// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
// internal
// import { i18n } from "../../i18n";
import {
  StyleMainContainer,
  StyleChampionGrid,
  StyleTimeFrameContainer,
  StyleActionContainer,
} from "./styles";
import DataContainer from "../DataContainer";
import Picker from "../../Picker";
import Button from "../../Button";
import championsDict from "../../../services/championService";
// import { getJungleRoute } from "../../../services/fujitsuRealtimeApiService";
import positions from "./../../../storybook-built/dist/Constants/lol_positions";

function DataJungleRoute(props) {
  const [currentGameState, setCurrentGameState] = useState();
  const [selectionState, setSelectionState] = useState({
    startTimeSec: 0,
    startTimeMin: 0,
    endTimeSec: 0,
    endTimeMin: 0,
  });
  const [fetchResultState, setFetchResultState] = useState("");
  const [playbackDurationState, setPlaybackDurationState] = useState(30); // total playback duration (seconds)
  // const [fetchingState, setFetchingState] = useState(false);

  useEffect(() => {
    const currentGame = get(
      props,
      `schedule.rounds.${props.schedule.round}.matches.${props.schedule.match}.games.${props.schedule.game}`,
      undefined
    );
    if (Object.values(currentGame.teams).length !== 2) return; // Handling TBD teams

    if (currentGame) {
      let team100 = {},
        team200 = {};
      let team100Name,
        team200Name = "";
      if (
        Object.values(currentGame.teams)[0].side === "100" &&
        Object.values(currentGame.teams)[1].side === "200"
      ) {
        team100Name = Object.keys(currentGame.teams)[0];
        team200Name = Object.keys(currentGame.teams)[1];
        for (let pos of positions) {
          team100[pos] = {
            ...Object.values(currentGame.teams)[0].roster[pos],
            pos,
          };
          team200[pos] = {
            ...Object.values(currentGame.teams)[1].roster[pos],
            pos,
          };
        }
      } else {
        team100Name = Object.keys(currentGame.teams)[1];
        team200Name = Object.keys(currentGame.teams)[0];
        for (let pos of positions) {
          team100[pos] = {
            ...Object.values(currentGame.teams)[1].roster[pos],
            pos,
          };
          team200[pos] = {
            ...Object.values(currentGame.teams)[0].roster[pos],
            pos,
          };
        }
      }
      // console.log("team100", team100, "team200", team200);
      setCurrentGameState({ team100Name, team100, team200Name, team200 });
    }
  }, [props.schedule]);

  useEffect(() => {
    getPositionDuration();
  }, [playbackDurationState]);

  const getPositionDuration = () => {
    const playbackPeriod = Math.max(
      Object.values(get(window, "MainData.jungleRouteData.team100.data", {}))
        .length,
      Object.values(get(window, "MainData.jungleRouteData.team200.data", {}))
        .length
    );
    // console.log("getPositionDuration", playbackPeriod);
    if (playbackPeriod > 0) {
      const positionDuration = Math.round(
        (playbackDurationState / playbackPeriod) * 1000
      );
      // console.log('positionDuration', positionDuration);
      window.MainData.jungleRouteData.positionDuration = positionDuration;
    }
  };

  const fetchData = async () => {
    const startTimeSec =
      Number(selectionState.startTimeSec) +
      Number(selectionState.startTimeMin) * 60;
    const endTimeSec =
      Number(selectionState.endTimeSec) +
      Number(selectionState.endTimeMin) * 60;
    let fetchingStatus = "";
    let data100 = {},
      data200 = {};
    if (get(selectionState, "team100.position")) {
      data100 = window.MainData.dataApiListener.component.getPlayerMovement(
        "team100",
        selectionState.team100.position,
        startTimeSec,
        endTimeSec
      );
    }
    console.log("route100", data100);
    if (get(selectionState, "team200.position")) {
      data200 = window.MainData.dataApiListener.component.getPlayerMovement(
        "team200",
        selectionState.team200.position,
        startTimeSec,
        endTimeSec
      );
    }
    console.log("route200", data200);

    if (fetchingStatus) {
      setFetchResultState(fetchingStatus);
      return;
    }

    window.MainData.jungleRouteData = {
      team100: {
        championName: get(
          championsDict,
          `${get(selectionState, "team100.championId")}.id`,
          undefined
        ),
        championId: get(selectionState, "team100.championId"),
        championIcon: championsDict[get(selectionState, "team100.championId")]
          ? `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${
              championsDict[selectionState.team100.championId].id
            }.png`
          : "",
        data: data100,
      },
      team200: {
        championName: get(
          championsDict,
          `${get(selectionState, "team200.championId")}.id`,
          undefined
        ),
        championId: get(selectionState, "team200.championId"),
        championIcon: championsDict[get(selectionState, "team200.championId")]
          ? `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${
              championsDict[selectionState.team200.championId].id
            }.png`
          : "",
        data: data200,
      },
    };
    getPositionDuration();
    setFetchResultState(
      `${currentGameState.team100Name} => ${
        Object.values(get(window, "MainData.jungleRouteData.team100.data", {}))
          .length
      } position(s) loaded,
       ${currentGameState.team200Name} => ${
        Object.values(get(window, "MainData.jungleRouteData.team200.data", {}))
          .length
      } position(s) loaded,
    `
    );
  };

  const areChampionsPicked = () => {
    //at least one champion from each team has to be picked
    return !(
      currentGameState &&
      currentGameState.team100 &&
      currentGameState.team200 &&
      Object.values(currentGameState.team100).filter(
        (player) => player.championId
      ).length &&
      Object.values(currentGameState.team200).filter(
        (player) => player.championId
      ).length
    );
  };

  const getPlayersOfTeam = (teamID) => {
    const players = [];
    positions.forEach((pos) => {
      // if(currentGameState[teamID][pos].championId){
      //   players.push({
      //     name: `${currentGameState[teamID][pos].name} - ${pos}`,
      //     value: pos,
      //   });
      // }

      if (currentGameState[teamID][pos].championId) {
        if (pos === "jungle") {
          players.push({
            name: `${currentGameState[teamID][pos].name} - ${pos}`,
            value: pos,
          });
        }
      }
    });
    return players;
  };

  const handlePlayerSelection = (teamID, pos) => {
    setSelectionState((currentSelection) => {
      const newSelection = { ...currentSelection };
      if (!pos) {
        console.log("cleared selection for team", teamID);
        newSelection[teamID] = undefined;
      } else {
        newSelection[teamID] = {
          championId: currentGameState[teamID][pos].championId,
          position: pos,
          player: currentGameState[teamID][pos].name,
        };
      }
      return newSelection;
    });
  };

  return (
    <DataContainer
      componentSlug="jungleRoute"
      icon="fas fa-route"
      style={{ overflow: "hidden" }}
    >
      <div
        className="data-panel__fieldset"
        style={{ paddingTop: "10px", paddingBottom: "0" }}
      >
        <StyleMainContainer disabled={areChampionsPicked()}>
          {areChampionsPicked() && <p>{"No Champions Selected"}</p>}
          {(currentGameState && (
            <StyleChampionGrid>
              <div style={{ backgroundColor: "#0078ff" }}>
                <h3 style={{ backgroundColor: "#46adff" }}>
                  {currentGameState.team100Name}
                </h3>
                <Picker
                  items={getPlayersOfTeam("team100")}
                  onUpdate={(pos) => {
                    handlePlayerSelection("team100", pos);
                  }}
                />
              </div>
              <div style={{ backgroundColor: "#dd3217" }}>
                <h3 style={{ backgroundColor: "#ff5745" }}>
                  {currentGameState.team200Name}
                </h3>
                <Picker
                  items={getPlayersOfTeam("team200")}
                  onUpdate={(pos) => {
                    handlePlayerSelection("team200", pos);
                  }}
                />
              </div>
            </StyleChampionGrid>
          )) || <p>{"No game"}</p>}
          <StyleTimeFrameContainer>
            <div className={"pill"}>
              <span>Start Time</span>
              <input
                className={`input input--mini input--small-text`}
                type={"number"}
                value={selectionState.startTimeMin}
                min={0}
                onChange={(e) => {
                  e.persist();
                  // e.target && console.log("choice start Time", e.target.value);
                  e.target &&
                    setSelectionState((selectionState) => {
                      return {
                        ...selectionState,
                        startTimeMin: e.target.value,
                      };
                    });
                }}
              />
              <span>min</span>
              <input
                className={`input input--mini input--small-text`}
                type="number"
                value={selectionState.startTimeSec}
                min={0}
                max={59}
                onChange={(e) => {
                  e.persist();
                  // e.target && console.log("choice start Time", e.target.value);
                  e.target &&
                    setSelectionState((selectionState) => {
                      return {
                        ...selectionState,
                        startTimeSec: e.target.value,
                      };
                    });
                }}
              />
              <span>sec</span>
            </div>
            <div className={"pill"}>
              <span>End Time</span>
              <input
                className={`input input--mini input--small-text`}
                type={"number"}
                value={selectionState.endTimeMin}
                min={0}
                pattern="\d\d:\d\d"
                onChange={(e) => {
                  e.persist();
                  // e.target && console.log("choice end Time", e.target.value);
                  e.target &&
                    setSelectionState((selectionState) => {
                      return { ...selectionState, endTimeMin: e.target.value };
                    });
                }}
              />
              <span>min</span>
              <input
                className={`input input--mini input--small-text`}
                type={"number"}
                value={selectionState.endTimeSec}
                min={0}
                max={59}
                onChange={(e) => {
                  e.persist();
                  // e.target && console.log("choice end Time", e.target.value);
                  e.target &&
                    setSelectionState((selectionState) => {
                      return { ...selectionState, endTimeSec: e.target.value };
                    });
                }}
              />
              <span>sec</span>
            </div>
          </StyleTimeFrameContainer>
          <StyleTimeFrameContainer>
            <div className={"pill"}>
              <span>Playback Duration</span>
              <input
                className={`input input--mini input--small-text`}
                type={"number"}
                value={playbackDurationState}
                min={0}
                onChange={(e) => {
                  e.persist();
                  // e.target && console.log("choice end Time", e.target.value);
                  e.target && setPlaybackDurationState(e.target.value);
                }}
              />
              <span>sec</span>
            </div>
          </StyleTimeFrameContainer>

          <StyleActionContainer className={"pill"}>
            <Button
              disabled={
                !(
                  (get(selectionState, "team100.position") ||
                    get(selectionState, "team200.position")) &&
                  selectionState.startTimeMin !== undefined &&
                  Number.isInteger(Number(selectionState.startTimeMin)) &&
                  selectionState.startTimeSec !== undefined &&
                  Number.isInteger(Number(selectionState.startTimeSec)) &&
                  selectionState.endTimeMin !== undefined &&
                  Number.isInteger(Number(selectionState.endTimeMin)) &&
                  selectionState.endTimeSec !== undefined &&
                  Number.isInteger(Number(selectionState.endTimeSec))
                )
              }
              action={async () => {
                fetchResultState && setFetchResultState("");
                await fetchData();
              }}
              text="Load Data"
            />
          </StyleActionContainer>
          {fetchResultState && <p>{fetchResultState}</p>}
        </StyleMainContainer>
      </div>
    </DataContainer>
  );
}

DataJungleRoute.propTypes = {
  schedule: PropTypes.object,
};

export default DataJungleRoute;
