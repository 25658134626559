// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
// internal
// import { i18n } from "../../i18n";
import {
  StyleMainContainer,
  StyleTeamGrid,
  StyleInputPrediction,
  StyleActionContainer,
  StyleSeparator,
  StyleContainer,
} from "./styles";
import DataContainer from "../DataContainer";
import Button from "../../Button";
import Picker from "../../Picker";
import {
  getWhoWillWinPredictions,
  getGameFlowPrediction,
} from "../../../services/fujitsuRealtimeApiService";
import { BLITZCRANK_ASSET } from "../../../util/const";

const GAME_TIMES_OPTIONS = [
  { value: "5:00", name: "5:00" },
  { value: "10:00", name: "10:00" },
  { value: "15:00", name: "15:00" },
  { value: "20:00", name: "20:00" },
  { value: "25:00", name: "25:00" },
  { value: "30:00", name: "30:00" },
  { value: "35:00", name: "35:00" },
  { value: "40:00", name: "40:00" },
  { value: "45:00", name: "45:00" },
  { value: "50:00", name: "50:00" },
];

function DataWhoWillWinAI(props) {
  const [currentGameState, setCurrentGameState] = useState();
  const [leftTeamPredictionState, setLeftTeamPredictionState] = useState(50);
  const [rightTeamPredictionState, setRightTeamPredictionState] = useState(50);
  const [gameTimeState, setGameTimeState] = useState();
  const [predictionsCollectionState, setPredictionsCollectionState] = useState(
    []
  );
  const [predictionBPState, setPredictionBPState] = useState("");
  const [gameFlowPredictionsState, setGameFlowPredictionState] = useState([]);
  const [blitzcrankAssetState, setBlitzcrankAssetState] = useState();

  useEffect(() => {
    const currentGame = get(
      props,
      `schedule.rounds.${props.schedule.round}.matches.${props.schedule.match}.games.${props.schedule.game}`,
      undefined
    );
    if (Object.values(currentGame.teams).length !== 2) return; // Handling TBD teams

    if (currentGame) {
      let team100 = {},
        team200 = {};
      let team100Name,
        team200Name = "";
      if (
        Object.values(currentGame.teams)[0].side === "100" &&
        Object.values(currentGame.teams)[1].side === "200"
      ) {
        team100Name = Object.keys(currentGame.teams)[0];
        team200Name = Object.keys(currentGame.teams)[1];
      } else {
        team100Name = Object.keys(currentGame.teams)[1];
        team200Name = Object.keys(currentGame.teams)[0];
      }
      // console.log("team100", team100, "team200", team200);
      setCurrentGameState({ team100Name, team100, team200Name, team200 });
    }
  }, [props.schedule]);

  const handlePredictionChange = (side, prediciton) => {
    let value = Number(prediciton);
    if (side === "left") {
      setLeftTeamPredictionState(value);
      setRightTeamPredictionState(100 - value);
    } else if (side === "right") {
      setRightTeamPredictionState(value);
      setLeftTeamPredictionState(100 - value);
    }
    setPredictionBPState("");
  };

  const loadPredictions = async () => {
    const delay = get(window, "MainData.realtimeApi.liveStatusDelay", 0);
    let predictions = await getWhoWillWinPredictions(delay);
    setPredictionsCollectionState(predictions);
    window.MainData.whoWillWinAI = {
      ...window.MainData.whoWillWinAI,
      collection: predictions,
    };
  };

  const loadBPPrediction = async () => {
    const response = await getWhoWillWinPredictions();
    const predictions = !response || response.error ? [] : response;
    const firstPrediction = predictions.find(
      (prediction) => prediction.timestamp === "0"
    );
    if (firstPrediction) {
      setLeftTeamPredictionState(Number(get(firstPrediction, "data.blue", 50)));
      setRightTeamPredictionState(Number(get(firstPrediction, "data.red", 50)));
      setLeftTeamPredictionState();
      window.MainData.whoWillWinAI = {
        ...window.MainData.whoWillWinAI,
        prediction: {
          team100: get(firstPrediction, "data.blue", 50),
          team200: get(firstPrediction, "data.red", 50),
        },
      };
      setPredictionBPState(
        firstPrediction.matchName
          ? `${firstPrediction.matchName} loaded`
          : "Prediction loaded"
      );
    }
  };

  const loadGameFlowPrediction = async () => {
    const delay = get(window, "MainData.realtimeApi.liveStatusDelay", 0);
    let predictions = await getGameFlowPrediction(delay);
    setGameFlowPredictionState(predictions);
    window.MainData.gameFlowPrediction = {
      ...window.MainData.gameFlowPrediction,
      collection: predictions,
    };
  };

  const saveChange = () => {
    const prediction = {};
    prediction.team100 = leftTeamPredictionState;
    prediction.team200 = rightTeamPredictionState;
    if (gameTimeState) {
      prediction.gameTime = gameTimeState;
    }
    window.MainData.whoWillWinAI = {
      ...window.MainData.whoWillWinAI,
      prediction,
    };
  };

  return (
    <DataContainer
      componentSlug="whoWillWinAI"
      icon="fas fa-monument fa-mind-share"
      style={{ overflow: "hidden" }}
    >
      <div
        className="data-panel__fieldset"
        style={{ paddingTop: "10px", paddingBottom: "0" }}
      >
        <StyleMainContainer>
          <StyleContainer>
            <h1>{` - Ban&Pick Prediction`}</h1>
            <div className="pill" style={{ margin: "10px 20px" }}>
              <span>Game Time</span>
              <Picker
                items={GAME_TIMES_OPTIONS}
                onUpdate={(e) => {
                  setGameTimeState(e);
                }}
              />
            </div>
            {(currentGameState && (
              <StyleTeamGrid>
                <div style={{ backgroundColor: "#0078ff" }}>
                  <h3 style={{ backgroundColor: "#46adff" }}>
                    {currentGameState.team100Name}
                  </h3>
                  <StyleInputPrediction
                    type={"number"}
                    onChange={(e) => {
                      e.persist();
                      handlePredictionChange("left", e.target.value);
                    }}
                    value={leftTeamPredictionState}
                    min={0}
                    max={100}
                  />
                  <span>{"%"}</span>
                </div>
                <div style={{ backgroundColor: "#dd3217" }}>
                  <h3 style={{ backgroundColor: "#ff5745" }}>
                    {currentGameState.team200Name}
                  </h3>
                  <StyleInputPrediction
                    type={"number"}
                    onChange={(e) => {
                      e.persist();
                      handlePredictionChange("right", e.target.value);
                    }}
                    value={rightTeamPredictionState}
                    min={0}
                    max={100}
                  />
                  <span>{"%"}</span>
                </div>
              </StyleTeamGrid>
            )) || <p>{"No game"}</p>}
            <StyleActionContainer className={"pill"}>
              <Button
                action={() => {
                  saveChange();
                }}
                text="Save Manual Change"
              />
            </StyleActionContainer>

            <StyleActionContainer className={"pill"}>
              <Button
                action={() => {
                  loadBPPrediction();
                }}
                text="Fetch B/P Predictions (0 min)"
                icon={"fas fa-mind-share"}
              />
              <span>
                {predictionBPState ? predictionBPState : "Not Loaded"}
              </span>
            </StyleActionContainer>
          </StyleContainer>

          <StyleSeparator />
          <StyleContainer>
            <h1>{` - Who Will Win Graph Prediction`}</h1>
            <div style={{ display: "grid" }}>
              <StyleActionContainer
                className={"pill"}
                style={{ display: "inline" }}
              >
                <Button
                  action={() => {
                    loadPredictions();
                  }}
                  text="Fetch Predictions Graph Data"
                  icon={"far fa-chart-line"}
                />
                <span>
                  {(predictionsCollectionState || []).length
                    ? "Loaded"
                    : "Not Loaded"}
                </span>
              </StyleActionContainer>
              <div style={{ display: "flex" }}>
                <StyleActionContainer className={"pill"}>
                  <span>{"Blitzcrank Asset"}</span>
                  <Picker
                    items={BLITZCRANK_ASSET}
                    onUpdate={(e) => {
                      setBlitzcrankAssetState(e);
                      window.MainData.whoWillWinAI = {
                        ...window.MainData.whoWillWinAI,
                        blitzcrankAsset: e,
                      };
                    }}
                  />
                </StyleActionContainer>
                {(blitzcrankAssetState && (
                  <img
                    src={blitzcrankAssetState}
                    alt={"blitzcrank-preview"}
                    style={{ width: "200px" }}
                  />
                )) ||
                  null}
              </div>
            </div>
          </StyleContainer>

          <StyleSeparator />
          <StyleContainer>
            <h1>{` - Future Prediction / Power Spike`}</h1>
            <StyleActionContainer className={"pill"}>
              <Button
                action={() => {
                  loadGameFlowPrediction();
                }}
                text="Fetch Future Prediction Graph Data"
                icon={"far fa-flux-capacitor"}
              />
              <span>
                {(gameFlowPredictionsState || []).length
                  ? "Loaded"
                  : "Not Loaded"}
              </span>
            </StyleActionContainer>
          </StyleContainer>
        </StyleMainContainer>
      </div>
    </DataContainer>
  );
}

DataWhoWillWinAI.propTypes = {
  schedule: PropTypes.object,
};

export default DataWhoWillWinAI;
