/* eslint-disable no-unused-vars */
//
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { get, cloneDeep } from "lodash";

// internal
import { i18n } from "../../i18n";
import DataContainer from "../DataContainer";
import Button from "../../Button";
import TournamentRealmPicker from "../../Picker/TournamentRealmPicker";
import {
  readGameEvents,
  stateBasedEvents,
} from "../../../services/riotRealtimeApiService";
import { readGameEvents as readFujitsuGameEvents } from "../../../services/fujitsuRealtimeApiService";
import {
  isInRadius,
  getJunglerProximity,
  defineCreep,
} from "../../../services/realTimeStatsProcessingService";
import { playerPositions } from "../DataMatch";
import {
  firebaseRealtimeDbSetData,
  firebaseRealtimeDbUpdateStream,
  firebaseRealtimeDbSetValue,
} from "../../../util/firebase";
import { getXPLevelProgression } from "../../../services/championXPLevelService";
import { itemsList, isItemMythic } from "../../../services/itemService";
// import { initialBanPick } from '../../../services/banPickLiveService';
import GameID from "./../DataGameID";

const dragonDisplayNameMap = {
  air: "Cloud",
  earth: "Mountain",
  fire: "Infernal",
  water: "Ocean",
  chemtech: "Chemtech",
  hextech: "Hextech",
  elder: "Infernal", // to prevent crashes.
};

const initialTurretStatus = {
  team100: {
    top: 0,
    mid: 0,
    bot: 0,
  },
  team200: {
    top: 0,
    mid: 0,
    bot: 0,
  },
};

// const initialTurretsDestroyed = {
//     team100: 0,
//     team200: 0,
// }

// const initialKillCount = {
//     team100: 0,
//     team200: 0,
// }

const initialPlayersMythicItem = {
  team100: {
    ...Object.assign(...playerPositions.map((pos) => ({ [pos]: "" }))),
  },
  team200: {
    ...Object.assign(...playerPositions.map((pos) => ({ [pos]: "" }))),
  },
};

const initialMonsterKillHistory = {
  team100: [],
  team200: [],
};

const dataroot = "dataApiListener";

// const calculateTeamGold = individualGoldArray => {
//     const result = {};
//     individualGoldArray.forEach(data => {
//         if (data.goldRange) {
//             Object.keys(data.goldRange).forEach(key => {
//                 result[key] = (result[key] || 0) + data.goldRange[key];
//             });
//         }
//     });
//     return result;
// };

// const calculateTricode = (expectedTricodesArr, playerSummonerNames) => {
//     const potentialTricodes = playerSummonerNames.map(name => name.split(' ')[0]);
//     for (let i = 0; i < potentialTricodes.length; i++){
//         if (expectedTricodesArr.includes(potentialTricodes[i])) {
//             return potentialTricodes[i];
//         }
//     }
//     return null;
// };

class DataApiListener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listeningGameLiveStats: false,
      listeningFujitsu: false,
      gameId: "",
      platform: "LOLTMNT01",
      getApiContext: () => null,
      stopListeningFunc: () => {},
      fujitsuStopListeningFunc: () => {},
      getFujitsuContext: () => null,
      liveStatusDelay: window.MainData.realtimeApi.liveStatusDelay,
      showFujitsuSettings: false,
      turretPlateStatus: cloneDeep(initialTurretStatus),
      // turretsDestroyed: cloneDeep(initialTurretsDestroyed),
      // killCount: cloneDeep(initialKillCount),
      jungleProximityProcessingStatus: false,
      playersMythicItem: cloneDeep(initialPlayersMythicItem),
      monsterKillHistory: cloneDeep(initialMonsterKillHistory),
      eventHistory: cloneDeep(initialMonsterKillHistory),
      firstTurretDesctruction: undefined,
    };
    window.MainData[dataroot] = {
      data: this.state,
      component: this,
    };
  }

  /**
   * Response looks like:
   * {
   *  blue: "72",
   *  none: "50",
   *  red: "41",
   *  datetime: undefined
   * }
   */
  getCurrentVisionData = () => {
    return (
      window.MainData.realtimeApi &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].vision
    );
  };

  getWhoWillWinGraphData = async () => {
    return get(window, "MainData.whoWillWinAI.collection", []);
  };

  /**
     * Note: The 'side' represents the side in the riot realtime api. The keys under 'gold' represent the seconds since the start of the game.
     * Response looks like:
     * {
            "DFM": {
                "side": "100",
                "gold": {
                    "0": 2500,
                    "20": 2500,
                    "40": 2500,
                    "60": 2500,
                    "80": 2500,
                    "100": 2542,
                    "120": 2980
                }
            },
            "V3": {
                ...
            }
        }
     */
  getTeamGoldGraphData = () => {
    const team100 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100;
    const team200 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200;
    const response = {};
    if (team100) {
      response[team100.tricode] = {
        side: "100",
        // gold: calculateTeamGold(Object.keys(window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].stats || {}).filter(key => team100.playerAccountIds.includes(key)).map(key =>
        //     window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].stats[key])),
        gold: (
          (
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ].teamStats || {}
          ).team100 || {}
        ).goldRange,
      };
    }
    if (team200) {
      response[team200.tricode] = {
        side: "200",
        // gold: calculateTeamGold(Object.keys(window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].stats || {}).filter(key => team200.playerAccountIds.includes(key)).map(key =>
        //     window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].stats[key])),
        gold: (
          (
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ].teamStats || {}
          ).team200 || {}
        ).goldRange,
      };
    }
    return response;
  };

  /**
     * Note: The 'side' represents the side in the riot realtime api.
     * Response looks like:
     * {
            "DFM": {
                "side": "100",
                "gold": 5121
            },
            "V3": {
                ...
            }
        }
     */
  getTeamGoldCount = () => {
    const team100 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100;
    const team200 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200;
    const response = {};
    if (team100) {
      response[team100.tricode] = {
        side: "100",
        gold: Object.keys(
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].stats || {}
        )
          .filter((key) => team100.playerAccountIds.includes(key))
          .map(
            (key) =>
              window.MainData.realtimeApi.events[
                `${this.state.platform}_${this.state.gameId}`
              ].stats[key]
          )
          .reduce((acc, player) => {
            acc = (acc || 0) + (player.totalGold || 0);
            return acc;
          }, 0),
      };
    }
    if (team200) {
      response[team200.tricode] = {
        side: "200",
        gold: Object.keys(
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].stats || {}
        )
          .filter((key) => team200.playerAccountIds.includes(key))
          .map(
            (key) =>
              window.MainData.realtimeApi.events[
                `${this.state.platform}_${this.state.gameId}`
              ].stats[key]
          )
          .reduce((acc, player) => {
            acc = (acc || 0) + (player.totalGold || 0);
            return acc;
          }, 0),
      };
    }
    return response;
  };

  /**
     * Note: the keys under the totalGoldRange represents the seconds since the start of the game. The value represents the totalGold.
     * Response Looks Like:
     * {
            "DFM": {
                ...
                "mid": {
                    "currentGold": 63,
                    "totalGoldRange": {
                        "0": 500,
                        "20": 500,
                        "40": 500,
                        "60": 500,
                        "80": 500,
                        "100": 542,
                        "120": 627
                    }
                }
                ...
            },
            "V3": {
               ...
            }
        }
     */
  getPlayersGoldGraphData = () => {
    const team100 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100;
    const team200 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200;
    const response = {};
    if (team100) {
      response[team100.tricode] = {};
      Object.keys(
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].stats || {}
      )
        .filter((key) => team100.playerAccountIds.includes(key))
        .map(
          (key) =>
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ].stats[key]
        )
        .forEach((data) => {
          const playerData = team100.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team100.tricode][playerPositions[playerData.arrayIndex]] =
              {
                // totalGoldRange: data.goldRange,
                currentTotalGold: data.totalGold,
                currentGold: data.currentGold,
              };
          }
        });
    }
    if (team200) {
      response[team200.tricode] = {};
      Object.keys(
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].stats || {}
      )
        .filter((key) => team200.playerAccountIds.includes(key))
        .map(
          (key) =>
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ].stats[key]
        )
        .forEach((data) => {
          const playerData = team200.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team200.tricode][playerPositions[playerData.arrayIndex]] =
              {
                // totalGoldRange: data.goldRange,
                currentTotalGold: data.totalGold,
                currentGold: data.currentGold,
              };
          }
        });
    }
    return response;
  };

  /**
     * Note: 'data' seems to be an array of one element. It comes from the riot realtime Api.
     * Response Looks Like:
     * {
            "DFM": {
                ...
                "mid": {
                    "data": [{
                        "perkSubStyle": 8100,
                        "perkIds": [8351, 8304, 8316, 8410, 8139, 8134, 5005, 5008, 5003],
                        "perkStyle": 8300
                    }],
                    "keystoneID": 8351
                },
                ...
            },
            "V3": {
                ...
            }
        }
     */
  getPlayerRunesData = () => {
    const team100 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100;
    const team200 =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ] &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200;
    const response = {};
    if (team100) {
      response[team100.tricode] = team100.perks;
    }
    if (team200) {
      response[team200.tricode] = team200.perks;
    }
    return response;
  };

  getPlayersDamageDealtToChampion = (startTime, endTime) => {
    const team100 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team100`,
      undefined
    );
    const team200 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team200`,
      undefined
    );
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    const response = {};
    if (!stats || !stats[Object.keys(stats)[0]]) {
      return response;
    }
    // console.log("stats", stats);
    const maxTime = Math.max(
      ...Object.keys(stats[Object.keys(stats)[0]].totalDamageDealtToChampions)
    );

    if (team100) {
      response[team100.tricode] = {};
      Object.keys(stats)
        .filter((key) => team100.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team100.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team100.tricode][playerPositions[playerData.arrayIndex]] =
              {
                damageDealtToChampions:
                  startTime && endTime
                    ? data.totalDamageDealtToChampions[
                        `${Math.min(maxTime, endTime)}`
                      ] - data.totalDamageDealtToChampions[`${startTime}`] || 0
                    : data.totalDamageDealtToChampions[maxTime],
              };
          }
        });
    }
    if (team200) {
      response[team200.tricode] = {};
      Object.keys(stats)
        .filter((key) => team200.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team200.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team200.tricode][playerPositions[playerData.arrayIndex]] =
              {
                damageDealtToChampions:
                  startTime && endTime
                    ? data.totalDamageDealtToChampions[
                        `${Math.min(maxTime, endTime)}`
                      ] - data.totalDamageDealtToChampions[`${startTime}`] || 0
                    : data.totalDamageDealtToChampions[maxTime],
              };
          }
        });
    }
    return response;
  };
  /**
     * Response Looks Like:
     * {
            "DFM": {
                ...
                "mid": {
                    "damageDealtToChampions": 1220
                },
                ...
            },
            "V3": {
                ...
            }
        }
     */

  getPlayersDamageDealttoTurret = () => {
    const team100 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team100`,
      undefined
    );
    const team200 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team200`,
      undefined
    );
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    const response = {};
    if (!stats || !stats[Object.keys(stats)[0]]) {
      return response;
    }

    if (team100) {
      response[team100.tricode] = {};
      Object.keys(stats)
        .filter((key) => team100.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team100.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team100.tricode][playerPositions[playerData.arrayIndex]] =
              {
                totalDamageDealtToTurrets: data.totalDamageDealtToTurrets || 0,
              };
          }
        });
    }
    if (team200) {
      response[team200.tricode] = {};
      Object.keys(stats)
        .filter((key) => team200.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team200.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team200.tricode][playerPositions[playerData.arrayIndex]] =
              {
                totalDamageDealtToTurrets: data.totalDamageDealtToTurrets || 0,
              };
          }
        });
    }
    return response;
  };
  // * Response Looks Like:
  // * {
  //        "DFM": {
  //            ...
  //            "mid": {
  //                "totalDamageDealtToTurrets": 1220
  //            },
  //            ...
  //        },
  //        "V3": {
  //            ...
  //        }
  //    }
  //
  getTotalGoldEarnedFromTurretPlateDestruction = () => {
    const team100 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team100`,
      undefined
    );
    const team200 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team200`,
      undefined
    );
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    const response = {};
    if (!stats || !stats[Object.keys(stats)[0]]) {
      return response;
    }

    if (team100) {
      response[team100.tricode] = {};
      Object.keys(stats)
        .filter((key) => team100.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team100.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team100.tricode][playerPositions[playerData.arrayIndex]] =
              {
                totalGoldEarnedFromTurretPlateDestruction:
                  data.totalGoldEarnedFromTurretPlateDestruction || 0,
              };
          }
        });
    }
    if (team200) {
      response[team200.tricode] = {};
      Object.keys(stats)
        .filter((key) => team200.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team200.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team200.tricode][playerPositions[playerData.arrayIndex]] =
              {
                totalGoldEarnedFromTurretPlateDestruction:
                  data.totalGoldEarnedFromTurretPlateDestruction || 0,
              };
          }
        });
    }
    return response;
  };
  // * Response Looks Like:
  // * {
  //        "DFM": {
  //            ...
  //            "mid": {
  //                "totalGoldEarnedFromTurretPlateDestruction": 1220
  //            },
  //            ...
  //        },
  //        "V3": {
  //            ...
  //        }
  //    }
  // */

  getTurretsStatus = () => {
    const turretStatus = cloneDeep(this.state.turretPlateStatus);
    turretStatus.team100.totalGold = get(
      window,
      "MainData.turretPlateGold.team100",
      undefined
    );
    turretStatus.team200.totalGold = get(
      window,
      "MainData.turretPlateGold.team200",
      undefined
    );
    return turretStatus;
  };

  // getTurretsDestroyed = () => {
  //     const turretsDestroyed = cloneDeep(this.state.turretsDestroyed);
  //     return turretsDestroyed;
  // }

  // getKillCount = () => {
  //     const killCount = cloneDeep(this.state.killCount);
  //     return killCount;
  // }

  getPlayerMovement = (teamID, position, startTime, endTime) => {
    const team = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.${teamID}`,
      undefined
    );
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    const response = {};
    if (!stats || !stats[Object.keys(stats)[0]]) {
      return response;
    }
    if (team) {
      const playerId = this.getPlayerAccountIdFromPos(teamID, position);
      const playerStats = stats[playerId];
      for (let i = startTime; i < endTime; i++) {
        if (playerStats) {
          if (playerStats.positions[i]) {
            response[i] = playerStats.positions[i];
            if (
              response[i - 1] &&
              !isInRadius(response[i - 1], response[i], 1000)
            ) {
              response[i].skip = true;
            }
          }
        }
      }
    }
    return response;
  };

  getPlayersPosition = (gameTime) => {
    const team100 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team100`,
      undefined
    );
    const team200 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team200`,
      undefined
    );
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    const response = {};
    if (!stats || !stats[Object.keys(stats)[0]]) {
      return response;
    }
    // console.log("stats", stats);
    const maxTime = Math.max(
      ...Object.keys(stats[Object.keys(stats)[0]].totalDamageDealtToChampions)
    );

    if (team100) {
      response[team100.tricode] = {};
      Object.keys(stats)
        .filter((key) => team100.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team100.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team100.tricode][playerPositions[playerData.arrayIndex]] =
              {
                position:
                  data.positions[
                    `${gameTime ? Math.min(maxTime, gameTime) : maxTime}`
                  ] || 0,
              };
          }
        });
    }
    if (team200) {
      response[team200.tricode] = {};
      Object.keys(stats)
        .filter((key) => team200.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team200.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team200.tricode][playerPositions[playerData.arrayIndex]] =
              {
                position:
                  data.positions[
                    `${gameTime ? Math.min(maxTime, gameTime) : maxTime}`
                  ] || 0,
              };
          }
        });
    }
    return response;
  };
  // * Response Looks Like:
  // * {
  //        "DFM": {
  //            ...
  //            "mid": {
  //                "position": { x: 10271, z: 5051 }
  //            },
  //            ...
  //        },
  //        "V3": {
  //            ...
  //        }
  //    }
  // */

  getPlayersXPLevel = () => {
    const team100 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team100`,
      undefined
    );
    const team200 = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.team200`,
      undefined
    );
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    const response = {};
    if (!stats || !stats[Object.keys(stats)[0]]) {
      return response;
    }
    if (team100) {
      response[team100.tricode] = {};
      Object.keys(stats)
        .filter((key) => team100.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team100.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team100.tricode][playerPositions[playerData.arrayIndex]] =
              {
                XP: data.XP,
                level: data.level,
                progression: getXPLevelProgression(data.XP, data.level),
              };
          }
        });
    }
    if (team200) {
      response[team200.tricode] = {};
      Object.keys(stats)
        .filter((key) => team200.playerAccountIds.includes(key))
        .map((key) => stats[key])
        .forEach((data) => {
          const playerData = team200.players.find(
            (player) => player.accountID === data.accountID
          );
          if (playerData) {
            response[team200.tricode][playerPositions[playerData.arrayIndex]] =
              {
                XP: data.XP,
                level: data.level,
                progression: getXPLevelProgression(data.XP, data.level),
              };
          }
        });
    }
    // console.log('getPlayersXPLevel', response);
    return response;
  };
  // * Response Looks Like:
  // * {
  //        "DFM": {
  //            ...
  //            "mid": {
  //                "XP": 15340,
  //                "level": 16,
  //            },
  //            ...
  //        },
  //        "V3": {
  //            ...
  //        }
  //    }
  // */

  getPlayerPurchaseHistory = (teamId, position) => {
    const playerAccountId = this.getPlayerAccountIdFromPos(teamId, position);
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    return get(stats, `${playerAccountId}.itemsHistory`, []);
  };

  getPlayersMythicItem = () => {
    // console.log("player Myhtic Items", this.state.playersMythicItem)
    let result = { team100: {}, team200: {} };
    playerPositions.forEach((pos) => {
      const itemId100 = get(this.state.playersMythicItem, `team100.${pos}`);
      result.team100[pos] = {
        itemId: itemId100,
        iconUrl: get(itemsList, `${itemId100}.asset`, ""),
      };
      const itemId200 = get(this.state.playersMythicItem, `team200.${pos}`);
      result.team200[pos] = {
        itemId: itemId200,
        iconUrl: get(itemsList, `${itemId200}.asset`, ""),
      };
    });
    return result;
  };

  getPlayerAccountIdFromPos = (teamID, pos) => {
    const team = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.${teamID}`,
      undefined
    );
    return get(team, `playerAccountIds[${playerPositions.indexOf(pos)}]`);
  };

  getPlayerAccountIDFromParticipantID = (participantID) => {
    const team = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${
        this.state.gameId
      }.${participantID > 5 ? "team200" : "team100"}`,
      undefined
    );
    return get(
      team,
      `playerAccountIds[${
        participantID > 5 ? participantID - 6 : participantID - 1
      }]`
    );
  };

  getPlayerPositionFromParticipantID = (participantID) => {
    if (participantID > 5) {
      return playerPositions[participantID - 5 - 1];
    }
    return playerPositions[participantID - 1];
  };

  getTeamIdFromPlayerGameId = (playerId) =>
    playerId > 5 ? "team200" : "team100";

  getWhoWillWinAIGraphEvents = () => {
    const teamsAreFlipped = this.teamsAreFlipped();

    const team100EventHistory = teamsAreFlipped
      ? this.state.eventHistory.team200
      : this.state.eventHistory.team100;
    const team100Events = [
      ...this.state.monsterKillHistory.team100,
      ...team100EventHistory,
    ];
    team100Events.sort((eventA, eventB) => eventA.gameTime - eventB.gameTime);

    const team200EventHistory = teamsAreFlipped
      ? this.state.eventHistory.team100
      : this.state.eventHistory.team200;
    const team200Events = [
      ...this.state.monsterKillHistory.team200,
      ...team200EventHistory,
    ];
    team200Events.sort((eventA, eventB) => eventA.gameTime - eventB.gameTime);

    return {
      team100: team100Events,
      team200: team200Events,
    };
  };

  processJunglerProximityStats = async () => {
    const stats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    const jungler100Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team100", "jungle")]}.positions`
    );
    const top100Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team100", "top")]}.positions`
    );
    const mid100Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team100", "mid")]}.positions`
    );
    const adc100Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team100", "adc")]}.positions`
    );

    const jungler200Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team200", "jungle")]}.positions`
    );
    const top200Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team200", "top")]}.positions`
    );
    const mid200Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team200", "mid")]}.positions`
    );
    const adc200Positions = get(
      stats,
      `${[this.getPlayerAccountIdFromPos("team200", "adc")]}.positions`
    );

    const team100Stats = getJunglerProximity(jungler100Positions, [
      top100Positions,
      mid100Positions,
      adc100Positions,
    ]);
    const team200Stats = getJunglerProximity(jungler200Positions, [
      top200Positions,
      mid200Positions,
      adc200Positions,
    ]);
    window.MainData.junglerProximityData = {
      team100: {
        TOP: team100Stats[0],
        MID: team100Stats[1],
        BOT: team100Stats[2],
      },
      team200: {
        TOP: team200Stats[0],
        MID: team200Stats[1],
        BOT: team200Stats[2],
      },
    };
    firebaseRealtimeDbSetValue(
      "control-gameScreen/junglerProximityReady",
      true
    );
    this.setState({
      jungleProximityProcessingStatus: true,
    });
  };

  // dateSimulationOffsetMs = Date.parse('2019-09-16T04:41:02.400Z') - Date.now(); // for testing also in components/GameScreen/index
  // dateSimulationOffsetMs = Date.parse('2019-09-16T04:33:32.400Z') - Date.now();
  // dateSimulationOffsetMs = Date.parse('2019-11-10T14:05:49.745Z') - Date.now(); // worlds 2019 final game 2, has a pause after 20 minutes
  // dateSimulationOffsetMs = Date.parse('2020-01-22T04:16:18.241Z') - Date.now(); // 2020-01-22 test game #2 dragon kill 3 ( event 1337 )
  // dateSimulationOffsetMs = Date.parse('2020-01-24T04:28:15.429Z') - Date.now(); // 2020-01-22 test game #2 dragon kill 2 ( event 860 )
  // dateSimulationOffsetMs = Date.parse('2020-02-08T08:28:36.320Z') - Date.now();
  // dateSimulationOffsetMs = Date.parse('2020-02-08T04:50:00.000Z') - Date.now(); // Baron killed 1294233
  // dateSimulationOffsetMs = Date.parse('2020-02-11T04:47:00.000Z') - Date.now(); // Baron killed 1295440 game1 week2
  // dateSimulationOffsetMs = Date.parse('2020-02-11T04:47:00.000Z') - Date.now(); // Baron killed 1295440 game1 week2

  // dateSimulationOffsetMs = Date.parse('2020-03-21T13:28:30.000Z') - Date.now(); //  1323953 game3 playoff round1 CGA RJ game5

  // dateSimulationOffsetMs = Date.parse('2021-01-23T08:16:00.000Z') - Date.now(); // 1720310 game4 week1 V3 vs SG 2021

  dateSimulationOffsetMs = 0;

  getCurrentTimestamp = () => Date.now() + this.dateSimulationOffsetMs;

  saveGameID = async (value) => {
    try {
      await firebaseRealtimeDbSetValue(
        `${window.MainData.schedule.league}/${
          window.MainData.schedule.season
        }/${window.MainData.schedule.split}/${
          window.MainData.schedule.rounds[window.MainData.schedule.round].name
        }/${window.MainData.schedule.match}/${
          window.MainData.schedule.game
        }/GameID`,
        value
      );
    } catch (error) {
      console.log("Did not save GameID", error);
    }
  };

  handleChangeGameId = (e) => {
    this.setState({ gameId: e.target.value });
  };
  updateRealm = (platform) => {
    this.setState({ platform });
    this.props.distributePlatform(platform);
  };
  saveRealm = (value) => {
    try {
      firebaseRealtimeDbSetValue(
        `${window.MainData.schedule.league}/${
          window.MainData.schedule.season
        }/${window.MainData.schedule.split}/${
          window.MainData.schedule.rounds[window.MainData.schedule.round].name
        }/${window.MainData.schedule.match}/${
          window.MainData.schedule.game
        }/Realm`,
        value
      );
    } catch (error) {
      console.log("Did not save Realm", error);
    }
  };
  static propTypes = {
    gameId: PropTypes.string,
    platform: PropTypes.string,
    distributeGameId: PropTypes.func,
    distributePlatform: PropTypes.func,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.gameId) this.setState({ gameId: nextProps.gameId });
    if (nextProps.gameId === "000000")
      document.getElementById("game-id-label").value = "";
    // if(nextProps.platform) this.updateRealm(nextProps.platform);
  }

  updateData = async (newState) => {
    await new Promise((resolve) => {
      this.setState(newState, () => {
        window.MainData[dataroot].data = this.state;
        resolve();
      });
    });
  };

  updateTurretPlateStatus = (event) => {
    const turretPlateStatus = cloneDeep(this.state.turretPlateStatus);

    const team =
      turretPlateStatus[`team${event.teamID === 100 ? "200" : "100"}`];
    team[event.lane] = team[event.lane] + 1;

    // let destroyerTeam, lane;
    // if(event.position && event.position.x && event.position.z){
    //     const calculated = calculateTeamAndLaneFromPosition(event.position.x, event.position.z, true);
    //     if(calculated){
    //         if(calculated.team){
    //             const destroyedTeam = calculated.team;
    //             destroyerTeam = (destroyedTeam === 'team100') ? 'team200' : 'team100';
    //         }
    //         if(calculated.lane){
    //             lane = calculated.lane;
    //         }
    //     }
    // }

    // if(!destroyerTeam){
    //     destroyerTeam = `team${event.teamID}`;
    // }
    // if(!lane){
    //     lane = event.lane;
    // }

    // const team = turretPlateStatus[destroyerTeam];
    // team[lane] = team[lane] + 1;

    this.setState({ turretPlateStatus });
  };

  updateTurretDestruction = (event) => {
    if (!this.state.firstTurretDesctruction) {
      this.setState(
        {
          firstTurretDesctruction: {
            eventType: "firstTower",
            gameTime: event.gameTime,
            teamID: event.teamID === 100 ? "team200" : "team200",
          },
        },
        () => {
          this.updateEventHistory(
            "firstTower",
            this.state.firstTurretDesctruction.teamID,
            this.state.firstTurretDesctruction.gameTime
          );
        }
      );
    }
  };

  // updateTurretsDestroyed = (event) => {
  //     const turretsDestroyed = cloneDeep(this.state.turretsDestroyed);
  //     const destroyedTeam = `team${liveStats.teamID}`;
  //     let destroyerTeam;
  //     destroyerTeam = (destroyedTeam === 'team100') ? 'team200' : 'team100';

  //     turretsDestroyed[destroyerTeam] =  turretsDestroyed[destroyerTeam] + 1;
  //     this.setState({ turretsDestroyed });
  // }

  // updateKillCount = (event) => {
  //     const killCount = cloneDeep(this.state.killCount);
  //     const killerTeam = `team${event.killerTeamID}`;

  //     killCount[killerTeam] = killCount[killerTeam] + 1;
  //     this.setState({ killCount });
  // }

  updateGoldEarnedFromTurretPlateDestruction = (event) => {
    const playerToReward = [];
    const goldAmountToShare = 120; // 120 from patch 10.5 patch, patch < 10.5 => 160
    const teamToReward = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${
        this.state.gameId
      }.team${event.teamID === 100 ? "200" : "100"}`,
      undefined
    );
    const teamPositions = this.getPlayersPosition();
    const currentStats = get(
      window,
      `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
      undefined
    );
    playerPositions.forEach((pos, index) => {
      if (
        isInRadius(
          event.position,
          teamPositions[teamToReward.tricode][pos].position,
          1000
        )
      ) {
        playerToReward.push(teamToReward.playerAccountIds[index]);
      }
    });
    for (const id of playerToReward) {
      currentStats[id].totalGoldEarnedFromTurretPlateDestruction =
        (currentStats[id].totalGoldEarnedFromTurretPlateDestruction || 0) +
        goldAmountToShare / playerToReward.length;
    }
  };

  updatePlayersStatsCreepsKills = async (creep, playerAccountId, gameTime) => {
    await new Promise((resolve) => {
      const stats = get(
        window,
        `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats`,
        undefined
      );
      const gameTimeSeconds = Math.round(parseInt(gameTime) / 1000);
      stats[playerAccountId].positions[gameTimeSeconds] = {
        ...get(stats, `${playerAccountId}.positions.${gameTimeSeconds}`, {}),
        creepKilled: get(
          stats,
          `${playerAccountId}.positions.${gameTimeSeconds}.creepKilled`,
          []
        ).concat(creep),
      };
      resolve();
    });
  };

  updateItemMovement = async (
    itemEventType,
    participantID,
    itemID,
    gameTime
  ) => {
    const playerAccountId =
      this.getPlayerAccountIDFromParticipantID(participantID);
    // console.log("updating item list for event", itemEventType, 'for',playerAccountId, 'item', itemID, 'at', gameTime);

    // Season 15 Patch getting rid of Mythic items, do not want the Mythic Item side popouts
    // if (itemEventType === "item_purchased" && isItemMythic(itemID)) {
    //   // console.log("Mythic item purchased", `${((gameTime/1000)/60)}:${gameTime % Math.floor((gameTime/1000)/60)}`, 'min')
    //   const teamId = participantID > 5 ? "team200" : "team100";
    //   const assetUrl = get(
    //     itemsList,
    //     `[${itemID}].sidePopupAsset[${
    //       teamId === "team100" ? "left" : "right"
    //     }]`,
    //     ""
    //   );
    //   const playerPosition =
    //     this.getPlayerPositionFromParticipantID(participantID);
    //   const newPlayersMythicItem = this.state.playersMythicItem;
    //   newPlayersMythicItem[teamId][playerPosition] = itemID;
    //   this.setState({
    //     playersMythicItem: newPlayersMythicItem,
    //   });
    //   if (assetUrl && playerPosition) {
    //     firebaseRealtimeDbSetValue(
    //       `control-gameScreen/data/inGameTips/${teamId}/${playerPosition}/url`,
    //       assetUrl
    //     );
    //     setTimeout(() => {
    //       firebaseRealtimeDbSetValue(
    //         `control-gameScreen/data/inGameTips/${teamId}/${playerPosition}`
    //       );
    //     }, 7000);
    //   }
    // }
    await new Promise((resolve) => {
      const playerStats = get(
        window,
        `MainData.realtimeApi.events.${this.state.platform}_${this.state.gameId}.stats.${playerAccountId}`,
        undefined
      );
      // console.log('playerStats', playerStats);
      if (playerStats) {
        const playerItemsHistory = get(playerStats, `itemsHistory`, []).concat({
          transactionType: itemEventType,
          item: itemID,
          gameTime,
        });
        playerStats.itemsHistory = playerItemsHistory;
      }
      resolve();
    });
  };

  updateTeamDrakeEvent = (monsterType, dragonType, killerTeamID, gameTime) => {
    const newMonsterKillHistory = cloneDeep(this.state.monsterKillHistory);
    newMonsterKillHistory[`team${killerTeamID}`] = [
      ...get(newMonsterKillHistory, `team${killerTeamID}`, []),
      {
        monsterType,
        dragonType,
        gameTime,
      },
    ];

    this.setState({ monsterKillHistory: newMonsterKillHistory }, () => {
      console.log("newMonsterKillHistory", this.state.monsterKillHistory);
    });
  };

  updateEventHistory = (eventType, teamID, gameTime) => {
    const newEventHistory = cloneDeep(this.state.eventHistory);
    newEventHistory[teamID] = [
      ...newEventHistory[teamID],
      {
        eventType,
        gameTime,
      },
    ];
    this.setState({
      eventHistory: newEventHistory,
    });
  };

  saveDraftOrder = (event) => {
    const draft = {
      team100: {
        bans: [
          ...get(event, "bannedChampions", []).filter(
            (championBan) => championBan.teamID === 100
          ),
        ],
        roster: {},
      },
      team200: {
        bans: [
          ...get(event, "bannedChampions", []).filter(
            (championBan) => championBan.teamID === 200
          ),
        ],
        roster: {},
      },
    };
    Object.values(get(event, "teamOne", {})).forEach((player) => {
      const pos = this.getPlayerPositionFromParticipantID(
        player.arrayIndex + 1
      );
      draft.team100.roster[pos] = {
        championID: player.championID,
        accountID: player.accountID,
        summonerName: player.summonerName,
      };
    });
    Object.values(get(event, "teamTwo", [])).forEach((player) => {
      const pos = this.getPlayerPositionFromParticipantID(
        player.arrayIndex + 1
      );
      draft.team200.roster[pos] = {
        championID: player.championID,
        accountID: player.accountID,
        summonerName: player.summonerName,
      };
    });
    window.MainData.schedule.rounds[window.MainData.schedule.round].matches[
      window.MainData.schedule.match
    ].games[window.MainData.schedule.game] = {
      ...window.MainData.schedule.rounds[window.MainData.schedule.round]
        .matches[window.MainData.schedule.match].games[
        window.MainData.schedule.game
      ],
      draftOrder: draft,
    };
  };

  stateBasedEventsIds = {};

  callbacks = {};

  registerCallback = (key, callbackFunc, skipSequenceSet) => {
    const processHistory = () => {
      if (
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ] &&
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].list
      ) {
        Object.values(
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].list
        ).forEach((event) => {
          if (
            skipSequenceSet &&
            skipSequenceSet.includes(parseInt(event.sequenceIndex, 10))
          ) {
            // console.log(`Skipping callback for sequenceIndex ${event.sequenceIndex} because it was already processed.`)
            // console.log(skipSequenceSet.getIntGroups())
            return;
          }
          if (event.action === key) {
            // console.log('callback', key);
            if (
              skipSequenceSet &&
              !["game_info", "pause_ended"].includes(key)
            ) {
              skipSequenceSet.add(parseInt(event.sequenceIndex, 10));
            }
            callbackFunc(event);
          }
        });
      }
    };
    if (!this.callbacks[key]) {
      this.callbacks[key] = [callbackFunc];
      processHistory();
      return 0;
    } else {
      this.callbacks[key].push(callbackFunc);
      processHistory();
      return this.callbacks[key].length - 1;
    }
  };

  unregisterCallback = (key, callbackId) => {
    if (this.callbacks[key]) {
      this.callbacks[key].splice(callbackId, 1);
    }
  };

  listenFujitsu = () => {
    this.setState({ listeningFujitsu: true }, () => {
      const context = this.state.getFujitsuContext() || {};
      if (!context[`${this.state.platform}_${this.state.gameId}`]) {
        context[`${this.state.platform}_${this.state.gameId}`] = {};
      }
      const errorHandler = (error) => {
        this.stopListeningFujitsu();
        alert(i18n().errors.fujitsuAPIError);
      };
      const fujitsuStopListeningFunc = readFujitsuGameEvents(
        "1",
        (event) => {
          if (
            !window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ]
          ) {
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ] = {
              list: {},
            };
          }
          // console.log('fujitsu event', event);

          switch (event.type) {
            case "vision":
              this.populateVisionData(event);
              break;
            default:
              break;
          }
        },
        this.state.liveStatusDelay * 1000,
        context[`${this.state.platform}_${this.state.gameId}`],
        undefined,
        errorHandler
      );
      this.setState({
        getFujitsuContext: () => context,
        fujitsuStopListeningFunc,
      });
    });
  };

  listenGameLiveStats = () => {
    if (!this.state.gameId) {
      console.error("The game ID has not been set to listen livestats"); // eslint-disable-line no-console
      return null;
    }
    this.setState(
      { listeningGameLiveStats: true, jungleProximityProcessingStatus: false },
      () => {
        const context = this.state.getApiContext() || {};
        if (!context[`${this.state.platform}_${this.state.gameId}`]) {
          context[`${this.state.platform}_${this.state.gameId}`] = {};
        }
        console.log("listening to ", this.state.platform, this.state.gameId);

        this.props.distributeGameId(this.state.gameId); // send to other components
        this.saveGameID(this.state.gameId); // sent to firebase
        this.saveRealm(this.state.platform); // sent to firebase

        // Reseting the junglerProximity value from previous game
        if (window.MainData.junglerProximityData) {
          window.MainData.junglerProximityData = undefined;
        }
        firebaseRealtimeDbSetValue(
          "control-gameScreen/junglerProximityReady",
          false
        );

        const errorHandler = (error) => {
          this.stopListeningGameLiveStats();
          alert(i18n().errors.RiotRealTimeAPIError);
        };
        const stopListeningFunc = readGameEvents(
          this.state.platform,
          this.state.gameId,
          async (liveStats) => {
            if (
              !window.MainData.realtimeApi.events[
                `${this.state.platform}_${this.state.gameId}`
              ]
            ) {
              window.MainData.realtimeApi.events[
                `${this.state.platform}_${this.state.gameId}`
              ] = {
                list: {},
              };
            }
            if (liveStats.action === "stats_update") {
              this.processStatsUpdate(liveStats);
              if (this.callbacks[liveStats.action]) {
                this.callbacks[liveStats.action].forEach((callback) =>
                  callback(liveStats)
                );
              }
            } else {
              if (stateBasedEvents.includes(liveStats.action)) {
                if (this.stateBasedEventsIds[liveStats.action]) {
                  delete window.MainData.realtimeApi.events[
                    `${this.state.platform}_${this.state.gameId}`
                  ].list[this.stateBasedEventsIds[liveStats.action]];
                }
                this.stateBasedEventsIds[liveStats.action] = parseInt(
                  liveStats.sequenceIndex,
                  10
                );
              }
              window.MainData.realtimeApi.events[
                `${this.state.platform}_${this.state.gameId}`
              ].list[parseInt(liveStats.sequenceIndex, 10)] = liveStats;

              if (this.callbacks[liveStats.action]) {
                this.callbacks[liveStats.action].forEach((callback) =>
                  callback(liveStats)
                );
              }
              switch (liveStats.action) {
                case "champ_select":
                  this.populateTeamData(liveStats);
                  break;
                case "game_info":
                  this.populatePerkData(liveStats);
                  this.populateGameInfo(liveStats);
                  break;
                case "turret_plate_destroyed":
                  // console.log("TAG => turret plate destroyed", liveStats);
                  this.updateTurretPlateStatus(liveStats);
                  // this.updateGoldEarnedFromTurretPlateDestruction(liveStats);
                  break;
                case "epic_monster_kill": {
                  const creepType = defineCreep(
                    liveStats.monsterType,
                    liveStats.position
                  );
                  if (creepType) {
                    // console.log('creep event', liveStats);
                    const playerAccountId =
                      this.getPlayerAccountIDFromParticipantID(
                        liveStats.killer
                      );
                    await this.updatePlayersStatsCreepsKills(
                      creepType,
                      playerAccountId,
                      liveStats.gameTime
                    );
                  } else {
                    // console.log('TAG => epic_monster_kill', liveStats);
                    this.updateTeamDrakeEvent(
                      liveStats.monsterType,
                      liveStats.dragonType,
                      liveStats.killerTeamID,
                      liveStats.gameTime
                    );
                  }
                  break;
                }
                case "skill_level_up":
                  break;
                case "champion_level_up":
                  break;
                case "champion_kill":
                  // this.updateKillCount(liveStats);
                  break;
                case "item_destroyed":
                case "item_purchased":
                case "item_sold":
                case "item_undo": {
                  await this.updateItemMovement(
                    liveStats.action,
                    liveStats.participantID,
                    liveStats.itemID,
                    liveStats.gameTime
                  );
                  break;
                }
                case "champion_kill_special":
                  if (liveStats.killType === "firstBlood") {
                    // console.log('TAG => champion_kill_special', liveStats);
                    this.updateEventHistory(
                      liveStats.killType,
                      this.getTeamIdFromPlayerGameId(liveStats.playerID),
                      liveStats.gameTime
                    );
                  }
                  break;
                case "building_destroyed":
                  if (liveStats.buildingType === "inhibitor") {
                    this.updateTurretDestruction(liveStats);
                  }
                  // else if (liveStats.buildingType === 'turret') {
                  // this.updateTurretsDestroyed(liveStats);
                  // }
                  break;
                default:
                  // console.log('Unhandled event ',liveStats.action, liveStats);
                  break;
              }
            }

            this.sendToDekkiFrameIfNeeded(liveStats);

            // console.log('getTeamGoldGraphData', this.getTeamGoldGraphData());
            // console.log('getPlayersGoldGraphData', JSON.stringify(this.getPlayersGoldGraphData()));
            // console.log('getPlayerRunesData', JSON.stringify(this.getPlayerRunesData()));
            // console.log('getTeamGoldCount', this.getTeamGoldCount());
          },
          this.state.liveStatusDelay * 1000,
          context[`${this.state.platform}_${this.state.gameId}`],
          null,
          this.getCurrentTimestamp(),
          errorHandler
        );
        this.setState({
          getApiContext: () => context,
          stopListeningFunc,
        });
      }
    );
  };

  teamsAreFlipped = () => {
    let flipped = false;
    const schedule = window.MainData.schedule;
    const matchData = schedule.rounds[schedule.round].matches[schedule.match];
    const teamLeftTricode = matchData
      ? matchData.team100 && matchData.team100.tricode
      : "";
    const teamRightTricode = matchData
      ? matchData.team200 && matchData.team200.tricode
      : "";
    if (teamLeftTricode && teamRightTricode) {
      flipped =
        matchData && matchData.games && matchData.games[schedule.game]
          ? matchData.games[schedule.game].teams[teamLeftTricode].side === "100"
            ? false
            : true
          : false;
    } else {
      flipped = false;
    }
    if (window.MainData.invertBroadcastToolScreens) {
      flipped = !flipped;
    }
    return flipped;
  };

  sendToDekkiFrameIfNeeded = (liveStatsEvent) => {
    if (liveStatsEvent.date) {
      if (
        this.getCurrentTimestamp() -
          (Date.parse(liveStatsEvent.date) +
            this.state.liveStatusDelay * 1000) >
        5000
      ) {
        // don't process stuff from over 5s outdated
        // console.log('skipping frame event', liveStatsEvent.sequenceIndex, ' outdated.');
        return;
      }
    }

    const prefix = `${liveStatsEvent.platformID}/${liveStatsEvent.gameID}`;
    firebaseRealtimeDbSetData(
      `${prefix}/lastEventTime`,
      liveStatsEvent.date || new Date().toISOString()
    );
    let team100, team200, killerTeam, killerPlayer, frameTriggerTime;
    if (liveStatsEvent.date) {
      frameTriggerTime = Date.parse(liveStatsEvent.date);
      frameTriggerTime += this.state.liveStatusDelay * 1000;
      if (frameTriggerTime > Date.now()) {
        frameTriggerTime = Date.now();
      }
      frameTriggerTime = new Date(frameTriggerTime).toISOString();
    }
    switch (liveStatsEvent.action) {
      case "queued_dragon_info":
        firebaseRealtimeDbSetData(
          `${prefix}/queuedDragonInfo/${liveStatsEvent.sequenceIndex}`,
          {
            // TRACKING PURPOSES ONLY, THIS CAN BE DISABLED.
            ...liveStatsEvent,
            datetime: liveStatsEvent.date || new Date().toISOString(),
          }
        );
        break;
      case "queued_epic_monster_info":
        firebaseRealtimeDbSetData(
          `${prefix}/queuedEpicMonsterInfo/${liveStatsEvent.sequenceIndex}`,
          {
            // TRACKING PURPOSES ONLY, THIS CAN BE DISABLED.
            ...liveStatsEvent,
            datetime: liveStatsEvent.date || new Date().toISOString(),
          }
        );
        break;
      case "epic_monster_spawn":
        // firebaseRealtimeDbSetData(`${prefix}/epicMonsterSpawn/${liveStatsEvent.sequenceIndex}`, {
        //     monsterType: liveStatsEvent.monsterType,
        //     dragonType: liveStatsEvent.dragonType, //  air, earth, elder, fire, water
        //     datetime: liveStatsEvent.date || ((new Date()).toISOString()),
        // });
        break;
      case "turret_plate_destroyed":
        // console.log("turret plate destroyed", liveStatsEvent);
        firebaseRealtimeDbSetData(
          `${prefix}/turretPlateDestroyed/${liveStatsEvent.sequenceIndex}`,
          {
            // TRACKING PURPOSES ONLY, THIS CAN BE DISABLED.
            ...liveStatsEvent,
            datetime: liveStatsEvent.date || new Date().toISOString(),
          }
        );
        break;
      case "epic_monster_kill":
        firebaseRealtimeDbSetData(
          `${prefix}/epicMonsterKill/${liveStatsEvent.sequenceIndex}`,
          {
            // TRACKING PURPOSES ONLY, THIS CAN BE DISABLED.
            monsterType: liveStatsEvent.monsterType,
            dragonType: liveStatsEvent.dragonType, //  air, earth, elder, fire, water
            datetime: liveStatsEvent.date || new Date().toISOString(),
          }
        );
        if (liveStatsEvent.monsterType === "dragon") {
          // console.log('sending dragon kill to frame', liveStatsEvent);
          const videos = {
            air: "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_cloud.webm",
            earth:
              "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_mountain.webm",
            elder:
              "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/dragon_elder.webm",
            fire: "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_infernal.webm",
            water:
              "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_ocean.webm",
            chemtech:
              "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_chemtech.webm",
            hextech:
              "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/drake_hextech.webm",
          };

          const dragonState = {
            url: videos[liveStatsEvent.dragonType],
            lastTriggerTime: frameTriggerTime || new Date().toISOString(),
            // lastTriggerTime: ((new Date()).toISOString()),
            loop: null,
            name: `Dragon ${dragonDisplayNameMap[liveStatsEvent.dragonType]}`,
          };

          if (this.teamsAreFlipped()) {
            firebaseRealtimeDbUpdateStream(
              liveStatsEvent.killerTeamID === 100 ? 12 : 11,
              dragonState
            );
          } else {
            firebaseRealtimeDbUpdateStream(
              liveStatsEvent.killerTeamID === 100 ? 11 : 12,
              dragonState
            );
          }
        } else if (liveStatsEvent.monsterType === "baron") {
          const baronState = {
            url: "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/baron.webm",
            lastTriggerTime: frameTriggerTime || new Date().toISOString(),
            // lastTriggerTime: ((new Date()).toISOString()),
            loop: null,
            name: "Baron",
          };

          if (this.teamsAreFlipped()) {
            firebaseRealtimeDbUpdateStream(
              liveStatsEvent.killerTeamID === 100 ? 12 : 11,
              baronState
            );
          } else {
            firebaseRealtimeDbUpdateStream(
              liveStatsEvent.killerTeamID === 100 ? 11 : 12,
              baronState
            );
          }
        }
        break;
      case "champion_kill_special":
        if (["firstBlood", "multi"].includes(liveStatsEvent.killType)) {
          team100 =
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ] &&
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ].team100;
          team200 =
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ] &&
            window.MainData.realtimeApi.events[
              `${this.state.platform}_${this.state.gameId}`
            ].team200;

          if (team100 && team100.players) {
            killerPlayer = team100.players.find(
              (p) => p.participantID === liveStatsEvent.killer
            );

            if (killerPlayer) {
              killerTeam = team100;
            }
          }
          if (!killerTeam && team200 && team200.players) {
            killerPlayer = team200.players.find(
              (p) => p.participantID === liveStatsEvent.killer
            );
            if (killerPlayer) {
              killerTeam = team200;
            }
          }

          // firebaseRealtimeDbSetData(`${prefix}/championKillSpecial/${liveStatsEvent.sequenceIndex}`, {
          //     killType: liveStatsEvent.killType,
          //     killerTeamTricode: killerTeam.tricode,
          //     killerPosition: killerPlayer && playerPositions[killerPlayer.arrayIndex],
          //     datetime: liveStatsEvent.date || ((new Date()).toISOString()),
          //     killStreakLength: liveStatsEvent.killStreakLength,
          // });
          let type;
          switch (liveStatsEvent.killType) {
            case "firstBlood":
              type = "firstBlood";
              // this.updateEventHistory('firstBlood', liveStatsEvent.teamID, liveStatsEvent.gameTime);
              break;
            case "multi":
              switch (liveStatsEvent.killStreakLength) {
                case 5:
                  type = "pentakill";
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }

          if (type) {
            const videos = {
              pentakill:
                "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/pentakill.webm",
              firstBlood:
                "https://cdn.dekki.com/uploads/lolesports/broadcastFrame/firstblood.webm",
            };
            const state = {
              url: videos[type],
              lastTriggerTime: frameTriggerTime || new Date().toISOString(),
              // lastTriggerTime: ((new Date()).toISOString()),
              loop: null,
              name: type === "pentakill" ? "Pentakill" : "First Blood",
            };

            if (this.teamsAreFlipped()) {
              firebaseRealtimeDbUpdateStream(
                killerTeam === team100 ? 12 : 11,
                state
              );
            } else {
              firebaseRealtimeDbUpdateStream(
                killerTeam === team100 ? 11 : 12,
                state
              );
            }
          }
        }
        break;
      // case 'champion_kill': { // this is when a player dies
      //             team100 = window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`] && window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].team100;
      //             team200 = window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`] && window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].team200;

      //             if (liveStatsEvent.killerTeamID === 100) {
      //                 killerPlayer = team100 && team100.players && team100.players.find(p => p.participantID === liveStatsEvent.killer);
      //                 victimPlayer = team200 && team200.players && team200.players.find(p => p.participantID === liveStatsEvent.victim);
      //             } else {
      //                 killerPlayer = team200 && team200.players && team200.players.find(p => p.participantID === liveStatsEvent.killer);
      //                 victimPlayer = team100 && team100.players && team100.players.find(p => p.participantID === liveStatsEvent.victim);
      //             }

      //             const state = {
      //                 deathVideoUrl: 'https://www.w3schools.com/tags/movie.mp4',
      //                 // lastDeathTime: liveStatsEvent.date || ((new Date()).toISOString()),
      //                 lastDeathTime: ((new Date()).toISOString()),
      //             };
      //             if (victimPlayer) {
      //                 let screenNumber;
      //                 if (this.teamsAreFlipped()) {
      //                     if (liveStatsEvent.killerTeamID === 100) {
      //                         screenNumber = victimPlayer.arrayIndex + 1;
      //                     } else {
      //                         screenNumber = 6 + victimPlayer.arrayIndex;
      //                     }
      //                 } else {
      //                     if (liveStatsEvent.killerTeamID === 100) {
      //                         screenNumber = 6 + victimPlayer.arrayIndex;
      //                     } else {
      //                         screenNumber = victimPlayer.arrayIndex + 1;
      //                     }
      //                 }
      //                 console.log('champion victim', victimPlayer, screenNumber, state);
      //                 firebaseRealtimeDbSetDataCgpc(`${screenNumber - 1}/deathVideoUrl`, state.deathVideoUrl);
      //                 firebaseRealtimeDbSetDataCgpc(`${screenNumber - 1}/lastDeathTime`, state.lastDeathTime);
      //             }
      //         }
      //     break;
      default:
        break;
    }
  };

  processStatsUpdate = (liveStats) => {
    const gameTimeMs = parseInt(liveStats.gameTime) || 0;
    if (!this.state.jungleProximityProcessingStatus && gameTimeMs > 900000) {
      // the stats need to be calculated from the 3rd minute to the 15th minute (180s - 900s)
      this.processJunglerProximityStats();
    }
    const existingStats =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].stats || {};
    liveStats.participants.forEach((participant) => {
      // console.log("participant", participant);
      const existing = existingStats[`${participant.accountID}`] || {
        totalDamageDealtToChampions: {},
        positions: {},
      };
      existing.totalGold = participant.totalGold;
      existing.currentGold = participant.currentGold;
      existing.accountID = `${participant.accountID}`;
      // Get the evolution of the total damage dealt
      existing.totalDamageDealtToChampions[parseInt(gameTimeMs / 1000)] =
        parseInt(participant.stats[38].value);
      //  Get current total damage dealt to turret
      existing.totalDamageDealtToTurrets = parseInt(
        participant.stats[49].value
      );
      existing.positions[parseInt(gameTimeMs / 1000)] = {
        ...get(existing, `positions[${parseInt(gameTimeMs / 1000)}]`, {}),
        ...participant.position,
        alive: participant.alive,
      };

      // Get the XP score and Level
      existing.level = participant.level;
      existing.XP = participant.XP;
      // const goldRange = existing.goldRange || {};
      // const remainder = parseInt(liveStats.gameTime) % 20000;
      // const timeGroup = (parseInt(liveStats.gameTime) - remainder) / 1000;
      // if (!goldRange[timeGroup]) {
      //     goldRange[timeGroup] = participant.totalGold;
      //     existing.goldRange = goldRange;
      existingStats[`${participant.accountID}`] = existing;
      //     // console.log('gold', participant.accountID, goldRange);
      // }
    });
    window.MainData.realtimeApi.events[
      `${this.state.platform}_${this.state.gameId}`
    ].stats = existingStats;
    const team100 = liveStats.teams.find((team) => team.teamID === 100);
    const team200 = liveStats.teams.find((team) => team.teamID === 200);
    if (team100 && team200) {
      const existingTeamStats =
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].teamStats || {};
      const existing100 = existingTeamStats.team100 || {};
      const existing200 = existingTeamStats.team200 || {};

      const goldRange100 = existing100.goldRange || {};
      const goldRange200 = existing200.goldRange || {};

      const remainder = gameTimeMs % 20000;
      const timeGroup = (gameTimeMs - remainder) / 1000;
      if (!goldRange100[timeGroup]) {
        goldRange100[timeGroup] = team100.totalGold;
        existing100.goldRange = goldRange100;
      }
      if (!goldRange200[timeGroup]) {
        goldRange200[timeGroup] = team200.totalGold;
        existing200.goldRange = goldRange200;
      }

      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].teamStats = {
        team100: existing100,
        team200: existing200,
      };
    }
  };

  populateGameInfo = (liveStats) => {
    if (liveStats.participants) {
      const existingTeam100Players =
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].team100 &&
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].team100.players;
      const existingTeam200Players =
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].team200 &&
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].team200.players;
      liveStats.participants.forEach((participant) => {
        if (existingTeam100Players) {
          existingTeam100Players.forEach((p) => {
            if (p.accountID === participant.accountID) {
              p.participantID = participant.participantID;
            }
          });
        }
        if (existingTeam200Players) {
          existingTeam200Players.forEach((p) => {
            if (p.accountID === participant.accountID) {
              p.participantID = participant.participantID;
            }
          });
        }
      });
    }
  };

  populateTeamData = (liveStats) => {
    // console.log('populateTeamData', liveStats)
    if (!liveStats.teamOne || !liveStats.teamTwo) return;

    let tricode100, tricode200;

    const mainDatateam100 =
      window.MainData.schedule.rounds[window.MainData.schedule.round].matches[
        window.MainData.schedule.match
      ].team100;
    const mainDatateam200 =
      window.MainData.schedule.rounds[window.MainData.schedule.round].matches[
        window.MainData.schedule.match
      ].team200;

    // const expectedTricodes = [mainDatateam100.tricode, mainDatateam200.tricode];

    // tricode100 = calculateTricode(expectedTricodes, Object.values(liveStats.teamOne).map(player => player.summonerName));
    // tricode200 = calculateTricode(expectedTricodes, Object.values(liveStats.teamTwo).map(player => player.summonerName));

    // if (!tricode100) {
    const gameTeams =
      window.MainData.schedule.rounds[window.MainData.schedule.round].matches[
        window.MainData.schedule.match
      ].games[window.MainData.schedule.game].teams;
    const reversed =
      "200" ===
      (gameTeams[mainDatateam100.tricode] &&
        gameTeams[mainDatateam100.tricode].side);
    // console.log('reversed', reversed);
    if (reversed) {
      tricode200 = mainDatateam100.tricode;
      tricode100 = mainDatateam200.tricode;
    } else {
      tricode100 = mainDatateam100.tricode;
      tricode200 = mainDatateam200.tricode;
    }
    // }

    const teamOne = liveStats.teamOne;
    const teamTwo = liveStats.teamTwo;
    if (liveStats.gameState === "POST_CHAMP_SELECT") {
      this.saveDraftOrder(liveStats);
    }

    const existingTeam100Players =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100 &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100.players;
    if (existingTeam100Players) {
      existingTeam100Players.forEach((p) => {
        if (p.participantID && p.accountID) {
          if (teamOne[p.accountID]) {
            teamOne[p.accountID].participantID = p.participantID;
          }
        }
      });
    }
    const existingTeam200Players =
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200 &&
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200.players;
    if (existingTeam200Players) {
      existingTeam200Players.forEach((p) => {
        if (p.participantID && p.accountID) {
          if (teamTwo[p.accountID]) {
            teamTwo[p.accountID].participantID = p.participantID;
          }
        }
      });
    }

    window.MainData.realtimeApi.events[
      `${this.state.platform}_${this.state.gameId}`
    ].team100 = Object.assign(
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100 || {},
      {
        tricode: tricode100,
        players: Object.values(teamOne),
        playerAccountIds: Object.values(teamOne).map(
          (player) => player.accountID
        ),
      }
    );
    window.MainData.realtimeApi.events[
      `${this.state.platform}_${this.state.gameId}`
    ].team200 = Object.assign(
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200 || {},
      {
        tricode: tricode200,
        players: Object.values(teamTwo),
        playerAccountIds: Object.values(teamTwo).map(
          (player) => player.accountID
        ),
      }
    );
  };

  populatePerkData = (liveStats) => {
    if (!liveStats.teamOne || !liveStats.teamTwo) return;
    if (
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100
    ) {
      const perks = {};
      Object.values(liveStats.teamOne).forEach((player) => {
        const storedPlayer =
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].team100.players &&
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].team100.players.find((p) => p.summonerName === player.summonerName);
        if (storedPlayer) {
          perks[playerPositions[storedPlayer.arrayIndex]] = {
            data: player.perks,
            keystoneID: player.keystoneID,
          };
        }
        storedPlayer.participantID = player.participantID;
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].team100.players = (
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].team100.players || []
        ).map((p) => {
          if (p.summonerName === storedPlayer.summonerName) {
            return storedPlayer;
          }
          return p;
        });
      });
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team100.perks = perks;
    }

    if (
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200
    ) {
      const perks = {};
      Object.values(liveStats.teamTwo).forEach((player) => {
        const storedPlayer =
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].team200.players &&
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].team200.players.find((p) => p.summonerName === player.summonerName);
        if (storedPlayer) {
          perks[playerPositions[storedPlayer.arrayIndex]] = {
            data: player.perks,
            keystoneID: player.keystoneID,
          };
        }
        storedPlayer.participantID = player.participantID;
        window.MainData.realtimeApi.events[
          `${this.state.platform}_${this.state.gameId}`
        ].team200.players = (
          window.MainData.realtimeApi.events[
            `${this.state.platform}_${this.state.gameId}`
          ].team200.players || []
        ).map((p) => {
          if (p.summonerName === storedPlayer.summonerName) {
            return storedPlayer;
          }
          return p;
        });
      });
      window.MainData.realtimeApi.events[
        `${this.state.platform}_${this.state.gameId}`
      ].team200.perks = perks;
    }
  };

  populateVisionData = (fujitsuDataEvent) => {
    window.MainData.realtimeApi.events[
      `${this.state.platform}_${this.state.gameId}`
    ].vision = fujitsuDataEvent.data;
    window.MainData.realtimeApi.events[
      `${this.state.platform}_${this.state.gameId}`
    ].vision.datetime = fujitsuDataEvent.date;
    // console.log('vision', window.MainData.realtimeApi.events[`${this.state.platform}_${this.state.gameId}`].vision, fujitsuDataEvent);
  };

  stopListeningGameLiveStats = () => {
    // stopListening();
    this.state.stopListeningFunc();
    this.setState({
      listeningGameLiveStats: false,
      turretPlateStatus: cloneDeep(initialTurretStatus),
      // turretsDestroyed: cloneDeep(initialTurretsDestroyed),
      // killCount: cloneDeep(initialKillCount),
      monsterKillHistory: cloneDeep(initialMonsterKillHistory),
      playersMythicItem: cloneDeep(initialPlayersMythicItem),
      eventHistory: cloneDeep(initialMonsterKillHistory),
      firstTurretDesctruction: undefined,
    });
  };

  stopListeningFujitsu = () => {
    this.state.fujitsuStopListeningFunc();
    this.setState({ listeningFujitsu: false });
  };

  handleDelay = (e) => {
    try {
      const value = parseInt(e.target.value, 10);
      if (Number.isNaN(value)) {
        this.setState({ liveStatusDelay: 0 }, () => {
          window.MainData.realtimeApi.liveStatusDelay = 0;
        });
        return;
      }
      this.setState({ liveStatusDelay: value }, () => {
        window.MainData.realtimeApi.liveStatusDelay =
          this.state.liveStatusDelay;
      });
    } catch (e) {
      this.setState({ liveStatusDelay: 0 }, () => {
        window.MainData.realtimeApi.liveStatusDelay = 0;
      });
    }
  };

  render() {
    return (
      <DataContainer
        componentSlug="dataApiListener"
        icon="fas fa-broadcast-tower"
      >
        <div
          className="data-panel__fieldset"
          style={{ overflowX: "auto", flexDirection: "column" }}
        >
          <div className="pill">
            {this.state.listeningGameLiveStats ? (
              <p style={{ color: "green" }}>--ACTIVE--</p>
            ) : (
              <p style={{ color: "red" }}>--INACTIVE--</p>
            )}
            <span>{`Game ID`}</span>
            <input
              id="game-id-label"
              className="input input--x-wide"
              placeholder={this.state.gameId}
              onChange={this.handleChangeGameId}
            ></input>
            {this.state.listeningGameLiveStats ? (
              <Button
                className={`button interactive `}
                action={() => this.stopListeningGameLiveStats()}
                text="STOP"
              />
            ) : (
              <Button
                className={`button interactive `}
                action={() => this.listenGameLiveStats()}
                text="LISTEN"
              />
            )}
            <TournamentRealmPicker
              onChange={this.updateRealm}
              default={this.props.platform}
            />
          </div>
          {/* <div className="pill">
                        {
                            this.state.listeningFujitsu ?
                                <p style={{ color: 'green' }}>--ACTIVE--</p> :
                                <p style={{ color: 'red' }}>--INACTIVE--</p>
                        }
                        <span>{'Fujitsu'}</span>
                        {this.state.listeningFujitsu ?
                            <Button className={`button interactive `} action={() => this.stopListeningFujitsu()} text="STOP" />
                            :
                            <Button className={`button interactive `} action={() => this.listenFujitsu()} text="LISTEN" />}
                        <StyleFujitsuIPInput
                            show={this.state.showFujitsuSettings}
                            type={'text'}
                            placeholder={get(window, 'MainData.fujitsuServerIP', undefined) || 'API URL'}
                            onChange={(e) => {
                                if (e.target.value.trim().startsWith('https://')) {
                                    window.MainData.fujitsuServerIP = e.target.value.trim().replace("https://", '');
                                }
                                else {
                                    window.MainData.fujitsuServerIP = e.target.value.trim();
                                }
                            }}
                        />
                        <StyleFujitsuConfig
                            onClick={() => {
                                this.setState({ showFujitsuSettings: !this.state.showFujitsuSettings });
                            }}
                        >
                            {this.state.showFujitsuSettings ?
                                <i key={'showFujitsuSettingsOpened'} className={'fas fa-times'} />
                                :
                                <i key={'showFujitsuSettingsClosed'} className={'fas fa-cogs'} />
                            }
                        </StyleFujitsuConfig>
                    </div> */}
          <div className="pill">
            <span>{`Delay (in seconds)`}</span>
            <input
              id="game-delay-input"
              className="input input--wide"
              value={this.state.liveStatusDelay}
              onChange={this.handleDelay}
            ></input>
            <span>{this.formatTimer(this.state.liveStatusDelay)}</span>
          </div>
        </div>
        <div
          className="data-panel__fieldset"
          style={{ flexDirection: "column" }}
        >
          {
            <GameID
              distributeGameId={this.props.distributeGameId}
              updateRealm={this.updateRealm}
            />
          }
        </div>
      </DataContainer>
    );
  }

  formatTimer = (time, decimal = false) => {
    const mins = Math.floor(time / 60);
    const secs = time % 60;
    return decimal
      ? time === ""
        ? ""
        : `${mins < 10 ? "0" + mins : mins}:${secs < 10 ? "0" + secs : secs}`
      : time === ""
      ? ""
      : `${mins}:${secs < 10 ? "0" + secs : secs}`;
  };
}

// const calculateTeamAndLaneFromPosition = (x, z, forceLane = false) => {
//     /** Begin RIOT code - given to us from Michael Bailey - slightly modified by us */
//     const BLUE_SPAWN_X = 0;
//     const BLUE_SPAWN_Y = 0;
//     const RED_SPAWN_X = 14820;
//     const RED_SPAWN_Y = 14881;
//     const MAP_WIDTH = RED_SPAWN_X - BLUE_SPAWN_X;
//     const MAP_HEIGHT = RED_SPAWN_Y - BLUE_SPAWN_Y;
//     const RED_BLUE_SIDE_TAN_VAL = MAP_HEIGHT / MAP_WIDTH; // The tangent value for the diagonal line to check side

//     let TAN_VAL_BOT_MAX = 0.27; // 15 deg
//     let TAN_VAL_MID_MIN = 0.70; // 35 deg
//     let TAN_VAL_MID_MAX = 1.43; // 55 deg
//     let TAN_VAL_TOP_MIN = 3.73; // 75 deg

//     if(forceLane){ // playbrain code
//         TAN_VAL_BOT_MAX = 0.485;
//         TAN_VAL_MID_MIN = 0.485;
//         TAN_VAL_MID_MAX = 2.58;
//         TAN_VAL_TOP_MIN = 2.58;
//     }

//     const determineEventLane = (x, y) => {
//         const calculated = {
//             lane: null,
//             team: null,
//         };

//         // 1. Figure out whether it's on blue or red side
//         const evtTanVal = y / (MAP_WIDTH - x);
//         let tanValRelativeToSpawn;
//         if(evtTanVal < RED_BLUE_SIDE_TAN_VAL){
//             tanValRelativeToSpawn = y / x; // blue side
//             calculated.team = 'team100';
//         }else{
//             tanValRelativeToSpawn = (MAP_WIDTH - x) / (MAP_HEIGHT - y); // red side
//             calculated.team = 'team200';
//         }

//         // 2. According to the tangent value relative to the
//         if (tanValRelativeToSpawn <= TAN_VAL_BOT_MAX) {
//             calculated.lane = 'bot';
//         } else if (tanValRelativeToSpawn >= TAN_VAL_TOP_MIN) {
//             calculated.lane = 'top';
//         } else if (tanValRelativeToSpawn >= TAN_VAL_MID_MIN && tanValRelativeToSpawn <= TAN_VAL_MID_MAX) {
//             calculated.lane = 'mid';
//         }
//         return calculated;
//     };
//     /** end RIOT code */

//     return determineEventLane(x, z);
// };

const StyleFujitsuConfig = styled.div`
  margin: auto -1px auto 5px;
  padding: 2px;
  color: #000000;
  cursor: pointer;
  svg {
    margin: 3px;
  }
`;

const StyleFujitsuIPInput = styled.input`
  margin-left: 10px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  transition: width 300ms ease-in;
  ${(props) =>
    props.show
      ? `width: 135px;
    padding: 0 5px;`
      : "width: 0px;"}
`;

export default DataApiListener;
