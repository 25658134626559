import { get } from "lodash";

// export const itemsList1 = {
//     "1001": {
//         "id": "1001",
//         "englishName": "Boots",
//         "name": "ブーツ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1001.png",
//     },
//     "1004": {
//         "id": "1004",
//         "englishName": "Faerie Charm",
//         "name": "フェアリー チャーム",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1004.png",
//     },
//     "1006": {
//         "id": "1006",
//         "englishName": "Rejuvenation Bead",
//         "name": "再生の珠",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1006.png",
//     },
//     "1011": {
//         "id": "1011",
//         "englishName": "Giant's Belt",
//         "name": "ジャイアント ベルト",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1011.png",
//     },
//     "1018": {
//         "id": "1018",
//         "englishName": "Cloak of Agility",
//         "name": "アジリティ クローク",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1018.png",
//     },
//     "1026": {
//         "id": "1026",
//         "englishName": "Blasting Wand",
//         "name": "ブラスティング ワンド",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1026.png",
//     },
//     "1027": {
//         "id": "1027",
//         "englishName": "Sapphire Crystal",
//         "name": "サファイア クリスタル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1027.png",
//     },
//     "1028": {
//         "id": "1028",
//         "englishName": "Ruby Crystal",
//         "name": "ルビー クリスタル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1028.png",
//     },
//     "1029": {
//         "id": "1029",
//         "englishName": "Cloth Armor",
//         "name": "クロース アーマー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1029.png",
//     },
//     "1031": {
//         "id": "1031",
//         "englishName": "Chain Vest",
//         "name": "チェイン ベスト",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1031.png",
//     },
//     "1033": {
//         "id": "1033",
//         "englishName": "Null-Magic Mantle",
//         "name": "ヌルマジック マント",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1033.png",
//     },
//     "1035": {
//         "id": "1035",
//         "englishName": "Emberknife",
//         "name": "残炎のナイフ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1035.png",
//     },
//     "1036": {
//         "id": "1036",
//         "englishName": "Long Sword",
//         "name": "ロング ソード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1036.png",
//     },
//     "1037": {
//         "id": "1037",
//         "englishName": "Pickaxe",
//         "name": "ピッケル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1037.png",
//     },
//     "1038": {
//         "id": "1038",
//         "englishName": "B. F. Sword",
//         "name": "B. F. ソード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1038.png",
//     },
//     "1039": {
//         "id": "1039",
//         "englishName": "Hailblade",
//         "name": "氷雨の刃",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1039.png",
//     },
//     "1042": {
//         "id": "1042",
//         "englishName": "Dagger",
//         "name": "ダガー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1042.png",
//     },
//     "1043": {
//         "id": "1043",
//         "englishName": "Recurve Bow",
//         "name": "リカーブ ボウ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1043.png",
//     },
//     "1052": {
//         "id": "1052",
//         "englishName": "Amplifying Tome",
//         "name": "増魔の書",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1052.png",
//     },
//     "1053": {
//         "id": "1053",
//         "englishName": "Vampiric Scepter",
//         "name": "ヴァンパイア セプター",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1053.png",
//     },
//     "1054": {
//         "id": "1054",
//         "englishName": "Doran's Shield",
//         "name": "ドラン シールド",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1054.png",
//     },
//     "1055": {
//         "id": "1055",
//         "englishName": "Doran's Blade",
//         "name": "ドラン ブレード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1055.png",
//     },
//     "1056": {
//         "id": "1056",
//         "englishName": "Doran's Ring",
//         "name": "ドラン リング",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1056.png",
//     },
//     "1057": {
//         "id": "1057",
//         "englishName": "Negatron Cloak",
//         "name": "ネガトロン クローク",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1057.png",
//     },
//     "1058": {
//         "id": "1058",
//         "englishName": "Needlessly Large Rod",
//         "name": "ムダニ デカイ ロッド",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1058.png",
//     },
//     "1082": {
//         "id": "1082",
//         "englishName": "Dark Seal",
//         "name": "ダーク シール",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1082.png",
//     },
//     "1083": {
//         "id": "1083",
//         "englishName": "Cull",
//         "name": "カル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1083.png",
//     },
//     "2003": {
//         "id": "2003",
//         "englishName": "Health Potion",
//         "name": "体力ポーション",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2003.png",
//     },
//     "2010": {
//         "id": "2010",
//         "englishName": "Total Biscuit of Everlasting Will",
//         "name": "英気満点ビスケット",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2010.png",
//     },
//     "2015": {
//         "id": "2015",
//         "englishName": "Kircheis Shard",
//         "name": "キルヒアイス シャード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2015.png",
//     },
//     "2031": {
//         "id": "2031",
//         "englishName": "Refillable Potion",
//         "name": "詰め替えポーション",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2031.png",
//     },
//     "2033": {
//         "id": "2033",
//         "englishName": "Corrupting Potion",
//         "name": "コラプト ポーション",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2033.png",
//     },
//     "2051": {
//         "id": "2051",
//         "englishName": "Guardian's Horn",
//         "name": "ガーディアン ホーン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2051.png",
//     },
//     "2052": {
//         "id": "2052",
//         "englishName": "Poro-Snax",
//         "name": "ポロのおやつ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2052.png",
//     },
//     "2055": {
//         "id": "2055",
//         "englishName": "Control Ward",
//         "name": "コントロール ワード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2055.png",
//     },
//     "2065": {
//         "id": "2065",
//         "englishName": "Shurelya's Battlesong",
//         "name": "シュレリアの戦歌",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2065.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/shurelya_s-battlesong_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/shurelya_s-battlesong_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/shurelya_s-battlesong_infobox.png",
//     },
//     "2138": {
//         "id": "2138",
//         "englishName": "Elixir of Iron",
//         "name": "アイアン エリクサー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2138.png",
//     },
//     "2139": {
//         "id": "2139",
//         "englishName": "Elixir of Sorcery",
//         "name": "ソーサリー エリクサー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2139.png",
//     },
//     "2140": {
//         "id": "2140",
//         "englishName": "Elixir of Wrath",
//         "name": "ラース エリクサー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2140.png",
//     },
//     "2403": {
//         "id": "2403",
//         "englishName": "Minion Dematerializer",
//         "name": "ミニオン吸収装置",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2403.png",
//     },
//     "2419": {
//         "id": "2419",
//         "englishName": "Commencing Stopwatch",
//         "name": "始まりのストップウォッチ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2419.png",
//     },
//     "2420": {
//         "id": "2420",
//         "englishName": "Stopwatch",
//         "name": "ストップウォッチ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2420.png",
//     },
//     "2421": {
//         "id": "2421",
//         "englishName": "Broken Stopwatch",
//         "name": "壊れたストップウォッチ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2421.png",
//     },
//     "2422": {
//         "id": "2422",
//         "englishName": "Slightly Magical Footwear",
//         "name": "ちょっとだけ魔法がかった靴",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2422.png",
//     },
//     "2423": {
//         "id": "2423",
//         "englishName": "Perfectly Timed Stopwatch",
//         "name": "完全精度のストップウォッチ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2423.png",
//     },
//     "2424": {
//         "id": "2424",
//         "englishName": "Broken Stopwatch",
//         "name": "壊れたストップウォッチ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2424.png",
//     },
//     "3001": {
//         "id": "3001",
//         "englishName": "Abyssal Mask",
//         "name": "アビサル マスク",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3001.png",
//     },
//     "3003": {
//         "id": "3003",
//         "englishName": "Archangel's Staff",
//         "name": "アークエンジェル スタッフ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3003.png",
//     },
//     "3004": {
//         "id": "3004",
//         "englishName": "Manamune",
//         "name": "マナムネ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3004.png",
//     },
//     "3006": {
//         "id": "3006",
//         "englishName": "Berserker's Greaves",
//         "name": "バーサーカー ブーツ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3006.png",
//     },
//     "3009": {
//         "id": "3009",
//         "englishName": "Boots of Swiftness",
//         "name": "スイフトネス ブーツ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3009.png",
//     },
//     "3011": {
//         "id": "3011",
//         "englishName": "Chemtech Putrifier",
//         "name": "ケミテック ピュートリファイアー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3011.png",
//     },
//     "3020": {
//         "id": "3020",
//         "englishName": "Sorcerer's Shoes",
//         "name": "ソーサラー シューズ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3020.png",
//     },
//     "3024": {
//         "id": "3024",
//         "englishName": "Glacial Buckler",
//         "name": "グレイシャル バックラー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3024.png",
//     },
//     "3026": {
//         "id": "3026",
//         "englishName": "Guardian Angel",
//         "name": "ガーディアン エンジェル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3026.png",
//     },
//     "3031": {
//         "id": "3031",
//         "englishName": "Infinity Edge",
//         "name": "インフィニティ エッジ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3031.png",
//     },
//     "3033": {
//         "id": "3033",
//         "englishName": "Mortal Reminder",
//         "name": "モータル リマインダー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3033.png",
//     },
//     "3035": {
//         "id": "3035",
//         "englishName": "Last Whisper",
//         "name": "ラスト ウィスパー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3035.png",
//     },
//     "3036": {
//         "id": "3036",
//         "englishName": "Lord Dominik's Regards",
//         "name": "ドミニク リガード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3036.png",
//     },
//     "3040": {
//         "id": "3040",
//         "englishName": "Seraph's Embrace",
//         "name": "セラフ エンブレイス",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3040.png",
//     },
//     "3041": {
//         "id": "3041",
//         "englishName": "Mejai's Soulstealer",
//         "name": "メジャイ ソウルスティーラー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3041.png",
//     },
//     "3042": {
//         "id": "3042",
//         "englishName": "Muramana",
//         "name": "ムラマナ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3042.png",
//     },
//     "3044": {
//         "id": "3044",
//         "englishName": "Phage",
//         "name": "ファージ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3044.png",
//     },
//     "3046": {
//         "id": "3046",
//         "englishName": "Phantom Dancer",
//         "name": "ファントム ダンサー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3046.png",
//     },
//     "3047": {
//         "id": "3047",
//         "englishName": "Plated Steelcaps",
//         "name": "プレート スチールキャップ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3047.png",
//     },
//     "3050": {
//         "id": "3050",
//         "englishName": "Zeke's Convergence",
//         "name": "ジーク コンバージェンス",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3050.png",
//     },
//     "3051": {
//         "id": "3051",
//         "englishName": "Hearthbound Axe",
//         "name": "ハースバウンド アックス",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3051.png",
//     },
//     "3053": {
//         "id": "3053",
//         "englishName": "Sterak's Gage",
//         "name": "ステラックの篭手",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3053.png",
//     },
//     "3057": {
//         "id": "3057",
//         "englishName": "Sheen",
//         "name": "シーン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3057.png",
//     },
//     "3065": {
//         "id": "3065",
//         "englishName": "Spirit Visage",
//         "name": "スピリット ビサージュ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3065.png",
//     },
//     "3066": {
//         "id": "3066",
//         "englishName": "Winged Moonplate",
//         "name": "翼のムーンプレート",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3066.png",
//     },
//     "3067": {
//         "id": "3067",
//         "englishName": "Kindlegem",
//         "name": "キンドルジェム",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3067.png",
//     },
//     "3068": {
//         "id": "3068",
//         "englishName": "Sunfire Aegis",
//         "name": "サンファイア イージス",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3068.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/sunfire-aegis_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/sunfire-aegis_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/sunfire-aegis_infobox.png",
//     },
//     "3070": {
//         "id": "3070",
//         "englishName": "Tear of the Goddess",
//         "name": "女神の涙",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3070.png",
//     },
//     "3071": {
//         "id": "3071",
//         "englishName": "Black Cleaver",
//         "name": "ブラック クリーバー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3071.png",
//     },
//     "3072": {
//         "id": "3072",
//         "englishName": "Bloodthirster",
//         "name": "ブラッドサースター",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3072.png",
//     },
//     "3074": {
//         "id": "3074",
//         "englishName": "Ravenous Hydra",
//         "name": "ラヴァナス ハイドラ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3074.png",
//     },
//     "3075": {
//         "id": "3075",
//         "englishName": "Thornmail",
//         "name": "ソーンメイル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3075.png",
//     },
//     "3076": {
//         "id": "3076",
//         "englishName": "Bramble Vest",
//         "name": "ブランブル ベスト",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3076.png",
//     },
//     "3077": {
//         "id": "3077",
//         "englishName": "Tiamat",
//         "name": "ティアマット",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3077.png",
//     },
//     "3078": {
//         "id": "3078",
//         "englishName": "Trinity Force",
//         "name": "トリニティ フォース",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3078.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/trinity-force_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/trinity-force_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/trinity-force_infobox.png",
//     },
//     "3082": {
//         "id": "3082",
//         "englishName": "Warden's Mail",
//         "name": "ワーデン メイル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3082.png",
//     },
//     "3083": {
//         "id": "3083",
//         "englishName": "Warmog's Armor",
//         "name": "ワーモグ アーマー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3083.png",
//     },
//     "3084": {
//         "id": "3084",
//         "englishName": "Heartsteel",
//         "name": "心の鋼",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3084.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/heartsteel_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/heartsteel_side_left.png",
//         },
//         "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/heartsteel_infobox.png",
//     },
//     "3085": {
//         "id": "3085",
//         "englishName": "Runaan's Hurricane",
//         "name": "ルナーン ハリケーン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3085.png",
//     },
//     "3086": {
//         "id": "3086",
//         "englishName": "Zeal",
//         "name": "ジール",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3086.png",
//     },
//     "3089": {
//         "id": "3089",
//         "englishName": "Rabadon's Deathcap",
//         "name": "ラバドン デスキャップ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3089.png",
//     },
//     "3091": {
//         "id": "3091",
//         "englishName": "Wit's End",
//         "name": "ウィッツ エンド",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3091.png",
//     },
//     "3094": {
//         "id": "3094",
//         "englishName": "Rapid Firecannon",
//         "name": "ラピッド ファイアキャノン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3094.png",
//     },
//     "3095": {
//         "id": "3095",
//         "englishName": "Stormrazor",
//         "name": "ストームレイザー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3095.png",
//     },
//     "3100": {
//         "id": "3100",
//         "englishName": "Lich Bane",
//         "name": "リッチ ベイン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3100.png",
//     },
//     "3102": {
//         "id": "3102",
//         "englishName": "Banshee's Veil",
//         "name": "バンシー ヴェール",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3102.png",
//     },
//     "3105": {
//         "id": "3105",
//         "englishName": "Aegis of the Legion",
//         "name": "イージスの盾",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3105.png",
//     },
//     "3107": {
//         "id": "3107",
//         "englishName": "Redemption",
//         "name": "リデンプション",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3107.png",
//     },
//     "3108": {
//         "id": "3108",
//         "englishName": "Fiendish Codex",
//         "name": "フィンディッシュの古書",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3108.png",
//     },
//     "3109": {
//         "id": "3109",
//         "englishName": "Knight's Vow",
//         "name": "騎士の誓い",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3109.png",
//     },
//     "3110": {
//         "id": "3110",
//         "englishName": "Frozen Heart",
//         "name": "フローズン ハート",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3110.png",
//     },
//     "3111": {
//         "id": "3111",
//         "englishName": "Mercury's Treads",
//         "name": "マーキュリー ブーツ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3111.png",
//     },
//     "3112": {
//         "id": "3112",
//         "englishName": "Guardian's Orb",
//         "name": "ガーディアン オーブ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3112.png",
//     },
//     "3113": {
//         "id": "3113",
//         "englishName": "Aether Wisp",
//         "name": "エーテル ウィスプ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3113.png",
//     },
//     "3114": {
//         "id": "3114",
//         "englishName": "Forbidden Idol",
//         "name": "フォビドゥン アイドル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3114.png",
//     },
//     "3115": {
//         "id": "3115",
//         "englishName": "Nashor's Tooth",
//         "name": "ナッシャー トゥース",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3115.png",
//     },
//     "3116": {
//         "id": "3116",
//         "englishName": "Rylai's Crystal Scepter",
//         "name": "リーライ クリスタル セプター",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3116.png",
//     },
//     "3117": {
//         "id": "3117",
//         "englishName": "Mobility Boots",
//         "name": "モビリティ ブーツ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3117.png",
//     },
//     "3123": {
//         "id": "3123",
//         "englishName": "Executioner's Calling",
//         "name": "エクスキューショナー コーリング",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3123.png",
//     },
//     "3124": {
//         "id": "3124",
//         "englishName": "Guinsoo's Rageblade",
//         "name": "グインソー レイジブレード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3124.png",
//     },
//     "3133": {
//         "id": "3133",
//         "englishName": "Caulfield's Warhammer",
//         "name": "コールフィールド ウォーハンマー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3133.png",
//     },
//     "3134": {
//         "id": "3134",
//         "englishName": "Serrated Dirk",
//         "name": "セレイテッド ダーク",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3134.png",
//     },
//     "3135": {
//         "id": "3135",
//         "englishName": "Void Staff",
//         "name": "ヴォイド スタッフ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3135.png",
//     },
//     "3139": {
//         "id": "3139",
//         "englishName": "Mercurial Scimitar",
//         "name": "マーキュリアル シミター",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3139.png",
//     },
//     "3140": {
//         "id": "3140",
//         "englishName": "Quicksilver Sash",
//         "name": "クイックシルバー サッシュ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3140.png",
//     },
//     "3142": {
//         "id": "3142",
//         "englishName": "Youmuu's Ghostblade",
//         "name": "妖夢の霊剣",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3142.png",
//     },
//     "3143": {
//         "id": "3143",
//         "englishName": "Randuin's Omen",
//         "name": "ランデュイン オーメン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3143.png",
//     },
//     "3145": {
//         "id": "3145",
//         "englishName": "Hextech Alternator",
//         "name": "ヘクステック オルタネーター",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3145.png",
//     },
//     "3152": {
//         "id": "3152",
//         "englishName": "Hextech Rocketbelt",
//         "name": "ヘクステック ロケットベルト",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3152.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/hextech-rocketbelt_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/hextech-rocketbelt_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/hextech-rocketbelt_infobox.png",
//     },
//     "3153": {
//         "id": "3153",
//         "englishName": "Blade of The Ruined King",
//         "name": "ルインドキング ブレード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3153.png",
//     },
//     "3155": {
//         "id": "3155",
//         "englishName": "Hexdrinker",
//         "name": "ヘクスドリンカー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3155.png",
//     },
//     "3156": {
//         "id": "3156",
//         "englishName": "Maw of Malmortius",
//         "name": "マルモティウスの胃袋",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3156.png",
//     },
//     "3157": {
//         "id": "3157",
//         "englishName": "Zhonya's Hourglass",
//         "name": "ゾーニャの砂時計",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3157.png",
//     },
//     "3158": {
//         "id": "3158",
//         "englishName": "Ionian Boots of Lucidity",
//         "name": "アイオニア ブーツ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3158.png",
//     },
//     "3165": {
//         "id": "3165",
//         "englishName": "Morellonomicon",
//         "name": "モレロノミコン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3165.png",
//     },
//     "3177": {
//         "id": "3177",
//         "englishName": "Guardian's Blade",
//         "name": "ガーディアン ブレード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3177.png",
//     },
//     "3179": {
//         "id": "3179",
//         "englishName": "Umbral Glaive",
//         "name": "アンブラル グレイブ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3179.png",
//     },
//     "3181": {
//         "id": "3181",
//         "englishName": "Hullbreaker",
//         "name": "ハルブレイカー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3181.png",
//     },
//     "3184": {
//         "id": "3184",
//         "englishName": "Guardian's Hammer",
//         "name": "ガーディアン ハンマー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3184.png",
//     },
//     "3190": {
//         "id": "3190",
//         "englishName": "Locket of the Iron Solari",
//         "name": "ソラリのロケット",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3190.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/locket-of-the-iron-solari_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/locket-of-the-iron-solari_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/locket-of-the-iron-solari_infobox.png",
//     },
//     "3191": {
//         "id": "3191",
//         "englishName": "Seeker's Armguard",
//         "name": "シーカー アームガード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3191.png",
//     },
//     "3193": {
//         "id": "3193",
//         "englishName": "Gargoyle Stoneplate",
//         "name": "ガーゴイル ストーンプレート",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3193.png",
//     },
//     "3211": {
//         "id": "3211",
//         "englishName": "Spectre's Cowl",
//         "name": "スペクター カウル",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3211.png",
//     },
//     "3222": {
//         "id": "3222",
//         "englishName": "Mikael's Blessing",
//         "name": "ミカエルの祝福",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3222.png",
//     },
//     "3330": {
//         "id": "3330",
//         "englishName": "Scarecrow Effigy",
//         "name": "身代わり人形",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3330.png",
//     },
//     "3340": {
//         "id": "3340",
//         "englishName": "Stealth Ward",
//         "name": "ステルス ワード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3340.png",
//     },
//     "3363": {
//         "id": "3363",
//         "englishName": "Farsight Alteration",
//         "name": "ファーサイト オルタレーション",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3363.png",
//     },
//     "3364": {
//         "id": "3364",
//         "englishName": "Oracle Lens",
//         "name": "オラクル レンズ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3364.png",
//     },
//     "3400": {
//         "id": "3400",
//         "englishName": "Your Cut",
//         "name": "お前の取り分",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3400.png",
//     },
//     "3504": {
//         "id": "3504",
//         "englishName": "Ardent Censer",
//         "name": "アーデント センサー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3504.png",
//     },
//     "3508": {
//         "id": "3508",
//         "englishName": "Essence Reaver",
//         "name": "エッセンス リーバー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3508.png",
//     },
//     "3513": {
//         "id": "3513",
//         "englishName": "Eye of the Herald",
//         "name": "ヘラルドの瞳",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3513.png",
//     },
//     "3599": {
//         "id": "3599",
//         "englishName": "Kalista's Black Spear",
//         "name": "カリスタの復讐の黒槍",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3599.png",
//     },
//     "3600": {
//         "id": "3600",
//         "englishName": "Kalista's Black Spear",
//         "name": "カリスタの復讐の黒槍",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3600.png",
//     },
//     "3742": {
//         "id": "3742",
//         "englishName": "Dead Man's Plate",
//         "name": "デッド マン プレート",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3742.png",
//     },
//     "3748": {
//         "id": "3748",
//         "englishName": "Titanic Hydra",
//         "name": "タイタン ハイドラ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3748.png",
//     },
//     "3801": {
//         "id": "3801",
//         "englishName": "Crystalline Bracer",
//         "name": "クリスタル ブレーサー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3801.png",
//     },
//     "3802": {
//         "id": "3802",
//         "englishName": "Lost Chapter",
//         "name": "ロスト チャプター",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3802.png",
//     },
//     "3814": {
//         "id": "3814",
//         "englishName": "Edge of Night",
//         "name": "ナイト エッジ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3814.png",
//     },
//     "3850": {
//         "id": "3850",
//         "englishName": "Spellthief's Edge",
//         "name": "スペルシーフ エッジ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3850.png",
//     },
//     "3851": {
//         "id": "3851",
//         "englishName": "Frostfang",
//         "name": "フロストファング",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3851.png",
//     },
//     "3853": {
//         "id": "3853",
//         "englishName": "Shard of True Ice",
//         "name": "真なる氷のかけら",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3853.png",
//     },
//     "3854": {
//         "id": "3854",
//         "englishName": "Steel Shoulderguards",
//         "name": "鋼のショルダーガード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3854.png",
//     },
//     "3855": {
//         "id": "3855",
//         "englishName": "Runesteel Spaulders",
//         "name": "ルーン鋼の肩当て",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3855.png",
//     },
//     "3857": {
//         "id": "3857",
//         "englishName": "Pauldrons of Whiterock",
//         "name": "ホワイトロックの肩鎧",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3857.png",
//     },
//     "3858": {
//         "id": "3858",
//         "englishName": "Relic Shield",
//         "name": "レリック シールド",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3858.png",
//     },
//     "3859": {
//         "id": "3859",
//         "englishName": "Targon's Buckler",
//         "name": "ターゴンの盾",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3859.png",
//     },
//     "3860": {
//         "id": "3860",
//         "englishName": "Bulwark of the Mountain",
//         "name": "霊峰の砦",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3860.png",
//     },
//     "3862": {
//         "id": "3862",
//         "englishName": "Spectral Sickle",
//         "name": "霊者の鎌",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3862.png",
//     },
//     "3863": {
//         "id": "3863",
//         "englishName": "Harrowing Crescent",
//         "name": "暗黒の刻の三日月鎌",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3863.png",
//     },
//     "3864": {
//         "id": "3864",
//         "englishName": "Black Mist Scythe",
//         "name": "黒き霧の大鎌",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3864.png",
//     },
//     "3916": {
//         "id": "3916",
//         "englishName": "Oblivion Orb",
//         "name": "忘却のオーブ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3916.png",
//     },
//     "4005": {
//         "id": "4005",
//         "englishName": "Imperial Mandate",
//         "name": "帝国の指令",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4005.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/imperial-mandate_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/imperial-mandate_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/imperial-mandate_infobox.png",
//     },
//     "4401": {
//         "id": "4401",
//         "englishName": "Force of Nature",
//         "name": "自然の力",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4401.png",
//     },
//     "4403": {
//         "id": "4403",
//         "englishName": "The Golden Spatula",
//         "name": "ザ・金のへら",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4403.png",
//     },
//     "4628": {
//         "id": "4628",
//         "englishName": "Horizon Focus",
//         "name": "ホライゾン フォーカス",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4628.png",
//     },
//     "4629": {
//         "id": "4629",
//         "englishName": "Cosmic Drive",
//         "name": "コズミック ドライブ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4629.png",
//     },
//     "4630": {
//         "id": "4630",
//         "englishName": "Blighting Jewel",
//         "name": "枯死の宝石",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4630.png",
//     },
//     "4632": {
//         "id": "4632",
//         "englishName": "Verdant Barrier",
//         "name": "ヴァーダント バリア",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4632.png",
//     },
//     "4633": {
//         "id": "4633",
//         "englishName": "Riftmaker",
//         "name": "リフトメーカー",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4633.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/riftmaker_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/riftmaker_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/riftmaker_infobox.png",
//     },
//     "4635": {
//         "id": "4635",
//         "englishName": "Leeching Leer",
//         "name": "リーチング リア",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4635.png",
//     },
//     "4636": {
//         "id": "4636",
//         "englishName": "Night Harvester",
//         "name": "ナイト ハーベスター",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4636.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/night-harvester_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/night-harvester_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/night-harvester_infobox.png",
//     },
//     "4637": {
//         "id": "4637",
//         "englishName": "Demonic Embrace",
//         "name": "悪魔の抱擁",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4637.png",
//     },
//     "4638": {
//         "id": "4638",
//         "englishName": "Watchful Wardstone",
//         "name": "ウォッチフル ワードストーン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4638.png",
//     },
//     "4642": {
//         "id": "4642",
//         "englishName": "Bandleglass Mirror",
//         "name": "バンドルグラスの鏡",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4642.png",
//     },
//     "4643": {
//         "id": "4643",
//         "englishName": "Vigilant Wardstone",
//         "name": "ビジラント ワードストーン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4643.png",
//     },
//     "6029": {
//         "id": "6029",
//         "englishName": "Ironspike Whip",
//         "name": "アイアンスパイク ウィップ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6029.png",
//     },
//     "6035": {
//         "id": "6035",
//         "englishName": "Silvermere Dawn",
//         "name": "シルバーミアの夜明け",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6035.png",
//     },
//     "6333": {
//         "id": "6333",
//         "englishName": "Death's Dance",
//         "name": "デス ダンス",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6333.png",
//     },
//     "6609": {
//         "id": "6609",
//         "englishName": "Chempunk Chainsword",
//         "name": "ケミパンク チェーンソード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6609.png",
//     },
//     "6616": {
//         "id": "6616",
//         "englishName": "Staff of Flowing Water",
//         "name": "フロー ウォーター スタッフ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6616.png",
//     },
//     "6617": {
//         "id": "6617",
//         "englishName": "Moonstone Renewer",
//         "name": "ムーンストーンの再生",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6617.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/moonstone-renewer_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/moonstone-renewer_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/moonstone-renewer_infobox.png",
//     },
//     "6630": {
//         "id": "6630",
//         "englishName": "Goredrinker",
//         "name": "ゴアドリンカー",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6630.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/goredrinker_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/goredrinker_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/goredrinker_infobox.png",
//     },
//     "6631": {
//         "id": "6631",
//         "englishName": "Stridebreaker",
//         "name": "ストライドブレイカー",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6631.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/stridebreaker_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/stridebreaker_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/stridebreaker_infobox.png",
//     },
//     "6632": {
//         "id": "6632",
//         "englishName": "Divine Sunderer",
//         "name": "ディヴァイン サンダラー",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6632.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/divine-sunderer_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/divine-sunderer_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/divine-sunderer_infobox.png",
//     },
//     "6653": {
//         "id": "6653",
//         "englishName": "Liandry's Anguish",
//         "name": "ライアンドリーの苦悶",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6653.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/liandry_s-anguish_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/liandry_s-anguish_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/liandry_s-anguish_infobox.png",
//     },
//     "6655": {
//         "id": "6655",
//         "englishName": "Luden's Tempest",
//         "name": "ルーデン テンペスト",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6655.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/luden_s-tempest_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/luden_s-tempest_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/luden_s-tempest_infobox.png",
//     },
//     "6656": {
//         "id": "6656",
//         "englishName": "Everfrost",
//         "name": "エバーフロスト",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6656.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/everfrost_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/everfrost_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/everfrost_infobox.png",
//     },
//     "6657": {
//         "id": "6657",
//         "englishName": "Rod of Ages",
//         "name": "ロッド オブ エイジス",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6657.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/rod-of-ages_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/rod-of-ages_side_left.png",
//         },
//         "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/rod-of-ages_infobox.png",
//     },
//     "6660": {
//         "id": "6660",
//         "englishName": "Bami's Cinder",
//         "name": "バミ シンダー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6660.png",
//     },
//     // "6662": {
//     //     "id": "6662",
//     //     "englishName": "Frostfire Gauntlet",
//     //     "name": "フロストファイア ガントレット",
//     //     "isMythic": true,
//     //     "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6662.png",
//     //     "sidePopupAsset": {
//     //         "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/frostfire-gauntlet_side_right.png",
//     //         "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/frostfire-gauntlet_side_left.png",
//     //     },
//     //     "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/frostfire-gauntlet_infobox.png",
//     // },
//     "6664": {
//         "id": "6664",
//         "englishName": "Turbo Chemtank",
//         "name": "ターボ ケミタンク",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6664.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/turbo-chemtank_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/turbo-chemtank_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/turbo-chemtank_infobox.png",
//     },
//     "6665": {
//         "id": "6665",
//         "englishName": "Jak'Sho, The Protean",
//         "name": "変幻自在のジャック=ショー",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6665.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/jak'sho,-the-protean_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/jak'sho,-the-protean_side_left.png",
//         },
//         "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/jak'sho,-the-protean_infobox.png",
//     },
//     "6667": {
//         "id": "6667",
//         "englishName": "Radiant Virtue",
//         "name": "レディアント ヴァーチュ",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6667.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/radiant-virtue_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/radiant-virtue_side_left.png",
//         },
//         "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/radiant-virtue_infobox.png",
//     },
//     "6670": {
//         "id": "6670",
//         "englishName": "Noonquiver",
//         "name": "ヌーンクィヴァー",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6670.png",
//     },
//     "6671": {
//         "id": "6671",
//         "englishName": "Galeforce",
//         "name": "ゲイルフォース",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6671.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/galeforce_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/galeforce_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/galeforce_infobox.png",
//     },
//     "6672": {
//         "id": "6672",
//         "englishName": "Kraken Slayer",
//         "name": "クラーケン スレイヤー",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6672.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/kraken-slayer_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/kraken-slayer_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/kraken-slayer_infobox.png",
//     },
//     "6673": {
//         "id": "6673",
//         "englishName": "Immortal Shieldbow",
//         "name": "イモータル シールドボウ",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6673.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/immortal-shieldbow_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/immortal-shieldbow_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/immortal-shieldbow_infobox.png",
//     },
//     "6675": {
//         "id": "6675",
//         "englishName": "Navori Quickblades",
//         "name": "ナヴォリ クイックブレード",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6675.png",
//     },
//     "6676": {
//         "id": "6676",
//         "englishName": "The Collector",
//         "name": "コレクター",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6676.png",
//     },
//     "6677": {
//         "id": "6677",
//         "englishName": "Rageknife",
//         "name": "レイジナイフ",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6677.png",
//     },
//     "6691": {
//         "id": "6691",
//         "englishName": "Duskblade of Draktharr",
//         "name": "ドラクサー ダスクブレード",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6691.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/duskblade-of-draktharr_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/duskblade-of-draktharr_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/duskblade-of-draktharr_infobox.png",
//     },
//     "6692": {
//         "id": "6692",
//         "englishName": "Eclipse",
//         "name": "赤月の刃",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6692.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/eclipse_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/eclipse_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/eclipse_infobox.png",
//     },
//     "6693": {
//         "id": "6693",
//         "englishName": "Prowler's Claw",
//         "name": "プローラー クロウ",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6693.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/prowler_s-claw_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/prowler_s-claw_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/prowler_s-claw_infobox.png",
//     },
//     "6694": {
//         "id": "6694",
//         "englishName": "Serylda's Grudge",
//         "name": "セリルダの怨恨",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6694.png",
//     },
//     "6695": {
//         "id": "6695",
//         "englishName": "Serpent's Fang",
//         "name": "毒蛇の牙",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6695.png",
//     },
//     "7000": {
//         "id": "7000",
//         "englishName": "Sandshrike's Claw",
//         "name": "サンドシュライク クロウ",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7000.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/sandshrike_s-claw_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/sandshrike_s-claw_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/sandshrike_s-claw_infobox.png",
//     },
//     "7001": {
//         "id": "7001",
//         "englishName": "Syzygy",
//         "name": "朔望",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7001.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/syzygy_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/syzygy_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/syzygy_infobox.png",
//     },
//     "7002": {
//         "id": "7002",
//         "englishName": "Draktharr's Shadowcarver",
//         "name": "ドラクサー シャドウカーバー",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7002.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/draktharr_s-shadowcarver_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/draktharr_s-shadowcarver_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/draktharr_s-shadowcarver_infobox.png",
//     },
//     "7003": {
//         "id": "7003",
//         "englishName": "Turbocharged Hexperiment",
//         "name": "ターボチャージ ヘクスペリメント",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7003.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/turbocharged-hexperiment_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/turbocharged-hexperiment_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/turbocharged-hexperiment_infobox.png",
//     },
//     "7004": {
//         "id": "7004",
//         "englishName": "Forgefire Crest",
//         "name": "フォージファイア クレスト",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7004.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/forgefire-crest_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/forgefire-crest_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/forgefire-crest_infobox.png",
//     },
//     "7005": {
//         "id": "7005",
//         "englishName": "Frozen Fist",
//         "name": "フローズン フィスト",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7005.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/frozen-fist_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/frozen-fist_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/rimeforged-grasp_infobox.png",
//     },
//     "7006": {
//         "id": "7006",
//         "englishName": "Typhoon",
//         "name": "タイフーン",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7006.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/typhoon_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/typhoon_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/typhoon_infobox.png",
//     },
//     "7007": {
//         "id": "7007",
//         "englishName": "Wyrmfallen Sacrifice",
//         "name": "ワームフォールン サクリファイス",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7007.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/wyrmfallen-sacrifice_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/wyrmfallen-sacrifice_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/wyrmfallen-sacrifice_infobox.png",
//     },
//     "7008": {
//         "id": "7008",
//         "englishName": "Bloodward",
//         "name": "ブラッドワード",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7008.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/bloodward_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/bloodward_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/bloodward_infobox.png",
//     },
//     "7009": {
//         "id": "7009",
//         "englishName": "Icathia's Curse",
//         "name": "イカシアの呪い",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7009.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/icathia_s-curse_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/icathia_s-curse_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/icathia_s-curse_infobox.png",
//     },
//     "7010": {
//         "id": "7010",
//         "englishName": "Vespertide",
//         "name": "ヴェスパータイド",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7010.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/vespertide_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/vespertide_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/vespertide_infobox.png",
//     },
//     "7011": {
//         "id": "7011",
//         "englishName": "Upgraded Aeropack",
//         "name": "アップグレード エアロパック",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7011.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/upgraded-aeropack_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/upgraded-aeropack_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/upgraded-aeropack_infobox.png",
//     },
//     "7012": {
//         "id": "7012",
//         "englishName": "Liandry's Lament",
//         "name": "ライアンドリーの悲嘆",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7012.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/liandry_s-lament_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/liandry_s-lament_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/liandry_s-lament_infobox.png",
//     },
//     "7013": {
//         "id": "7013",
//         "englishName": "Eye of Luden",
//         "name": "ルーデンの瞳",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7013.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/eye-of-luden_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/eye-of-luden_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/eye-of-luden_infobox.png",
//     },
//     "7014": {
//         "id": "7014",
//         "englishName": "Eternal Winter",
//         "name": "永遠の冬",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7014.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/eternal-winter_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/eternal-winter_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/eternal-winter_infobox.png",
//     },
//     "7015": {
//         "id": "7015",
//         "englishName": "Ceaseless Hunger",
//         "name": "絶え間なき渇き",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7015.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/ceaseless-hunger_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/ceaseless-hunger_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/ceaseless-hunger_infobox.png",
//     },
//     "7016": {
//         "id": "7016",
//         "englishName": "Dreamshatter",
//         "name": "ドリームシャター",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7016.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/dreamshatter_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/dreamshatter_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/dreamshatter_infobox.png",
//     },
//     "7017": {
//         "id": "7017",
//         "englishName": "Deicide",
//         "name": "神殺し",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7017.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/deicide_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/deicide_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/deicide_infobox.png",
//     },
//     "7018": {
//         "id": "7018",
//         "englishName": "Infinity Force",
//         "name": "インフィニティ フォース",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7018.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/infinity-force_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/infinity-force_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/infinity-force_infobox.png",
//     },
//     "7019": {
//         "id": "7019",
//         "englishName": "Reliquary of the Golden Dawn",
//         "name": "来光の聖遺物",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7019.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/reliquary-of-the-golden-dawn_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/reliquary-of-the-golden-dawn_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/reliquary-of-the-golden-dawn_infobox.png",
//     },
//     "7020": {
//         "id": "7020",
//         "englishName": "Shurelya's Requiem",
//         "name": "シュレリアの鎮魂歌",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7020.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/shurelya_s-requiem_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/shurelya_s-requiem_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/shurelya_s-requiem_infobox.png",
//     },
//     "7021": {
//         "id": "7021",
//         "englishName": "Starcaster",
//         "name": "スターキャスター",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7021.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/starcaster_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/starcaster_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/starcaster_infobox.png",
//     },
//     "7022": {
//         "id": "7022",
//         "englishName": "Seat of Command",
//         "name": "統率者の座",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7022.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/seat-of-command_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/seat-of-command_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/seat-of-command_infobox.png",
//     },
//     "7025": {
//         "id": "7025",
//         "englishName": "Leviathan",
//         "name": "リヴァイアサン",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7025.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/leviathan_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/leviathan_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/leviathan_infobox.png"
//     },
//     "7026": {
//         "id": "7026",
//         "englishName": "The Unspoken Parasite",
//         "name": "アンスポークン パラサイト",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7026.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/the-unspoken-parasite_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/the-unspoken-parasite_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/the-unspoken-parasite_infobox.png"
//     },
//     "7027": {
//         "id": "7027",
//         "englishName": "Primordial Dawn",
//         "name": "始原の暁",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7027.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/primordial-dawn_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/primordial-dawn_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/primordial-dawn_infobox.png"
//     },
//     "7028": {
//         "id": "7028",
//         "englishName": "Infinite Convergence",
//         "name": "インフィニット コンバージェンス",
//         "isMythic": true,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7028.png",
//         "sidePopupAsset": {
//             "right": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/infinite-convergence_side_right.png",
//             "left": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/infinite-convergence_side_left.png",
//         },
//         // "infoboxAsset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/infinite-convergence_infobox.png"
//     },
//     "8001": {
//         "id": "8001",
//         "englishName": "Anathema's Chains",
//         "name": "アナセマ チェイン",
//         "isMythic": false,
//         "asset": "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/8001.png",
//     },
// }

export const itemsList = {
  1001: {
    id: "1001",
    englishName: "Boots",
    name: "ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1001.png",
  },
  1004: {
    id: "1004",
    englishName: "Faerie Charm",
    name: "フェアリー チャーム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1004.png",
  },
  1006: {
    id: "1006",
    englishName: "Rejuvenation Bead",
    name: "再生の珠",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1006.png",
  },
  1011: {
    id: "1011",
    englishName: "Giant's Belt",
    name: "ジャイアント ベルト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1011.png",
  },
  1018: {
    id: "1018",
    englishName: "Cloak of Agility",
    name: "アジリティ クローク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1018.png",
  },
  1026: {
    id: "1026",
    englishName: "Blasting Wand",
    name: "ブラスティング ワンド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1026.png",
  },
  1027: {
    id: "1027",
    englishName: "Sapphire Crystal",
    name: "サファイア クリスタル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1027.png",
  },
  1028: {
    id: "1028",
    englishName: "Ruby Crystal",
    name: "ルビー クリスタル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1028.png",
  },
  1029: {
    id: "1029",
    englishName: "Cloth Armor",
    name: "クロース アーマー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1029.png",
  },
  1031: {
    id: "1031",
    englishName: "Chain Vest",
    name: "チェイン ベスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1031.png",
  },
  1033: {
    id: "1033",
    englishName: "Null-Magic Mantle",
    name: "ヌルマジック マント",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1033.png",
  },
  1035: {
    id: "1035",
    englishName: "Emberknife",
    name: "残炎のナイフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1035.png",
  },
  1036: {
    id: "1036",
    englishName: "Long Sword",
    name: "ロング ソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1036.png",
  },
  1037: {
    id: "1037",
    englishName: "Pickaxe",
    name: "ピッケル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1037.png",
  },
  1038: {
    id: "1038",
    englishName: "B. F. Sword",
    name: "B. F. ソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1038.png",
  },
  1039: {
    id: "1039",
    englishName: "Hailblade",
    name: "氷雨の刃",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1039.png",
  },
  1040: {
    id: "1040",
    englishName: "Obsidian Edge",
    name: "オブシディアン エッジ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1040.png",
  },
  1042: {
    id: "1042",
    englishName: "Dagger",
    name: "ダガー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1042.png",
  },
  1043: {
    id: "1043",
    englishName: "Recurve Bow",
    name: "リカーブ ボウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1043.png",
  },
  1052: {
    id: "1052",
    englishName: "Amplifying Tome",
    name: "増魔の書",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1052.png",
  },
  1053: {
    id: "1053",
    englishName: "Vampiric Scepter",
    name: "ヴァンパイア セプター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1053.png",
  },
  1054: {
    id: "1054",
    englishName: "Doran's Shield",
    name: "ドラン シールド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1054.png",
  },
  1055: {
    id: "1055",
    englishName: "Doran's Blade",
    name: "ドラン ブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1055.png",
  },
  1056: {
    id: "1056",
    englishName: "Doran's Ring",
    name: "ドラン リング",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1056.png",
  },
  1057: {
    id: "1057",
    englishName: "Negatron Cloak",
    name: "ネガトロン クローク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1057.png",
  },
  1058: {
    id: "1058",
    englishName: "Needlessly Large Rod",
    name: "ムダニ デカイ ロッド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1058.png",
  },
  1082: {
    id: "1082",
    englishName: "Dark Seal",
    name: "ダーク シール",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1082.png",
  },
  1083: {
    id: "1083",
    englishName: "Cull",
    name: "カル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1083.png",
  },
  1101: {
    id: "1101",
    englishName: "Scorchclaw Pup",
    name: "スコーチクロウの幼体",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1101.png",
  },
  1102: {
    id: "1102",
    englishName: "Gustwalker Hatchling",
    name: "ガストウォーカーの幼体",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1102.png",
  },
  1103: {
    id: "1103",
    englishName: "Mosstomper Seedling",
    name: "モスストンパーの幼体",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1103.png",
  },
  1104: {
    id: "1104",
    englishName: "Eye of the Herald",
    name: "ヘラルドの瞳",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1104.png",
  },
  1105: {
    id: "1105",
    englishName: "Mosstomper Seedling",
    name: "モスストンパーの幼体",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1105.png",
  },
  1106: {
    id: "1106",
    englishName: "Gustwalker Hatchling",
    name: "ガストウォーカーの幼体",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1106.png",
  },
  1107: {
    id: "1107",
    englishName: "Scorchclaw Pup",
    name: "スコーチクロウの幼体",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1107.png",
  },
  126697: {
    id: "126697",
    englishName: "Hubris<br>",
    name: "ヒュブリス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/126697.png",
  },
  1500: {
    id: "1500",
    englishName: "Penetrating Bullets",
    name: "貫通弾",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1500.png",
  },
  1501: {
    id: "1501",
    englishName: "Fortification",
    name: "防衛拡張",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1501.png",
  },
  1502: {
    id: "1502",
    englishName: "Reinforced Armor",
    name: "強化装甲",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1502.png",
  },
  1503: {
    id: "1503",
    englishName: "Warden's Eye",
    name: "番人の瞳",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1503.png",
  },
  1504: {
    id: "1504",
    englishName: "Vanguard",
    name: "ヴァンガード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1504.png",
  },
  1506: {
    id: "1506",
    englishName: "Reinforced Armor",
    name: "強化装甲",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1506.png",
  },
  1507: {
    id: "1507",
    englishName: "Overcharged",
    name: "オーバーチャージ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1507.png",
  },
  1508: {
    id: "1508",
    englishName: "Anti-tower Socks",
    name: "アンチタワー ソックス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1508.png",
  },
  1509: {
    id: "1509",
    englishName: "Gusto",
    name: "ガスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1509.png",
  },
  1510: {
    id: "1510",
    englishName: "Phreakish Gusto",
    name: "フリーキッシュ ガスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1510.png",
  },
  1511: {
    id: "1511",
    englishName: "Super Mech Armor",
    name: "スーパー メカ アーマー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1511.png",
  },
  1512: {
    id: "1512",
    englishName: "Super Mech Power Field",
    name: "スーパー メカ パワー フィールド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1512.png",
  },
  1515: {
    id: "1515",
    englishName: "Turret Plating",
    name: "タワー プレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1515.png",
  },
  1516: {
    id: "1516",
    englishName: "Structure Bounty",
    name: "建造物の賞金",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1516.png",
  },
  1517: {
    id: "1517",
    englishName: "Structure Bounty",
    name: "建造物の賞金",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1517.png",
  },
  1518: {
    id: "1518",
    englishName: "Structure Bounty",
    name: "建造物の賞金",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1518.png",
  },
  1519: {
    id: "1519",
    englishName: "Structure Bounty",
    name: "建造物の賞金",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1519.png",
  },
  1520: {
    id: "1520",
    englishName: "OvererchargedHA",
    name: "オーバーチャージHA",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1520.png",
  },
  1521: {
    id: "1521",
    englishName: "Fortification",
    name: "防衛拡張",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1521.png",
  },
  1522: {
    id: "1522",
    englishName: "Tower Power-Up",
    name: "タワー パワーアップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/1522.png",
  },
  2003: {
    id: "2003",
    englishName: "Health Potion",
    name: "体力ポーション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2003.png",
  },
  2010: {
    id: "2010",
    englishName: "Total Biscuit of Everlasting Will",
    name: "英気満点ビスケット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2010.png",
  },
  2015: {
    id: "2015",
    englishName: "Kircheis Shard",
    name: "キルヒアイス シャード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2015.png",
  },
  2019: {
    id: "2019",
    englishName: "Steel Sigil",
    name: "スチール シジル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2019.png",
  },
  2020: {
    id: "2020",
    englishName: "The Brutalizer",
    name: "ブルータライザー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2020.png",
  },
  2021: {
    id: "2021",
    englishName: "Tunneler",
    name: "トンネル掘り",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2021.png",
  },
  2022: {
    id: "2022",
    englishName: "Glowing Mote",
    name: "輝きのモート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2022.png",
  },
  2031: {
    id: "2031",
    englishName: "Refillable Potion",
    name: "詰め替えポーション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2031.png",
  },
  2033: {
    id: "2033",
    englishName: "Corrupting Potion",
    name: "コラプト ポーション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2033.png",
  },
  2049: {
    id: "2049",
    englishName: "Guardian's Amulet",
    name: "ガーディアン アミュレット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2049.png",
  },
  2050: {
    id: "2050",
    englishName: "Guardian's Shroud",
    name: "ガーディアン シュラウド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2050.png",
  },
  2051: {
    id: "2051",
    englishName: "Guardian's Horn",
    name: "ガーディアン ホーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2051.png",
  },
  2052: {
    id: "2052",
    englishName: "Poro-Snax",
    name: "ポロのおやつ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2052.png",
  },
  2055: {
    id: "2055",
    englishName: "Control Ward",
    name: "コントロール ワード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2055.png",
  },
  2056: {
    id: "2056",
    englishName: "Stealth Ward",
    name: "ステルス ワード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2056.png",
  },
  2065: {
    id: "2065",
    englishName: "Shurelya's Battlesong",
    name: "シュレリアの戦歌",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2065.png",
  },
  2138: {
    id: "2138",
    englishName: "Elixir of Iron",
    name: "アイアン エリクサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2138.png",
  },
  2139: {
    id: "2139",
    englishName: "Elixir of Sorcery",
    name: "ソーサリー エリクサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2139.png",
  },
  2140: {
    id: "2140",
    englishName: "Elixir of Wrath",
    name: "ラース エリクサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2140.png",
  },
  2141: {
    id: "2141",
    englishName: "Cappa Juice",
    name: "帽子ジュース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2141.png",
  },
  2142: {
    id: "2142",
    englishName: "Juice of Power",
    name: "知力のジュース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2142.png",
  },
  2143: {
    id: "2143",
    englishName: "Juice of Vitality",
    name: "生命力のジュース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2143.png",
  },
  2144: {
    id: "2144",
    englishName: "Juice of Haste",
    name: "ヘイストジュース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2144.png",
  },
  2145: {
    id: "2145",
    englishName: "Lucky Dice",
    name: "幸運のダイス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2145.png",
  },
  2150: {
    id: "2150",
    englishName: "Elixir of Skill",
    name: "スキル エリクサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2150.png",
  },
  2151: {
    id: "2151",
    englishName: "Elixir of Avarice",
    name: "強欲のエリクサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2151.png",
  },
  2152: {
    id: "2152",
    englishName: "Elixir of Force",
    name: "力のエリクサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2152.png",
  },
  220000: {
    id: "220000",
    englishName: "Stat Bonus",
    name: "ステータスボーナス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220000.png",
  },
  220001: {
    id: "220001",
    englishName: "Legendary Fighter Item",
    name: "レジェンダリーファイターアイテム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220001.png",
  },
  220002: {
    id: "220002",
    englishName: "Legendary Marksman Item",
    name: "レジェンダリーマークスマンアイテム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220002.png",
  },
  220003: {
    id: "220003",
    englishName: "Legendary Assassin Item",
    name: "レジェンダリーアサシンアイテム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220003.png",
  },
  220004: {
    id: "220004",
    englishName: "Legendary Mage Item",
    name: "レジェンダリーメイジアイテム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220004.png",
  },
  220005: {
    id: "220005",
    englishName: "Legendary Tank Item",
    name: "レジェンダリータンクアイテム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220005.png",
  },
  220006: {
    id: "220006",
    englishName: "Legendary Support Item",
    name: "レジェンダリーサポートアイテム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220006.png",
  },
  220007: {
    id: "220007",
    englishName: "Prismatic Item",
    name: "プリズムアイテム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/220007.png",
  },
  221011: {
    id: "221011",
    englishName: "Giant's Belt",
    name: "ジャイアント ベルト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221011.png",
  },
  221026: {
    id: "221026",
    englishName: "Blasting Wand",
    name: "ブラスティング ワンド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221026.png",
  },
  221031: {
    id: "221031",
    englishName: "Chain Vest",
    name: "チェイン ベスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221031.png",
  },
  221038: {
    id: "221038",
    englishName: "B. F. Sword",
    name: "B. F. ソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221038.png",
  },
  221043: {
    id: "221043",
    englishName: "Recurve Bow",
    name: "リカーブ ボウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221043.png",
  },
  221053: {
    id: "221053",
    englishName: "Vampiric Scepter",
    name: "ヴァンパイア セプター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221053.png",
  },
  221057: {
    id: "221057",
    englishName: "Negatron Cloak",
    name: "ネガトロン クローク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221057.png",
  },
  221058: {
    id: "221058",
    englishName: "Needlessly Large Rod",
    name: "ムダニ デカイ ロッド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/221058.png",
  },
  222022: {
    id: "222022",
    englishName: "Glowing Mote",
    name: "輝きのモート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/222022.png",
  },
  222051: {
    id: "222051",
    englishName: "Guardian's Horn",
    name: "ガーディアン ホーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/222051.png",
  },
  222065: {
    id: "222065",
    englishName: "Shurelya's Battlesong",
    name: "シュレリアの戦歌",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/222065.png",
  },
  222141: {
    id: "222141",
    englishName: "Cappa Juice",
    name: "帽子ジュース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/222141.png",
  },
  222502: {
    id: "222502",
    englishName: "Unending Despair",
    name: "終わりなき絶望",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/222502.png",
  },
  222503: {
    id: "222503",
    englishName: "Blackfire Torch",
    name: "黒炎のトーチ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/222503.png",
  },
  222504: {
    id: "222504",
    englishName: "Kaenic Rookern",
    name: "ケイニック ルーケルン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/222504.png",
  },
  223001: {
    id: "223001",
    englishName: "Evenshroud",
    name: "イーブンシュラウド",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223001.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/evenshroud_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/evenshroud_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/evenshroud_infobox.png",
  },
  223002: {
    id: "223002",
    englishName: "Trailblazer",
    name: "先人の道標",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223002.png",
  },
  223003: {
    id: "223003",
    englishName: "Archangel's Staff",
    name: "アークエンジェル スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223003.png",
  },
  223004: {
    id: "223004",
    englishName: "Manamune",
    name: "マナムネ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223004.png",
  },
  223005: {
    id: "223005",
    englishName: "Ghostcrawlers",
    name: "ゴーストクローラー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223005.png",
  },
  223006: {
    id: "223006",
    englishName: "Berserker's Greaves",
    name: "バーサーカー ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223006.png",
  },
  223009: {
    id: "223009",
    englishName: "Boots of Swiftness",
    name: "スイフトネス ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223009.png",
  },
  223011: {
    id: "223011",
    englishName: "Chemtech Putrifier",
    name: "ケミテック ピュートリファイアー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223011.png",
  },
  223020: {
    id: "223020",
    englishName: "Sorcerer's Shoes",
    name: "ソーサラー シューズ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223020.png",
  },
  223026: {
    id: "223026",
    englishName: "Guardian Angel",
    name: "ガーディアン エンジェル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223026.png",
  },
  223031: {
    id: "223031",
    englishName: "Infinity Edge",
    name: "インフィニティ エッジ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223031.png",
  },
  223032: {
    id: "223032",
    englishName: "Yun Tal Wildarrows",
    name: "ユン・タル ワイルドアロー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223032.png",
  },
  223033: {
    id: "223033",
    englishName: "Mortal Reminder",
    name: "モータル リマインダー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223033.png",
  },
  223036: {
    id: "223036",
    englishName: "Lord Dominik's Regards",
    name: "ドミニク リガード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223036.png",
  },
  223039: {
    id: "223039",
    englishName: "Atma's Reckoning",
    name: "アトマの報い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223039.png",
  },
  223040: {
    id: "223040",
    englishName: "Seraph's Embrace",
    name: "セラフ エンブレイス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223040.png",
  },
  223042: {
    id: "223042",
    englishName: "Muramana",
    name: "ムラマナ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223042.png",
  },
  223046: {
    id: "223046",
    englishName: "Phantom Dancer",
    name: "ファントム ダンサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223046.png",
  },
  223047: {
    id: "223047",
    englishName: "Plated Steelcaps",
    name: "プレート スチールキャップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223047.png",
  },
  223050: {
    id: "223050",
    englishName: "Zeke's Convergence",
    name: "ジーク コンバージェンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223050.png",
  },
  223053: {
    id: "223053",
    englishName: "Sterak's Gage",
    name: "ステラックの篭手",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223053.png",
  },
  223057: {
    id: "223057",
    englishName: "Sheen",
    name: "シーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223057.png",
  },
  223065: {
    id: "223065",
    englishName: "Spirit Visage",
    name: "スピリット ビサージュ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223065.png",
  },
  223067: {
    id: "223067",
    englishName: "Kindlegem",
    name: "キンドルジェム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223067.png",
  },
  223068: {
    id: "223068",
    englishName: "Sunfire Aegis",
    name: "サンファイア イージス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223068.png",
  },
  223071: {
    id: "223071",
    englishName: "Black Cleaver",
    name: "ブラック クリーバー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223071.png",
  },
  223072: {
    id: "223072",
    englishName: "Bloodthirster",
    name: "ブラッドサースター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223072.png",
  },
  223073: {
    id: "223073",
    englishName: "Experimental Hexplate",
    name: "実験的ヘクスプレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223073.png",
  },
  223074: {
    id: "223074",
    englishName: "Ravenous Hydra",
    name: "ラヴァナス ハイドラ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223074.png",
  },
  223075: {
    id: "223075",
    englishName: "Thornmail",
    name: "ソーンメイル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223075.png",
  },
  223078: {
    id: "223078",
    englishName: "Trinity Force",
    name: "トリニティ フォース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223078.png",
  },
  223084: {
    id: "223084",
    englishName: "Heartsteel",
    name: "心の鋼",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223084.png",
  },
  223085: {
    id: "223085",
    englishName: "Runaan's Hurricane",
    name: "ルナーン ハリケーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223085.png",
  },
  223087: {
    id: "223087",
    englishName: "Statikk Shiv",
    name: "スタティック シヴ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223087.png",
  },
  223089: {
    id: "223089",
    englishName: "Rabadon's Deathcap",
    name: "ラバドン デスキャップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223089.png",
  },
  223091: {
    id: "223091",
    englishName: "Wit's End",
    name: "ウィッツ エンド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223091.png",
  },
  223094: {
    id: "223094",
    englishName: "Rapid Firecannon",
    name: "ラピッド ファイアキャノン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223094.png",
  },
  223095: {
    id: "223095",
    englishName: "Stormrazor",
    name: "ストームレイザー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223095.png",
  },
  223100: {
    id: "223100",
    englishName: "Lich Bane",
    name: "リッチ ベイン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223100.png",
  },
  223102: {
    id: "223102",
    englishName: "Banshee's Veil",
    name: "バンシー ヴェール",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223102.png",
  },
  223105: {
    id: "223105",
    englishName: "Aegis of the Legion",
    name: "イージスの盾",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223105.png",
  },
  223107: {
    id: "223107",
    englishName: "Redemption",
    name: "リデンプション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223107.png",
  },
  223109: {
    id: "223109",
    englishName: "Knight's Vow",
    name: "騎士の誓い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223109.png",
  },
  223110: {
    id: "223110",
    englishName: "Frozen Heart",
    name: "フローズン ハート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223110.png",
  },
  223111: {
    id: "223111",
    englishName: "Mercury's Treads",
    name: "マーキュリー ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223111.png",
  },
  223112: {
    id: "223112",
    englishName: "Guardian's Orb",
    name: "ガーディアン オーブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223112.png",
  },
  223115: {
    id: "223115",
    englishName: "Nashor's Tooth",
    name: "ナッシャー トゥース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223115.png",
  },
  223116: {
    id: "223116",
    englishName: "Rylai's Crystal Scepter",
    name: "リーライ クリスタル セプター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223116.png",
  },
  223118: {
    id: "223118",
    englishName: "Malignance",
    name: "マリグナンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223118.png",
  },
  223119: {
    id: "223119",
    englishName: "Winter's Approach",
    name: "冬の訪れ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223119.png",
  },
  223121: {
    id: "223121",
    englishName: "Fimbulwinter",
    name: "フィンブルウィンター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223121.png",
  },
  223124: {
    id: "223124",
    englishName: "Guinsoo's Rageblade",
    name: "グインソー レイジブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223124.png",
  },
  223135: {
    id: "223135",
    englishName: "Void Staff",
    name: "ヴォイド スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223135.png",
  },
  223137: {
    id: "223137",
    englishName: "Cryptbloom",
    name: "クリプトブルーム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223137.png",
  },
  223139: {
    id: "223139",
    englishName: "Mercurial Scimitar",
    name: "マーキュリアル シミター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223139.png",
  },
  223142: {
    id: "223142",
    englishName: "Youmuu's Ghostblade",
    name: "妖夢の霊剣",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223142.png",
  },
  223143: {
    id: "223143",
    englishName: "Randuin's Omen",
    name: "ランデュイン オーメン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223143.png",
  },
  223146: {
    id: "223146",
    englishName: "Hextech Gunblade",
    name: "ヘクステック ガンブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223146.png",
  },
  223152: {
    id: "223152",
    englishName: "Hextech Rocketbelt",
    name: "ヘクステック ロケットベルト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223152.png",
  },
  223153: {
    id: "223153",
    englishName: "Blade of The Ruined King",
    name: "ルインドキング ブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223153.png",
  },
  223156: {
    id: "223156",
    englishName: "Maw of Malmortius",
    name: "マルモティウスの胃袋",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223156.png",
  },
  223157: {
    id: "223157",
    englishName: "Zhonya's Hourglass",
    name: "ゾーニャの砂時計",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223157.png",
  },
  223158: {
    id: "223158",
    englishName: "Ionian Boots of Lucidity",
    name: "アイオニア ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223158.png",
  },
  223161: {
    id: "223161",
    englishName: "Spear of Shojin",
    name: "ショウジンの矛",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223161.png",
  },
  223165: {
    id: "223165",
    englishName: "Morellonomicon",
    name: "モレロノミコン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223165.png",
  },
  223172: {
    id: "223172",
    englishName: "Zephyr",
    name: "ゼファー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223172.png",
  },
  223177: {
    id: "223177",
    englishName: "Guardian's Blade",
    name: "ガーディアン ブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223177.png",
  },
  223181: {
    id: "223181",
    englishName: "Hullbreaker",
    name: "ハルブレイカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223181.png",
  },
  223184: {
    id: "223184",
    englishName: "Guardian's Hammer",
    name: "ガーディアン ハンマー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223184.png",
  },
  223185: {
    id: "223185",
    englishName: "Guardian's Dirk",
    name: "ガーディアン ダーク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223185.png",
  },
  223190: {
    id: "223190",
    englishName: "Locket of the Iron Solari",
    name: "ソラリのロケット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223190.png",
  },
  223193: {
    id: "223193",
    englishName: "Gargoyle Stoneplate",
    name: "ガーゴイル ストーンプレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223193.png",
  },
  223222: {
    id: "223222",
    englishName: "Mikael's Blessing",
    name: "ミカエルの祝福",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223222.png",
  },
  223302: {
    id: "223302",
    englishName: "Terminus",
    name: "テルミヌス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223302.png",
  },
  223504: {
    id: "223504",
    englishName: "Ardent Censer",
    name: "アーデント センサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223504.png",
  },
  223508: {
    id: "223508",
    englishName: "Essence Reaver",
    name: "エッセンス リーバー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223508.png",
  },
  223742: {
    id: "223742",
    englishName: "Dead Man's Plate",
    name: "デッド マン プレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223742.png",
  },
  223748: {
    id: "223748",
    englishName: "Titanic Hydra",
    name: "タイタン ハイドラ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223748.png",
  },
  223814: {
    id: "223814",
    englishName: "Edge of Night",
    name: "ナイト エッジ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/223814.png",
  },
  224004: {
    id: "224004",
    englishName: "Spectral Cutlass",
    name: "スペクトラル カトラス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224004.png",
  },
  224005: {
    id: "224005",
    englishName: "Imperial Mandate",
    name: "帝国の指令",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224005.png",
  },
  224401: {
    id: "224401",
    englishName: "Force of Nature",
    name: "自然の力",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224401.png",
  },
  224403: {
    id: "224403",
    englishName: "The Golden Spatula",
    name: "ザ・金のへら",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224403.png",
  },
  224628: {
    id: "224628",
    englishName: "Horizon Focus",
    name: "ホライゾン フォーカス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224628.png",
  },
  224629: {
    id: "224629",
    englishName: "Cosmic Drive",
    name: "コズミック ドライブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224629.png",
  },
  224633: {
    id: "224633",
    englishName: "Riftmaker",
    name: "リフトメーカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224633.png",
  },
  224636: {
    id: "224636",
    englishName: "Night Harvester",
    name: "ナイト ハーベスター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224636.png",
  },
  224637: {
    id: "224637",
    englishName: "Demonic Embrace",
    name: "悪魔の抱擁",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224637.png",
  },
  224644: {
    id: "224644",
    englishName: "Crown of the Shattered Queen",
    name: "シャッタードクイーン クラウン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224644.png",
  },
  224645: {
    id: "224645",
    englishName: "Shadowflame",
    name: "シャドウフレイム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224645.png",
  },
  224646: {
    id: "224646",
    englishName: "Stormsurge",
    name: "ストームサージ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/224646.png",
  },
  226035: {
    id: "226035",
    englishName: "Silvermere Dawn",
    name: "シルバーミアの夜明け",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226035.png",
  },
  226333: {
    id: "226333",
    englishName: "Death's Dance",
    name: "デス ダンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226333.png",
  },
  226609: {
    id: "226609",
    englishName: "Chempunk Chainsword",
    name: "ケミパンク チェーンソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226609.png",
  },
  226610: {
    id: "226610",
    englishName: "Sundered Sky",
    name: "サンダード スカイ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226610.png",
  },
  226616: {
    id: "226616",
    englishName: "Staff of Flowing Water",
    name: "フロー ウォーター スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226616.png",
  },
  226617: {
    id: "226617",
    englishName: "Moonstone Renewer",
    name: "ムーンストーンの再生",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226617.png",
  },
  226620: {
    id: "226620",
    englishName: "Echoes of Helia",
    name: "ヘリアの残響",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226620.png",
  },
  226621: {
    id: "226621",
    englishName: "Dawncore",
    name: "ドーンコア",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226621.png",
  },
  226630: {
    id: "226630",
    englishName: "Goredrinker",
    name: "ゴアドリンカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226630.png",
  },
  226631: {
    id: "226631",
    englishName: "Stridebreaker",
    name: "ストライドブレイカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226631.png",
  },
  226632: {
    id: "226632",
    englishName: "Divine Sunderer",
    name: "ディヴァイン サンダラー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226632.png",
  },
  226653: {
    id: "226653",
    englishName: "Liandry's Anguish",
    name: "ライアンドリーの苦悶",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226653.png",
  },
  226655: {
    id: "226655",
    englishName: "Luden's Companion",
    name: "ルーデン コンパニオン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226655.png",
  },
  226656: {
    id: "226656",
    englishName: "Everfrost",
    name: "エバーフロスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226656.png",
  },
  226657: {
    id: "226657",
    englishName: "Rod of Ages",
    name: "ロッド オブ エイジス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226657.png",
  },
  226662: {
    id: "226662",
    englishName: "Iceborn Gauntlet",
    name: "アイスボーン ガントレット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226662.png",
  },
  226664: {
    id: "226664",
    englishName: "Hollow Radiance<br>",
    name: "ホロウ レディアンス<br>",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226664.png",
  },
  226665: {
    id: "226665",
    englishName: "Jak'Sho, The Protean",
    name: "変幻自在のジャック＝ショー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226665.png",
  },
  226667: {
    id: "226667",
    englishName: "Radiant Virtue",
    name: "レディアント ヴァーチュ",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226667.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/radiant-virtue_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/radiant-virtue_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/radiant-virtue_infobox.png",
  },
  226671: {
    id: "226671",
    englishName: "Galeforce",
    name: "ゲイルフォース",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226671.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/galeforce_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/galeforce_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/galeforce_infobox.png",
  },
  226672: {
    id: "226672",
    englishName: "Kraken Slayer",
    name: "クラーケン スレイヤー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226672.png",
  },
  226673: {
    id: "226673",
    englishName: "Immortal Shieldbow",
    name: "イモータル シールドボウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226673.png",
  },
  226675: {
    id: "226675",
    englishName: "Navori Quickblades",
    name: "ナヴォリ クイックブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226675.png",
  },
  226676: {
    id: "226676",
    englishName: "The Collector",
    name: "コレクター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226676.png",
  },
  226691: {
    id: "226691",
    englishName: "Duskblade of Draktharr",
    name: "ドラクサー ダスクブレード",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226691.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/duskblade-of-draktharr_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/duskblade-of-draktharr_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/duskblade-of-draktharr_infobox.png",
  },
  226692: {
    id: "226692",
    englishName: "Eclipse",
    name: "赤月の刃",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226692.png",
  },
  226693: {
    id: "226693",
    englishName: "Prowler's Claw",
    name: "プローラー クロウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226693.png",
  },
  226694: {
    id: "226694",
    englishName: "Serylda's Grudge",
    name: "セリルダの怨恨",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226694.png",
  },
  226695: {
    id: "226695",
    englishName: "Serpent's Fang",
    name: "毒蛇の牙",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226695.png",
  },
  226696: {
    id: "226696",
    englishName: "Axiom Arc",
    name: "アクシオム アーク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226696.png",
  },
  226697: {
    id: "226697",
    englishName: "Hubris",
    name: "ヒュブリス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226697.png",
  },
  226698: {
    id: "226698",
    englishName: "Profane Hydra",
    name: "プロフェイン ハイドラ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226698.png",
  },
  226699: {
    id: "226699",
    englishName: "Voltaic Cyclosword",
    name: "ボルテイク サイクロソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226699.png",
  },
  226701: {
    id: "226701",
    englishName: "Opportunity",
    name: "オポチュニティー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/226701.png",
  },
  228001: {
    id: "228001",
    englishName: "Anathema's Chains",
    name: "アナセマ チェイン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228001.png",
  },
  228002: {
    id: "228002",
    englishName: "Wooglet's Witchcap",
    name: "ウーグレット ウィッチキャップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228002.png",
  },
  228003: {
    id: "228003",
    englishName: "Deathblade",
    name: "デスブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228003.png",
  },
  228004: {
    id: "228004",
    englishName: "Adaptive Helm",
    name: "アダプティブ ヘルム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228004.png",
  },
  228005: {
    id: "228005",
    englishName: "Obsidian Cleaver",
    name: "オブシディアン クリーバー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228005.png",
  },
  228006: {
    id: "228006",
    englishName: "Sanguine Blade",
    name: "サングイン ブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228006.png",
  },
  228008: {
    id: "228008",
    englishName: "Runeglaive",
    name: "ルーングレイブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228008.png",
  },
  228020: {
    id: "228020",
    englishName: "Abyssal Mask",
    name: "アビサル マスク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/228020.png",
  },
  2403: {
    id: "2403",
    englishName: "Minion Dematerializer",
    name: "ミニオン吸収装置",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2403.png",
  },
  2420: {
    id: "2420",
    englishName: "Seeker's Armguard",
    name: "シーカー アームガード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2420.png",
  },
  2421: {
    id: "2421",
    englishName: "Shattered Armguard",
    name: "砕けたアームガード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2421.png",
  },
  2422: {
    id: "2422",
    englishName: "Slightly Magical Footwear",
    name: "ちょっとだけ魔法がかった靴",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2422.png",
  },
  2501: {
    id: "2501",
    englishName: "Overlord's Bloodmail",
    name: "覇王のブラッドメイル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2501.png",
  },
  2502: {
    id: "2502",
    englishName: "Unending Despair",
    name: "終わりなき絶望",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2502.png",
  },
  2503: {
    id: "2503",
    englishName: "Blackfire Torch",
    name: "黒炎のトーチ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2503.png",
  },
  2504: {
    id: "2504",
    englishName: "Kaenic Rookern",
    name: "ケイニック ルーケルン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2504.png",
  },
  2508: {
    id: "2508",
    englishName: "Fated Ashes",
    name: "運命の灰",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/2508.png",
  },
  3001: {
    id: "3001",
    englishName: "Evenshroud",
    name: "イーブンシュラウド",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3001.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/evenshroud_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/evenshroud_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/evenshroud_infobox.png",
  },
  3002: {
    id: "3002",
    englishName: "Trailblazer",
    name: "先人の道標",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3002.png",
  },
  3003: {
    id: "3003",
    englishName: "Archangel's Staff",
    name: "アークエンジェル スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3003.png",
  },
  3004: {
    id: "3004",
    englishName: "Manamune",
    name: "マナムネ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3004.png",
  },
  3005: {
    id: "3005",
    englishName: "Ghostcrawlers",
    name: "ゴーストクローラー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3005.png",
  },
  3006: {
    id: "3006",
    englishName: "Berserker's Greaves",
    name: "バーサーカー ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3006.png",
  },
  3009: {
    id: "3009",
    englishName: "Boots of Swiftness",
    name: "スイフトネス ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3009.png",
  },
  3010: {
    id: "3010",
    englishName: "Symbiotic Soles",
    name: "共生靴底",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3010.png",
  },
  3011: {
    id: "3011",
    englishName: "Chemtech Putrifier",
    name: "ケミテック ピュートリファイアー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3011.png",
  },
  3012: {
    id: "3012",
    englishName: "Chalice of Blessing",
    name: "祝福の聖杯",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3012.png",
  },
  3013: {
    id: "3013",
    englishName: "Synchronized Souls",
    name: "シンクロナイズド ソウル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3013.png",
  },
  3020: {
    id: "3020",
    englishName: "Sorcerer's Shoes",
    name: "ソーサラー シューズ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3020.png",
  },
  3023: {
    id: "3023",
    englishName: "Lifewell Pendant",
    name: "生命泉のペンダント",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3023.png",
  },
  3024: {
    id: "3024",
    englishName: "Glacial Buckler",
    name: "グレイシャル バックラー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3024.png",
  },
  3026: {
    id: "3026",
    englishName: "Guardian Angel",
    name: "ガーディアン エンジェル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3026.png",
  },
  3031: {
    id: "3031",
    englishName: "Infinity Edge",
    name: "インフィニティ エッジ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3031.png",
  },
  3032: {
    id: "3032",
    englishName: "Yun Tal Wildarrows",
    name: "ユン・タル ワイルドアロー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3032.png",
  },
  3033: {
    id: "3033",
    englishName: "Mortal Reminder",
    name: "モータル リマインダー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3033.png",
  },
  3035: {
    id: "3035",
    englishName: "Last Whisper",
    name: "ラスト ウィスパー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3035.png",
  },
  3036: {
    id: "3036",
    englishName: "Lord Dominik's Regards",
    name: "ドミニク リガード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3036.png",
  },
  3039: {
    id: "3039",
    englishName: "Atma's Reckoning",
    name: "アトマの報い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3039.png",
  },
  3040: {
    id: "3040",
    englishName: "Seraph's Embrace",
    name: "セラフ エンブレイス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3040.png",
  },
  3041: {
    id: "3041",
    englishName: "Mejai's Soulstealer",
    name: "メジャイ ソウルスティーラー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3041.png",
  },
  3042: {
    id: "3042",
    englishName: "Muramana",
    name: "ムラマナ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3042.png",
  },
  3044: {
    id: "3044",
    englishName: "Phage",
    name: "ファージ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3044.png",
  },
  3046: {
    id: "3046",
    englishName: "Phantom Dancer",
    name: "ファントム ダンサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3046.png",
  },
  3047: {
    id: "3047",
    englishName: "Plated Steelcaps",
    name: "プレート スチールキャップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3047.png",
  },
  3050: {
    id: "3050",
    englishName: "Zeke's Convergence",
    name: "ジーク コンバージェンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3050.png",
  },
  3051: {
    id: "3051",
    englishName: "Hearthbound Axe",
    name: "ハースバウンド アックス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3051.png",
  },
  3053: {
    id: "3053",
    englishName: "Sterak's Gage",
    name: "ステラックの篭手",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3053.png",
  },
  3057: {
    id: "3057",
    englishName: "Sheen",
    name: "シーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3057.png",
  },
  3065: {
    id: "3065",
    englishName: "Spirit Visage",
    name: "スピリット ビサージュ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3065.png",
  },
  3066: {
    id: "3066",
    englishName: "Winged Moonplate",
    name: "翼のムーンプレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3066.png",
  },
  3067: {
    id: "3067",
    englishName: "Kindlegem",
    name: "キンドルジェム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3067.png",
  },
  3068: {
    id: "3068",
    englishName: "Sunfire Aegis",
    name: "サンファイア イージス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3068.png",
  },
  3070: {
    id: "3070",
    englishName: "Tear of the Goddess",
    name: "女神の涙",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3070.png",
  },
  3071: {
    id: "3071",
    englishName: "Black Cleaver",
    name: "ブラック クリーバー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3071.png",
  },
  3072: {
    id: "3072",
    englishName: "Bloodthirster",
    name: "ブラッドサースター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3072.png",
  },
  3073: {
    id: "3073",
    englishName: "Experimental Hexplate",
    name: "実験的ヘクスプレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3073.png",
  },
  3074: {
    id: "3074",
    englishName: "Ravenous Hydra",
    name: "ラヴァナス ハイドラ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3074.png",
  },
  3075: {
    id: "3075",
    englishName: "Thornmail",
    name: "ソーンメイル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3075.png",
  },
  3076: {
    id: "3076",
    englishName: "Bramble Vest",
    name: "ブランブル ベスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3076.png",
  },
  3077: {
    id: "3077",
    englishName: "Tiamat",
    name: "ティアマット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3077.png",
  },
  3078: {
    id: "3078",
    englishName: "Trinity Force",
    name: "トリニティ フォース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3078.png",
  },
  3082: {
    id: "3082",
    englishName: "Warden's Mail",
    name: "ワーデン メイル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3082.png",
  },
  3083: {
    id: "3083",
    englishName: "Warmog's Armor",
    name: "ワーモグ アーマー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3083.png",
  },
  3084: {
    id: "3084",
    englishName: "Heartsteel",
    name: "心の鋼",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3084.png",
  },
  3085: {
    id: "3085",
    englishName: "Runaan's Hurricane",
    name: "ルナーン ハリケーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3085.png",
  },
  3086: {
    id: "3086",
    englishName: "Zeal",
    name: "ジール",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3086.png",
  },
  3087: {
    id: "3087",
    englishName: "Statikk Shiv",
    name: "スタティック シヴ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3087.png",
  },
  3089: {
    id: "3089",
    englishName: "Rabadon's Deathcap",
    name: "ラバドン デスキャップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3089.png",
  },
  3091: {
    id: "3091",
    englishName: "Wit's End",
    name: "ウィッツ エンド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3091.png",
  },
  3094: {
    id: "3094",
    englishName: "Rapid Firecannon",
    name: "ラピッド ファイアキャノン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3094.png",
  },
  3095: {
    id: "3095",
    englishName: "Stormrazor",
    name: "ストームレイザー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3095.png",
  },
  3100: {
    id: "3100",
    englishName: "Lich Bane",
    name: "リッチ ベイン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3100.png",
  },
  3102: {
    id: "3102",
    englishName: "Banshee's Veil",
    name: "バンシー ヴェール",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3102.png",
  },
  3105: {
    id: "3105",
    englishName: "Aegis of the Legion",
    name: "イージスの盾",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3105.png",
  },
  3107: {
    id: "3107",
    englishName: "Redemption",
    name: "リデンプション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3107.png",
  },
  3108: {
    id: "3108",
    englishName: "Fiendish Codex",
    name: "フィンディッシュの古書",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3108.png",
  },
  3109: {
    id: "3109",
    englishName: "Knight's Vow",
    name: "騎士の誓い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3109.png",
  },
  3110: {
    id: "3110",
    englishName: "Frozen Heart",
    name: "フローズン ハート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3110.png",
  },
  3111: {
    id: "3111",
    englishName: "Mercury's Treads",
    name: "マーキュリー ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3111.png",
  },
  3112: {
    id: "3112",
    englishName: "Guardian's Orb",
    name: "ガーディアン オーブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3112.png",
  },
  3113: {
    id: "3113",
    englishName: "Aether Wisp",
    name: "エーテル ウィスプ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3113.png",
  },
  3114: {
    id: "3114",
    englishName: "Forbidden Idol",
    name: "フォビドゥン アイドル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3114.png",
  },
  3115: {
    id: "3115",
    englishName: "Nashor's Tooth",
    name: "ナッシャー トゥース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3115.png",
  },
  3116: {
    id: "3116",
    englishName: "Rylai's Crystal Scepter",
    name: "リーライ クリスタル セプター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3116.png",
  },
  3117: {
    id: "3117",
    englishName: "Mobility Boots",
    name: "モビリティ ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3117.png",
  },
  3118: {
    id: "3118",
    englishName: "Malignance",
    name: "マリグナンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3118.png",
  },
  3119: {
    id: "3119",
    englishName: "Winter's Approach",
    name: "冬の訪れ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3119.png",
  },
  3121: {
    id: "3121",
    englishName: "Fimbulwinter",
    name: "フィンブルウィンター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3121.png",
  },
  3123: {
    id: "3123",
    englishName: "Executioner's Calling",
    name: "エクスキューショナー コーリング",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3123.png",
  },
  3124: {
    id: "3124",
    englishName: "Guinsoo's Rageblade",
    name: "グインソー レイジブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3124.png",
  },
  3128: {
    id: "3128",
    englishName: "Deathfire Grasp",
    name: "デスファイア グラスプ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3128.png",
  },
  3131: {
    id: "3131",
    englishName: "Sword of the Divine",
    name: "天帝の剣",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3131.png",
  },
  3133: {
    id: "3133",
    englishName: "Caulfield's Warhammer",
    name: "コールフィールド ウォーハンマー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3133.png",
  },
  3134: {
    id: "3134",
    englishName: "Serrated Dirk",
    name: "セレイテッド ダーク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3134.png",
  },
  3135: {
    id: "3135",
    englishName: "Void Staff",
    name: "ヴォイド スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3135.png",
  },
  3137: {
    id: "3137",
    englishName: "Cryptbloom",
    name: "クリプトブルーム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3137.png",
  },
  3139: {
    id: "3139",
    englishName: "Mercurial Scimitar",
    name: "マーキュリアル シミター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3139.png",
  },
  3140: {
    id: "3140",
    englishName: "Quicksilver Sash",
    name: "クイックシルバー サッシュ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3140.png",
  },
  3142: {
    id: "3142",
    englishName: "Youmuu's Ghostblade",
    name: "妖夢の霊剣",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3142.png",
  },
  3143: {
    id: "3143",
    englishName: "Randuin's Omen",
    name: "ランデュイン オーメン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3143.png",
  },
  3144: {
    id: "3144",
    englishName: "Scout's Slingshot",
    name: "偵察兵のパチンコ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3144.png",
  },
  3145: {
    id: "3145",
    englishName: "Hextech Alternator",
    name: "ヘクステック オルタネーター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3145.png",
  },
  3146: {
    id: "3146",
    englishName: "Hextech Gunblade",
    name: "ヘクステック ガンブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3146.png",
  },
  3147: {
    id: "3147",
    englishName: "Haunting Guise",
    name: "悲愴な仮面",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3147.png",
  },
  3152: {
    id: "3152",
    englishName: "Hextech Rocketbelt",
    name: "ヘクステック ロケットベルト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3152.png",
  },
  3153: {
    id: "3153",
    englishName: "Blade of The Ruined King",
    name: "ルインドキング ブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3153.png",
  },
  3155: {
    id: "3155",
    englishName: "Hexdrinker",
    name: "ヘクスドリンカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3155.png",
  },
  3156: {
    id: "3156",
    englishName: "Maw of Malmortius",
    name: "マルモティウスの胃袋",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3156.png",
  },
  3157: {
    id: "3157",
    englishName: "Zhonya's Hourglass",
    name: "ゾーニャの砂時計",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3157.png",
  },
  3158: {
    id: "3158",
    englishName: "Ionian Boots of Lucidity",
    name: "アイオニア ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3158.png",
  },
  3161: {
    id: "3161",
    englishName: "Spear of Shojin",
    name: "ショウジンの矛",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3161.png",
  },
  3165: {
    id: "3165",
    englishName: "Morellonomicon",
    name: "モレロノミコン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3165.png",
  },
  3170: {
    id: "3170",
    englishName: "Swiftmarch",
    name: "スイフトマーチ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3170.png",
  },
  3171: {
    id: "3171",
    englishName: "Crimson Lucidity",
    name: "真紅のアイオニア ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3171.png",
  },
  3172: {
    id: "3172",
    englishName: "Gunmetal Greaves",
    name: "ガンメタル ブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3172.png",
  },
  3173: {
    id: "3173",
    englishName: "Chainlaced Crushers",
    name: "チェインレースド クラッシャー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3173.png",
  },
  3174: {
    id: "3174",
    englishName: "Armored Advance",
    name: "装甲強化の進撃",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3174.png",
  },
  3175: {
    id: "3175",
    englishName: "Spellslinger's Shoes",
    name: "連呪使いのブーツ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3175.png",
  },
  3176: {
    id: "3176",
    englishName: "Forever Forward",
    name: "永遠の前進",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3176.png",
  },
  3177: {
    id: "3177",
    englishName: "Guardian's Blade",
    name: "ガーディアン ブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3177.png",
  },
  3179: {
    id: "3179",
    englishName: "Umbral Glaive",
    name: "アンブラル グレイブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3179.png",
  },
  3181: {
    id: "3181",
    englishName: "Hullbreaker",
    name: "ハルブレイカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3181.png",
  },
  3184: {
    id: "3184",
    englishName: "Guardian's Hammer",
    name: "ガーディアン ハンマー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3184.png",
  },
  3190: {
    id: "3190",
    englishName: "Locket of the Iron Solari",
    name: "ソラリのロケット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3190.png",
  },
  3193: {
    id: "3193",
    englishName: "Gargoyle Stoneplate",
    name: "ガーゴイル ストーンプレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3193.png",
  },
  3211: {
    id: "3211",
    englishName: "Spectre's Cowl",
    name: "スペクター カウル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3211.png",
  },
  322065: {
    id: "322065",
    englishName: "Shurelya's Battlesong",
    name: "シュレリアの戦歌",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/322065.png",
  },
  3222: {
    id: "3222",
    englishName: "Mikael's Blessing",
    name: "ミカエルの祝福",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3222.png",
  },
  323002: {
    id: "323002",
    englishName: "Trailblazer",
    name: "先人の道標",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323002.png",
  },
  323003: {
    id: "323003",
    englishName: "Archangel's Staff",
    name: "アークエンジェル スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323003.png",
  },
  323004: {
    id: "323004",
    englishName: "Manamune",
    name: "マナムネ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323004.png",
  },
  323050: {
    id: "323050",
    englishName: "Zeke's Convergence",
    name: "ジーク コンバージェンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323050.png",
  },
  323070: {
    id: "323070",
    englishName: "Tear of the Goddess",
    name: "女神の涙",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323070.png",
  },
  323075: {
    id: "323075",
    englishName: "Thornmail",
    name: "ソーンメイル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323075.png",
  },
  323107: {
    id: "323107",
    englishName: "Redemption",
    name: "リデンプション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323107.png",
  },
  323109: {
    id: "323109",
    englishName: "Knight's Vow",
    name: "騎士の誓い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323109.png",
  },
  323110: {
    id: "323110",
    englishName: "Frozen Heart",
    name: "フローズン ハート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323110.png",
  },
  323119: {
    id: "323119",
    englishName: "Winter's Approach",
    name: "冬の訪れ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323119.png",
  },
  323190: {
    id: "323190",
    englishName: "Locket of the Iron Solari",
    name: "ソラリのロケット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323190.png",
  },
  323222: {
    id: "323222",
    englishName: "Mikael's Blessing",
    name: "ミカエルの祝福",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323222.png",
  },
  323504: {
    id: "323504",
    englishName: "Ardent Censer",
    name: "アーデント センサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/323504.png",
  },
  324005: {
    id: "324005",
    englishName: "Imperial Mandate",
    name: "帝国の指令",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/324005.png",
  },
  326616: {
    id: "326616",
    englishName: "Staff of Flowing Water",
    name: "フロー ウォーター スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/326616.png",
  },
  326617: {
    id: "326617",
    englishName: "Moonstone Renewer",
    name: "ムーンストーンの再生",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/326617.png",
  },
  326620: {
    id: "326620",
    englishName: "Echoes of Helia",
    name: "ヘリアの残響",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/326620.png",
  },
  326621: {
    id: "326621",
    englishName: "Dawncore",
    name: "ドーンコア",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/326621.png",
  },
  326657: {
    id: "326657",
    englishName: "Rod of Ages",
    name: "ロッド オブ エイジス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/326657.png",
  },
  328020: {
    id: "328020",
    englishName: "Abyssal Mask",
    name: "アビサル マスク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/328020.png",
  },
  3302: {
    id: "3302",
    englishName: "Terminus",
    name: "テルミヌス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3302.png",
  },
  3330: {
    id: "3330",
    englishName: "Scarecrow Effigy",
    name: "身代わり人形",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3330.png",
  },
  3340: {
    id: "3340",
    englishName: "Stealth Ward",
    name: "ステルス ワード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3340.png",
  },
  3348: {
    id: "3348",
    englishName: "Arcane Sweeper",
    name: "アーケイン スイーパー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3348.png",
  },
  3349: {
    id: "3349",
    englishName: "Lucent Singularity",
    name: "シンギュラリティ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3349.png",
  },
  3363: {
    id: "3363",
    englishName: "Farsight Alteration",
    name: "ファーサイト オルタレーション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3363.png",
  },
  3364: {
    id: "3364",
    englishName: "Oracle Lens",
    name: "オラクル レンズ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3364.png",
  },
  3400: {
    id: "3400",
    englishName: "Your Cut",
    name: "お前の取り分",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3400.png",
  },
  3430: {
    id: "3430",
    englishName: "Rite Of Ruin",
    name: "破滅の儀式",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3430.png",
  },
  3504: {
    id: "3504",
    englishName: "Ardent Censer",
    name: "アーデント センサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3504.png",
  },
  3508: {
    id: "3508",
    englishName: "Essence Reaver",
    name: "エッセンス リーバー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3508.png",
  },
  3513: {
    id: "3513",
    englishName: "Eye of the Herald",
    name: "ヘラルドの瞳",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3513.png",
  },
  3599: {
    id: "3599",
    englishName: "Kalista's Black Spear",
    name: "カリスタの復讐の黒槍",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3599.png",
  },
  3600: {
    id: "3600",
    englishName: "Kalista's Black Spear",
    name: "カリスタの復讐の黒槍",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3600.png",
  },
  3742: {
    id: "3742",
    englishName: "Dead Man's Plate",
    name: "デッド マン プレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3742.png",
  },
  3748: {
    id: "3748",
    englishName: "Titanic Hydra",
    name: "タイタン ハイドラ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3748.png",
  },
  3801: {
    id: "3801",
    englishName: "Crystalline Bracer",
    name: "クリスタル ブレーサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3801.png",
  },
  3802: {
    id: "3802",
    englishName: "Lost Chapter",
    name: "ロスト チャプター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3802.png",
  },
  3803: {
    id: "3803",
    englishName: "Catalyst of Aeons",
    name: "久遠のカタリスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3803.png",
  },
  3814: {
    id: "3814",
    englishName: "Edge of Night",
    name: "ナイト エッジ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3814.png",
  },
  3850: {
    id: "3850",
    englishName: "Spellthief's Edge",
    name: "スペルシーフ エッジ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3850.png",
  },
  3851: {
    id: "3851",
    englishName: "Frostfang",
    name: "フロストファング",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3851.png",
  },
  3853: {
    id: "3853",
    englishName: "Shard of True Ice",
    name: "真なる氷のかけら",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3853.png",
  },
  3854: {
    id: "3854",
    englishName: "Steel Shoulderguards",
    name: "鋼のショルダーガード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3854.png",
  },
  3855: {
    id: "3855",
    englishName: "Runesteel Spaulders",
    name: "ルーン鋼の肩当て",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3855.png",
  },
  3857: {
    id: "3857",
    englishName: "Pauldrons of Whiterock",
    name: "ホワイトロックの肩鎧",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3857.png",
  },
  3858: {
    id: "3858",
    englishName: "Relic Shield",
    name: "レリック シールド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3858.png",
  },
  3859: {
    id: "3859",
    englishName: "Targon's Buckler",
    name: "ターゴンの盾",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3859.png",
  },
  3860: {
    id: "3860",
    englishName: "Bulwark of the Mountain",
    name: "霊峰の砦",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3860.png",
  },
  3862: {
    id: "3862",
    englishName: "Spectral Sickle",
    name: "霊者の鎌",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3862.png",
  },
  3863: {
    id: "3863",
    englishName: "Harrowing Crescent",
    name: "暗黒の刻の三日月鎌",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3863.png",
  },
  3864: {
    id: "3864",
    englishName: "Black Mist Scythe",
    name: "黒き霧の大鎌",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3864.png",
  },
  3865: {
    id: "3865",
    englishName: "World Atlas",
    name: "ワールド アトラス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3865.png",
  },
  3866: {
    id: "3866",
    englishName: "Runic Compass",
    name: "ルーニック コンパス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3866.png",
  },
  3867: {
    id: "3867",
    englishName: "Bounty of Worlds",
    name: "世界の恵み",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3867.png",
  },
  3869: {
    id: "3869",
    englishName: "Celestial Opposition",
    name: "セレスティアル オポジション",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3869.png",
  },
  3870: {
    id: "3870",
    englishName: "Dream Maker",
    name: "ドリーム メーカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3870.png",
  },
  3871: {
    id: "3871",
    englishName: "Zaz'Zak's Realmspike",
    name: "ザズ＝ザクのレルムスパイク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3871.png",
  },
  3876: {
    id: "3876",
    englishName: "Solstice Sleigh",
    name: "至点のソリ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3876.png",
  },
  3877: {
    id: "3877",
    englishName: "Bloodsong",
    name: "ブラッドソング",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3877.png",
  },
  3901: {
    id: "3901",
    englishName:
      "<rarityLegendary>Fire at Will</rarityLegendary><br><subtitleLeft><silver>500 Silver Serpents</silver></subtitleLeft>",
    name: "<rarityLegendary>乱れ撃ち</rarityLegendary><br><subtitleLeft><silver>500シルバーサーペント</silver></subtitleLeft>",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3901.png",
  },
  3902: {
    id: "3902",
    englishName:
      "<rarityLegendary>Death's Daughter</rarityLegendary><br><subtitleLeft><silver>500 Silver Serpents</silver></subtitleLeft>",
    name: "<rarityLegendary>死の女神</rarityLegendary><br><subtitleLeft><silver>500シルバーサーペント</silver></subtitleLeft>",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3902.png",
  },
  3903: {
    id: "3903",
    englishName:
      "<rarityLegendary>Raise Morale</rarityLegendary><br><subtitleLeft><silver>500 Silver Serpents</silver></subtitleLeft>",
    name: "<rarityLegendary>士気上昇</rarityLegendary><br><subtitleLeft><silver>500シルバーサーペント</silver></subtitleLeft>",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3903.png",
  },
  3916: {
    id: "3916",
    englishName: "Oblivion Orb",
    name: "忘却のオーブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/3916.png",
  },
  4003: {
    id: "4003",
    englishName: "Lifeline",
    name: "ライフライン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4003.png",
  },
  4004: {
    id: "4004",
    englishName: "Spectral Cutlass",
    name: "スペクトラル カトラス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4004.png",
  },
  4005: {
    id: "4005",
    englishName: "Imperial Mandate",
    name: "帝国の指令",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4005.png",
  },
  4010: {
    id: "4010",
    englishName: "Bloodletter's Curse",
    name: "ブラッドレターの呪い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4010.png",
  },
  4011: {
    id: "4011",
    englishName: "Sword of Blossoming Dawn",
    name: "花咲く夜明けの剣",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4011.png",
  },
  4012: {
    id: "4012",
    englishName: "Sin Eater",
    name: "罪喰人",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4012.png",
  },
  4013: {
    id: "4013",
    englishName: "Lightning Braid",
    name: "稲妻の鎖",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4013.png",
  },
  4014: {
    id: "4014",
    englishName: "Frozen Mallet",
    name: "フローズン マレット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4014.png",
  },
  4015: {
    id: "4015",
    englishName: "Perplexity",
    name: "混乱",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4015.png",
  },
  4016: {
    id: "4016",
    englishName: "Wordless Promise",
    name: "無言の約束",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4016.png",
  },
  4017: {
    id: "4017",
    englishName: "Hellfire Hatchet",
    name: "獄炎のハチェット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4017.png",
  },
  4401: {
    id: "4401",
    englishName: "Force of Nature",
    name: "自然の力",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4401.png",
  },
  4402: {
    id: "4402",
    englishName: "Innervating Locket",
    name: "心身統御のペンダント",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4402.png",
  },
  4403: {
    id: "4403",
    englishName: "The Golden Spatula",
    name: "ザ・金のへら",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4403.png",
  },
  443054: {
    id: "443054",
    englishName: "Darksteel Talons",
    name: "ダークスティール タロン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443054.png",
  },
  443055: {
    id: "443055",
    englishName: "Fulmination",
    name: "起爆",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443055.png",
  },
  443056: {
    id: "443056",
    englishName: "Demon King's Crown",
    name: "不死身の大王の王冠",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443056.png",
  },
  443058: {
    id: "443058",
    englishName: "Shield of Molten Stone",
    name: "モルテンストーン シールド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443058.png",
  },
  443059: {
    id: "443059",
    englishName: "Cloak of Starry Night",
    name: "星空のマント",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443059.png",
  },
  443060: {
    id: "443060",
    englishName: "Sword of the Divine",
    name: "天帝の剣",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443060.png",
  },
  443061: {
    id: "443061",
    englishName: "Force Of Entropy",
    name: "エントロピーの力",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443061.png",
  },
  443062: {
    id: "443062",
    englishName: "Sanguine Gift",
    name: "紅血の賜物",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443062.png",
  },
  443063: {
    id: "443063",
    englishName: "Eleisa's Miracle",
    name: "エリーサの奇跡",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443063.png",
  },
  443064: {
    id: "443064",
    englishName: "Talisman Of Ascension",
    name: "超越のタリスマン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443064.png",
  },
  443069: {
    id: "443069",
    englishName: "Hamstringer",
    name: "ハムストリンガー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443069.png",
  },
  443079: {
    id: "443079",
    englishName: "Turbo Chemtank",
    name: "ターボ ケミタンク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443079.png",
  },
  443080: {
    id: "443080",
    englishName: "Twin Mask",
    name: "ツインマスク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443080.png",
  },
  443081: {
    id: "443081",
    englishName: "Hexbolt Companion",
    name: "ヘクスボルト・コンパニオン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443081.png",
  },
  443090: {
    id: "443090",
    englishName: "Reaper's Toll",
    name: "リーパーズ・トール",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443090.png",
  },
  443193: {
    id: "443193",
    englishName: "Gargoyle Stoneplate",
    name: "ガーゴイル ストーンプレート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/443193.png",
  },
  444636: {
    id: "444636",
    englishName: "Night Harvester",
    name: "ナイト ハーベスター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/444636.png",
  },
  444637: {
    id: "444637",
    englishName: "Demonic Embrace",
    name: "悪魔の抱擁",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/444637.png",
  },
  444644: {
    id: "444644",
    englishName: "Crown of the Shattered Queen",
    name: "シャッタードクイーン クラウン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/444644.png",
  },
  446632: {
    id: "446632",
    englishName: "Divine Sunderer",
    name: "ディヴァイン サンダラー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/446632.png",
  },
  446656: {
    id: "446656",
    englishName: "Everfrost",
    name: "エバーフロスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/446656.png",
  },
  446667: {
    id: "446667",
    englishName: "Radiant Virtue",
    name: "レディアント ヴァーチュ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/446667.png",
  },
  446671: {
    id: "446671",
    englishName: "Galeforce",
    name: "ゲイルフォース",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/446671.png",
  },
  446691: {
    id: "446691",
    englishName: "Duskblade of Draktharr",
    name: "ドラクサー ダスクブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/446691.png",
  },
  446693: {
    id: "446693",
    englishName: "Prowler's Claw",
    name: "プローラー クロウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/446693.png",
  },
  447100: {
    id: "447100",
    englishName: "Mirage Blade",
    name: "ミラージュ ブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447100.png",
  },
  447101: {
    id: "447101",
    englishName: "Gambler's Blade",
    name: "ギャンブラーの剣",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447101.png",
  },
  447102: {
    id: "447102",
    englishName: "Reality Fracture",
    name: "現実の崩壊",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447102.png",
  },
  447103: {
    id: "447103",
    englishName: "Hemomancer's Helm",
    name: "操血術師のヘルム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447103.png",
  },
  447104: {
    id: "447104",
    englishName: "Innervating Locket",
    name: "心身統御のペンダント",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447104.png",
  },
  447105: {
    id: "447105",
    englishName: "Empyrean Promise",
    name: "荘厳の天球の約束",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447105.png",
  },
  447106: {
    id: "447106",
    englishName: "Dragonheart",
    name: "ドラゴンハート",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447106.png",
  },
  447107: {
    id: "447107",
    englishName: "Decapitator",
    name: "デキャピテーター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447107.png",
  },
  447108: {
    id: "447108",
    englishName: "Runecarver",
    name: "ルーン職人",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447108.png",
  },
  447109: {
    id: "447109",
    englishName: "Cruelty",
    name: "冷酷な一撃",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447109.png",
  },
  447110: {
    id: "447110",
    englishName: "Moonflair Spellblade",
    name: "ムーンフレア スペルブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447110.png",
  },
  447111: {
    id: "447111",
    englishName: "Overlord's Bloodmail",
    name: "覇王のブラッドメイル",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447111.png",
  },
  447112: {
    id: "447112",
    englishName: "Flesheater",
    name: "肉喰らう者",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447112.png",
  },
  447113: {
    id: "447113",
    englishName: "Detonation Orb",
    name: "爆発オーブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447113.png",
  },
  447114: {
    id: "447114",
    englishName: "Reverberation",
    name: "残響",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447114.png",
  },
  447115: {
    id: "447115",
    englishName: "Regicide",
    name: "レジサイド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447115.png",
  },
  447116: {
    id: "447116",
    englishName: "Kinkou Jitte",
    name: "均衡の守人ジッテ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447116.png",
  },
  447118: {
    id: "447118",
    englishName: "Pyromancer's Cloak",
    name: "パイロマンサーのマント",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447118.png",
  },
  447119: {
    id: "447119",
    englishName: "Lightning Rod",
    name: "雷撃の杖",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447119.png",
  },
  447120: {
    id: "447120",
    englishName: "Diamond-Tipped Spear",
    name: "ダイヤモンドチップスピア",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447120.png",
  },
  447121: {
    id: "447121",
    englishName: "Twilight's Edge",
    name: "トワイライト・エッジ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447121.png",
  },
  447122: {
    id: "447122",
    englishName: "Black Hole Gauntlet",
    name: "ブラックホールガントレット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447122.png",
  },
  447123: {
    id: "447123",
    englishName: "Puppeteer",
    name: "人形使い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/447123.png",
  },
  4628: {
    id: "4628",
    englishName: "Horizon Focus",
    name: "ホライゾン フォーカス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4628.png",
  },
  4629: {
    id: "4629",
    englishName: "Cosmic Drive",
    name: "コズミック ドライブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4629.png",
  },
  4630: {
    id: "4630",
    englishName: "Blighting Jewel",
    name: "枯死の宝石",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4630.png",
  },
  4632: {
    id: "4632",
    englishName: "Verdant Barrier",
    name: "ヴァーダント バリア",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4632.png",
  },
  4633: {
    id: "4633",
    englishName: "Riftmaker",
    name: "リフトメーカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4633.png",
  },
  4635: {
    id: "4635",
    englishName: "Leeching Leer",
    name: "リーチング リア",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4635.png",
  },
  4636: {
    id: "4636",
    englishName: "Night Harvester",
    name: "ナイト ハーベスター",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4636.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/night-harvester_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/night-harvester_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/night-harvester_infobox.png",
  },
  4637: {
    id: "4637",
    englishName: "Demonic Embrace",
    name: "悪魔の抱擁",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4637.png",
  },
  4638: {
    id: "4638",
    englishName: "Watchful Wardstone",
    name: "ウォッチフル ワードストーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4638.png",
  },
  4641: {
    id: "4641",
    englishName: "Stirring Wardstone",
    name: "スターリング ワードストーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4641.png",
  },
  4642: {
    id: "4642",
    englishName: "Bandleglass Mirror",
    name: "バンドルグラスの鏡",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4642.png",
  },
  4643: {
    id: "4643",
    englishName: "Vigilant Wardstone",
    name: "ビジラント ワードストーン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4643.png",
  },
  4644: {
    id: "4644",
    englishName: "Crown of the Shattered Queen",
    name: "シャッタードクイーン クラウン",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4644.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/crown-of-the-shattered-queen_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/crown-of-the-shattered-queen_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/crown-of-the-shattered-queen_infobox.png",
  },
  4645: {
    id: "4645",
    englishName: "Shadowflame",
    name: "シャドウフレイム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4645.png",
  },
  4646: {
    id: "4646",
    englishName: "Stormsurge",
    name: "ストームサージ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/4646.png",
  },
  6029: {
    id: "6029",
    englishName: "Ironspike Whip",
    name: "アイアンスパイク ウィップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6029.png",
  },
  6035: {
    id: "6035",
    englishName: "Silvermere Dawn",
    name: "シルバーミアの夜明け",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6035.png",
  },
  6333: {
    id: "6333",
    englishName: "Death's Dance",
    name: "デス ダンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6333.png",
  },
  6609: {
    id: "6609",
    englishName: "Chempunk Chainsword",
    name: "ケミパンク チェーンソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6609.png",
  },
  6610: {
    id: "6610",
    englishName: "Sundered Sky",
    name: "サンダード スカイ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6610.png",
  },
  6616: {
    id: "6616",
    englishName: "Staff of Flowing Water",
    name: "フロー ウォーター スタッフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6616.png",
  },
  6617: {
    id: "6617",
    englishName: "Moonstone Renewer",
    name: "ムーンストーンの再生",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6617.png",
  },
  6620: {
    id: "6620",
    englishName: "Echoes of Helia",
    name: "ヘリアの残響",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6620.png",
  },
  6621: {
    id: "6621",
    englishName: "Dawncore",
    name: "ドーンコア",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6621.png",
  },
  6630: {
    id: "6630",
    englishName: "Goredrinker",
    name: "ゴアドリンカー",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6630.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/goredrinker_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/goredrinker_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/goredrinker_infobox.png",
  },
  6631: {
    id: "6631",
    englishName: "Stridebreaker",
    name: "ストライドブレイカー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6631.png",
  },
  6632: {
    id: "6632",
    englishName: "Divine Sunderer",
    name: "ディヴァイン サンダラー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6632.png",
  },
  6653: {
    id: "6653",
    englishName: "Liandry's Torment",
    name: "ライアンドリーの仮面",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6653.png",
  },
  6655: {
    id: "6655",
    englishName: "Luden's Companion",
    name: "ルーデン コンパニオン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6655.png",
  },
  6656: {
    id: "6656",
    englishName: "Everfrost",
    name: "エバーフロスト",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6656.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/everfrost_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/everfrost_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/everfrost_infobox.png",
  },
  6657: {
    id: "6657",
    englishName: "Rod of Ages",
    name: "ロッド オブ エイジス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6657.png",
  },
  6660: {
    id: "6660",
    englishName: "Bami's Cinder",
    name: "バミ シンダー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6660.png",
  },
  6662: {
    id: "6662",
    englishName: "Iceborn Gauntlet",
    name: "アイスボーン ガントレット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6662.png",
  },
  6664: {
    id: "6664",
    englishName: "Hollow Radiance",
    name: "ホロウ レディアンス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6664.png",
  },
  6665: {
    id: "6665",
    englishName: "Jak'Sho, The Protean",
    name: "変幻自在のジャック＝ショー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6665.png",
  },
  6667: {
    id: "6667",
    englishName: "Radiant Virtue",
    name: "レディアント ヴァーチュ",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6667.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/radiant-virtue_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/radiant-virtue_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/radiant-virtue_infobox.png",
  },
  6670: {
    id: "6670",
    englishName: "Noonquiver",
    name: "ヌーンクィヴァー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6670.png",
  },
  6671: {
    id: "6671",
    englishName: "Galeforce",
    name: "ゲイルフォース",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6671.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/galeforce_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/galeforce_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/galeforce_infobox.png",
  },
  6672: {
    id: "6672",
    englishName: "Kraken Slayer",
    name: "クラーケン スレイヤー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6672.png",
  },
  6673: {
    id: "6673",
    englishName: "Immortal Shieldbow",
    name: "イモータル シールドボウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6673.png",
  },
  6675: {
    id: "6675",
    englishName: "Navori Flickerblade",
    name: "ナヴォリ フリッカーブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6675.png",
  },
  6676: {
    id: "6676",
    englishName: "The Collector",
    name: "コレクター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6676.png",
  },
  6677: {
    id: "6677",
    englishName: "Rageknife",
    name: "レイジナイフ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6677.png",
  },
  6690: {
    id: "6690",
    englishName: "Rectrix",
    name: "レクトリクス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6690.png",
  },
  6691: {
    id: "6691",
    englishName: "Duskblade of Draktharr",
    name: "ドラクサー ダスクブレード",
    isMythic: true,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6691.png",
    sidePopupAsset: {
      right:
        "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/right/duskblade-of-draktharr_side_right.png",
      left: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/mythic_items/left/duskblade-of-draktharr_side_left.png",
    },
    infoboxAsset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/mythic_items/duskblade-of-draktharr_infobox.png",
  },
  6692: {
    id: "6692",
    englishName: "Eclipse",
    name: "赤月の刃",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6692.png",
  },
  6693: {
    id: "6693",
    englishName: "Prowler's Claw",
    name: "プローラー クロウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6693.png",
  },
  6694: {
    id: "6694",
    englishName: "Serylda's Grudge",
    name: "セリルダの怨恨",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6694.png",
  },
  6695: {
    id: "6695",
    englishName: "Serpent's Fang",
    name: "毒蛇の牙",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6695.png",
  },
  6696: {
    id: "6696",
    englishName: "Axiom Arc",
    name: "アクシオム アーク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6696.png",
  },
  6697: {
    id: "6697",
    englishName: "Hubris",
    name: "ヒュブリス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6697.png",
  },
  6698: {
    id: "6698",
    englishName: "Profane Hydra",
    name: "プロフェイン ハイドラ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6698.png",
  },
  6699: {
    id: "6699",
    englishName: "Voltaic Cyclosword",
    name: "ボルテイク サイクロソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6699.png",
  },
  6700: {
    id: "6700",
    englishName: "Shield of the Rakkor",
    name: "ラッコールの盾",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6700.png",
  },
  6701: {
    id: "6701",
    englishName: "Opportunity",
    name: "オポチュニティー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/6701.png",
  },
  7050: {
    id: "7050",
    englishName: "Gangplank Placeholder",
    name: "ガングプランク プレースホルダー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/7050.png",
  },
  8001: {
    id: "8001",
    englishName: "Anathema's Chains",
    name: "アナセマ チェイン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/8001.png",
  },
  8010: {
    id: "8010",
    englishName: "Bloodletter's Curse",
    name: "ブラッドレターの呪い",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/8010.png",
  },
  8020: {
    id: "8020",
    englishName: "Abyssal Mask",
    name: "アビサル マスク",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/8020.png",
  },
  9168: {
    id: "9168",
    englishName: "Locked Weapon Slot",
    name: "ロックされた武器スロット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9168.png",
  },
  9171: {
    id: "9171",
    englishName: "Cyclonic Slicers",
    name: "サイクロニック スライサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9171.png",
  },
  9172: {
    id: "9172",
    englishName: "YuumiBot",
    name: "ユーミボット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9172.png",
  },
  9173: {
    id: "9173",
    englishName: "Radiant Field",
    name: "レディアント フィールド",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9173.png",
  },
  9174: {
    id: "9174",
    englishName: "Statikk Sword",
    name: "スタティック ソード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9174.png",
  },
  9175: {
    id: "9175",
    englishName: "Lioness's Lament",
    name: "ライオネスの悲嘆",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9175.png",
  },
  9176: {
    id: "9176",
    englishName: "Gatling Bunny-Guns",
    name: "ガトリング バニーガン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9176.png",
  },
  9177: {
    id: "9177",
    englishName: "Searing Shortbow",
    name: "灼熱のショートボウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9177.png",
  },
  9178: {
    id: "9178",
    englishName: "The Annihilator",
    name: "アナイアレーター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9178.png",
  },
  9179: {
    id: "9179",
    englishName: "Battle Bunny Crossbow",
    name: "バトルバニー クロスボウ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9179.png",
  },
  9180: {
    id: "9180",
    englishName: "UwU Blaster",
    name: "UwU ブラスター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9180.png",
  },
  9181: {
    id: "9181",
    englishName: "Vortex Glove",
    name: "ボルテックス グローブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9181.png",
  },
  9183: {
    id: "9183",
    englishName: "Blade-o-rang",
    name: "ブレードブーメラン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9183.png",
  },
  9184: {
    id: "9184",
    englishName: "Bunny Mega-Blast",
    name: "バニー メガブラスト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9184.png",
  },
  9185: {
    id: "9185",
    englishName: "Anti-Shark Sea Mine",
    name: "対シャーク海中地雷",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9185.png",
  },
  9187: {
    id: "9187",
    englishName: "T.I.B.B.E.R.S",
    name: "T.I.B.B.E.R.S",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9187.png",
  },
  9188: {
    id: "9188",
    englishName: "Ani-Mines",
    name: "アニマイン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9188.png",
  },
  9189: {
    id: "9189",
    englishName: "Final City Transit",
    name: "ファイナルシティ交通",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9189.png",
  },
  9190: {
    id: "9190",
    englishName: "Echoing Batblades",
    name: "エコー バットブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9190.png",
  },
  9192: {
    id: "9192",
    englishName: "Paw Print Poisoner",
    name: "毒の足跡",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9192.png",
  },
  9193: {
    id: "9193",
    englishName: "Iceblast Armor",
    name: "アイスブラスト アーマー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9193.png",
  },
  9271: {
    id: "9271",
    englishName: "Unceasing Cyclone",
    name: "終わりなき旋風",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9271.png",
  },
  9272: {
    id: "9272",
    englishName: "YuumiBot_Final_FINAL",
    name: "YuumiBot_Final_FINAL",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9272.png",
  },
  9273: {
    id: "9273",
    englishName: "Explosive Embrace",
    name: "爆発の抱擁",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9273.png",
  },
  9274: {
    id: "9274",
    englishName: "Prumbis's Electrocarver",
    name: "プランビス エレクトロカーバー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9274.png",
  },
  9275: {
    id: "9275",
    englishName: "Enveloping Light",
    name: "包みこむ光",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9275.png",
  },
  9276: {
    id: "9276",
    englishName: "Double Bun-Bun Barrage",
    name: "ダブルバンバン砲",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9276.png",
  },
  9277: {
    id: "9277",
    englishName: "Evolved Embershot",
    name: "進化したエンバーショット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9277.png",
  },
  9278: {
    id: "9278",
    englishName: "Animapocalypse",
    name: "アニマポカリプス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9278.png",
  },
  9279: {
    id: "9279",
    englishName: "Bunny Prime Ballista",
    name: "バニープライム バリスタ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9279.png",
  },
  9280: {
    id: "9280",
    englishName: "OwO Blaster",
    name: "OwO ブラスター",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9280.png",
  },
  9281: {
    id: "9281",
    englishName: "Tempest's Gauntlet",
    name: "テンペスト ガントレット",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9281.png",
  },
  9283: {
    id: "9283",
    englishName: "Quad-o-rang",
    name: "クアッドブーメラン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9283.png",
  },
  9284: {
    id: "9284",
    englishName: "Rapid Rabbit Raindown",
    name: "ラピッド ラビット レインダウン",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9284.png",
  },
  9285: {
    id: "9285",
    englishName: "Neverending Mobstomper",
    name: "終わりなきモブストンパー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9285.png",
  },
  9287: {
    id: "9287",
    englishName: "T.I.B.B.E.R.S (B.E.E.G Edition)",
    name: "T.I.B.B.E.R.S (B.E.E.Gエディション)",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9287.png",
  },
  9288: {
    id: "9288",
    englishName: "Jinx's Tri-Namite",
    name: "ジンクスのトライナマイト",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9288.png",
  },
  9289: {
    id: "9289",
    englishName: "FC Limited Express",
    name: "ファイナルシティ特急",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9289.png",
  },
  9290: {
    id: "9290",
    englishName: "Vayne's Chromablades",
    name: "ヴェインのクロマブレード",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9290.png",
  },
  9292: {
    id: "9292",
    englishName: "Bearfoot Chem-Dispenser",
    name: "ベアフット ケムディスペンサー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9292.png",
  },
  9293: {
    id: "9293",
    englishName: "Deep Freeze",
    name: "急速冷凍",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9293.png",
  },
  9300: {
    id: "9300",
    englishName: "Meow Meow",
    name: "にゃおにゃお",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9300.png",
  },
  9301: {
    id: "9301",
    englishName: "Shield Slam",
    name: "シールドスラム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9301.png",
  },
  9302: {
    id: "9302",
    englishName: "Sound Wave",
    name: "サウンドウェーブ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9302.png",
  },
  9303: {
    id: "9303",
    englishName: "Pillory Swipe",
    name: "ピロリースワイプ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9303.png",
  },
  9304: {
    id: "9304",
    englishName: "Steel Tempest",
    name: "抜刀",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9304.png",
  },
  9305: {
    id: "9305",
    englishName: "Tentacle Slam",
    name: "触手スラム",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9305.png",
  },
  9306: {
    id: "9306",
    englishName: "Winged Dagger",
    name: "ウィングダガー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9306.png",
  },
  9307: {
    id: "9307",
    englishName: "Guiding Hex",
    name: "ガイディング ヘックス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9307.png",
  },
  9308: {
    id: "9308",
    englishName: "Bunny Hop",
    name: "バニーホップ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9308.png",
  },
  9400: {
    id: "9400",
    englishName: "Battle Cat Barrage",
    name: "バトルキャット バラージ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9400.png",
  },
  9401: {
    id: "9401",
    englishName: "Light of the Lion",
    name: "獅子の光",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9401.png",
  },
  9402: {
    id: "9402",
    englishName: "Anima Echo",
    name: "アニマ エコー",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9402.png",
  },
  9403: {
    id: "9403",
    englishName: "Savage Slice",
    name: "獰猛な斬撃",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9403.png",
  },
  9404: {
    id: "9404",
    englishName: "Wandering Storms",
    name: "流浪の嵐",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9404.png",
  },
  9405: {
    id: "9405",
    englishName: "Grizzly Smash",
    name: "グリズリーの打撃",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9405.png",
  },
  9406: {
    id: "9406",
    englishName: "Lover's Ricochet",
    name: "恋人たちのリコシェ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9406.png",
  },
  9407: {
    id: "9407",
    englishName: "Hopped-Up Hex",
    name: "ホップアップ ヘックス",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9407.png",
  },
  9408: {
    id: "9408",
    englishName: "Carrot Crash",
    name: "キャロット クラッシュ",
    isMythic: false,
    asset:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/items/9408.png",
  },
};

export const getMythicItems = () => {
  return Object.values(itemsList).filter((item) => item.isMythic);
};

export const getInfoboxItems = () => {
  return Object.values(itemsList).filter(
    (item) => item.isMythic && item.infoboxAsset
  );
};

export const isItemMythic = (itemID) => {
  return get(itemsList, `[${itemID}].isMythic`, false);
};
