// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";
// libraries
import moment from "moment";
import { cloneDeep, get } from "lodash";
import styled from "styled-components";
// internal
import Button from "../../Button";
import Picker from "../../Picker";
import { i18n } from "../../i18n";
import DataContainer from "../DataContainer";
import { leagueName, splitName } from "../../../util/const";
import { stopListening } from "../../../util/firebase";
import {
  removeChampionsAssets,
  setMatchRosterAssets,
} from "../../../util/assetLoader";
class DataSchedule extends Component {
  state = {
    league: window.MainData.schedule.league || "ljl",
    season: window.MainData.schedule.season || "2018",
    split: window.MainData.schedule.split || "summer",
    round: window.MainData.schedule.round || 0,
    rounds: window.MainData.schedule.rounds,
    match: window.MainData.schedule.match || 0,
    teams: window.MainData.teams || {},
  };

  static propTypes = {
    openSplitMenu: PropTypes.func,
    updateSchedule: PropTypes.func,
  };

  async componentDidMount() {
    this.updateSchedule();
    await removeChampionsAssets();
    await setMatchRosterAssets(
      get(window, "MainData.schedule.round", 0),
      get(window, "MainData.schedule.match", 0)
    );
  }

  handleRoundSelect = async (e) => {
    // stop all firebase listeners first
    stopListening(null);

    const round = Number(e);
    await this.setState({ round, match: 0 }, () => {
      window.MainData.schedule.round = round;
      window.MainData.schedule.game = 0;
      this.updateSchedule();
    });
  };

  handleMatchSelect = async (e) => {
    // stop all firebase listeners first
    stopListening(null);

    const match = e;
    await this.setState({ match: Number(match) });
    window.MainData.schedule.match = Number(match);
    window.MainData.schedule.game = 0;
    await removeChampionsAssets();
    await setMatchRosterAssets(window.MainData.schedule.round, match);
    this.updateSchedule();
  };

  handleBestOfSelect = async (index, e) => {
    const bestOf = e;
    const rounds = this.state.rounds;
    rounds[this.state.round].matches[index].bestOf = Number(bestOf);
    await this.setState({ rounds });
    window.MainData.schedule.rounds[this.state.round].matches[index].bestOf =
      Number(bestOf);
    this.updateSchedule();
  };

  handleChangeMatchTime = (e) => {
    const rounds = this.state.rounds;
    const time = e.target.value;
    const round = e.target.attributes["data-round"].value;
    const match = e.target.attributes["data-match"].value;
    rounds[round].matches[match].datetime = time;
    this.setState({ rounds });
    window.MainData.schedule.rounds[round].matches[match].datetime = time;
  };

  handleMatchHide = (matchIndex) => {
    // console.log("Need to hide the match", matchIndex);
    let match = this.state.rounds[this.state.round].matches[matchIndex];
    let newRounds = cloneDeep(this.state.rounds);
    if (newRounds[this.state.round].matches[matchIndex].hide) {
      if (
        // Check that both teams are selected
        match.team100 &&
        match.team200 &&
        match.team100.tricode !== match.team200.tricode &&
        match.team100.tricode &&
        match.team200.tricode
      ) {
        newRounds[this.state.round].matches[matchIndex]["hide"] = false;
        // newRounds[this.state.round].matches[matchIndex]['games'] = []
      } else {
        console.log("CANNOT show this game");
      }
    } else {
      newRounds[this.state.round].matches[matchIndex]["hide"] = true;
    }
    // console.log("NewRounds", newRounds);
    this.setState(
      {
        rounds: newRounds,
      },
      () => {
        this.updateRounds();
      }
    );
  };

  handleTeamSelect = (matchIndex, team, selectedTeam) => {
    let newRounds = cloneDeep(this.state.rounds);
    newRounds[this.state.round].matches[matchIndex][team] = {
      ...this.state.teams[selectedTeam],
      tricode: this.state.teams[selectedTeam].tricode.toUpperCase(),
    };
    // Reset the games array
    newRounds[this.state.round].matches[matchIndex] = {
      ...newRounds[this.state.round].matches[matchIndex],
      games: [],
    };
    // console.log("NewRounds", newRounds);
    this.setState(
      {
        rounds: newRounds,
      },
      () => {
        this.updateRounds();
        if (this.state.match === matchIndex) {
          this.handleMatchSelect(matchIndex);
        }
      }
    );
  };

  isMatchValid = (matchIndex) => {
    const match = this.state.rounds[this.state.round].matches[matchIndex];
    if (
      match &&
      match.datetime &&
      moment(match.datetime, "YYYY-MM-DDThh:mm") &&
      match.team200 &&
      Object.values(match.team200).length &&
      match.team100 &&
      Object.values(match.team100).length
    ) {
      return true;
    }
    return false;
  };

  updateRounds = () => {
    window.MainData.schedule.rounds = this.state.rounds;
  };

  updateSchedule = () => {
    window.MainData.schedule.league = this.state.league;
    window.MainData.schedule.season = this.state.season;
    window.MainData.schedule.split = this.state.split;
    window.MainData.schedule.round = this.state.round;
    window.MainData.schedule.match = this.state.match;
    this.props.updateSchedule();
  };

  createNewMatch = () => {
    let newRounds = cloneDeep(window.MainData.schedule.rounds);
    newRounds[this.state.round].matches.push({
      hide: true,
      // name: 'TBD vs TBD',
      value: newRounds[this.state.round].matches.length,
      datetime: moment().format("YYYY-MM-DDThh:mm"),
      isCustom: true,
      bestOf: 3,
    });
    this.setState(
      {
        rounds: newRounds,
      },
      () => {
        this.updateRounds();
      }
    );
  };

  render() {
    const rounds = this.state.rounds.map((round, i) => {
      return { name: round.name, value: i };
    });
    const matches = this.state.rounds[this.state.round]
      ? this.state.rounds[this.state.round].matches.map((match, i) => {
          const team100 =
            this.state.rounds[this.state.round].matches[i].team100;
          const team200 =
            this.state.rounds[this.state.round].matches[i].team200;
          return {
            name:
              this.state.rounds[this.state.round].matches[i].name ||
              `${team100 && team100.tricode} vs ${team200 && team200.tricode}`,
            value: i,
            datetime: this.state.rounds[this.state.round].matches[i].datetime,
            hide: match.hide,
            skip: match.skip,
            team100: team100,
            team200: team200,
            isCustom: match.isCustom,
            bestOf: match.bestOf,
          };
        })
      : [];
    const nextRoundId = Number(this.state.round) + 1;
    let matchesnext;
    if (this.state.rounds[nextRoundId]) {
      matchesnext = this.state.rounds[nextRoundId].matches.map((match, i) => {
        const team100 = this.state.rounds[nextRoundId].matches[i].team100;
        const team200 = this.state.rounds[nextRoundId].matches[i].team200;
        return {
          name:
            this.state.rounds[nextRoundId].matches[i].name ||
            `${team100 && team100.tricode} vs ${team200 && team200.tricode}`,
          value: i,
          datetime: this.state.rounds[nextRoundId].matches[i].datetime,
        };
      });
    }
    const extraHeader = `${leagueName(window.MainData.schedule.league)} ${
      window.MainData.schedule.season
    } ${splitName(
      window.MainData.schedule.league,
      window.MainData.schedule.split
    )} : ${
      window.MainData.schedule.rounds[window.MainData.schedule.round].name
    }`;

    const bestOfOptions = [
      { value: 1, name: "Best of 1" },
      { value: 3, name: "Best of 3" },
      { value: 5, name: "Best of 5" },
      { value: 7, name: "Best of 7" },
      { value: 9, name: "Best of 9" },
    ];

    return (
      <DataContainer
        componentSlug="schedule"
        extra={extraHeader}
        icon="fas fa-calendar-alt"
      >
        <div className="data-panel__fieldset">
          <label className="data-panel__fieldset__label">
            {i18n().general.round}
          </label>
          <div className="data-panel__fieldset__body">
            <div className="pill">
              <span>{i18n().general.round}</span>
              <Picker
                items={rounds}
                onUpdate={this.handleRoundSelect}
                preselect={String(this.state.round)}
              />
              <Button action={this.props.openSplitMenu} text="Change Split" />
            </div>
          </div>
        </div>
        <div className="data-panel__fieldset">
          <label className="data-panel__fieldset__label">
            {i18n().general.roundMatches}
            <br />
            {this.state.rounds[this.state.round].name}
          </label>
          <div className="data-panel__fieldset__body">
            {matches.map((match, i) => {
              return (
                <div key={i}>
                  <div
                    className={`pill ${
                      this.state.match === Number(i) ? "pill--outlined" : ""
                    } ${match.hide ? "pill--disabled" : ""}`}
                  >
                    <Button
                      action={() => !match.hide && this.handleMatchSelect(i)}
                      text="→"
                      disabled={this.state.match === Number(i)}
                      style={{
                        backgroundColor: match.skip ? "#dd3217" : "#076FC1",
                      }}
                    />
                    <span style={{ backgroundColor: "#F8F8F8" }}>
                      #{match.value}
                    </span>
                    <Picker
                      error={
                        !(match.team100 && Object.values(match.team100).length)
                      }
                      items={Object.keys(this.state.teams).map((team) => ({
                        value: team.toUpperCase(),
                        name: team,
                      }))}
                      index={i}
                      onUpdate={(index, selectedTeam) => {
                        selectedTeam &&
                          this.handleTeamSelect(i, "team100", selectedTeam);
                      }}
                      preselect={
                        (match.team100 &&
                          match.team100.tricode &&
                          match.team100.tricode.toUpperCase()) ||
                        ""
                      }
                    />
                    <span style={{ backgroundColor: "#F8F8F8" }}>vs</span>
                    <Picker
                      error={
                        !(match.team200 && Object.values(match.team200).length)
                      }
                      items={Object.keys(this.state.teams).map((team) => ({
                        value: team.toUpperCase(),
                        name: team,
                      }))}
                      index={i}
                      onUpdate={(index, selectedTeam) => {
                        selectedTeam &&
                          this.handleTeamSelect(i, "team200", selectedTeam);
                      }}
                      preselect={
                        (match.team200 &&
                          match.team200.tricode &&
                          match.team200.tricode.toUpperCase()) ||
                        ""
                      }
                    />
                    <input
                      className={`input input--xx-wide input--small-text ${
                        moment(match.datetime, "YYYY-MM-DDThh:mm").isValid()
                          ? ""
                          : "error"
                      }`}
                      type="datetime-local"
                      onChange={this.handleChangeMatchTime}
                      value={moment(match.datetime).format("YYYY-MM-DDTHH:mm")}
                      data-round={this.state.round}
                      data-match={i}
                    />
                    <Picker
                      error={!match.bestOf}
                      items={bestOfOptions}
                      index={i}
                      onUpdate={this.handleBestOfSelect}
                      preselect={String(
                        this.state.rounds[this.state.round].matches[i].bestOf ||
                          3
                      )}
                    />
                    <Button
                      action={() => this.handleMatchHide(i)}
                      icon="fas fa-eye-slash"
                      className={`${match.hide ? "button--highlighted" : ""} ${
                        this.isMatchValid(i) ? "" : "button--error"
                      }`}
                    />
                  </div>
                  {(match.skip && <StyleSkip>Match Skipped</StyleSkip>) || null}
                </div>
              );
            })}
            <Button
              action={() => {
                this.createNewMatch();
              }}
              text="Add Match"
            />
          </div>
        </div>
        {this.state.rounds[Number(this.state.round) + 1] ? (
          <div className="data-panel__fieldset">
            <label className="data-panel__fieldset__label">
              {i18n().general.roundMatchesNext}
              <br />
              {this.state.rounds[Number(this.state.round) + 1].name}
            </label>
            <div className="data-panel__fieldset__body">
              {matchesnext ? (
                matchesnext.map(
                  (match, i) => (
                    <div key={i}>
                      <div className="pill">
                        <span>
                          #{match.value} {match.name}
                        </span>
                        <input
                          className="input input--xx-wide input--small-text"
                          type="datetime-local"
                          onChange={this.handleChangeMatchTime}
                          value={moment(match.datetime).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          data-round={Number(this.state.round) + 1}
                          data-match={match.value}
                        />
                      </div>
                    </div>
                  ),
                  <br />
                )
              ) : (
                <div>No next round</div>
              )}
            </div>
          </div>
        ) : (
          <div>No next round</div>
        )}
      </DataContainer>
    );
  }
}

const StyleSkip = styled.div`
  display: inline-block;
  width: 150px;
  margin: 0 20px;
  padding: 0 5px;
  text-align: center;
  border-radius: 10px;
  background-color: #cccccc;
`;

export default DataSchedule;
