// const championsList = {
//     '1':
//     {
//         id: 'Annie',
//         key: '1',
//         name: 'アニー',
//         englishName: "Annie",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/annie.jpg',
//     },
//     '2':
//     {
//         id: 'Olaf',
//         key: '2',
//         name: 'オラフ',
//         englishName: "Olaf",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/olaf.jpg',
//     },
//     '3':
//     {
//         id: 'Galio',
//         key: '3',
//         name: 'ガリオ',
//         englishName: "Galio",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/galio.jpg',
//     },
//     '4':
//     {
//         id: 'TwistedFate',
//         key: '4',
//         name: 'ツイステッド・フェイト',
//         englishName: "Twisted Fate",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/twistedfate.jpg',
//     },
//     '5':
//     {
//         id: 'XinZhao',
//         key: '5',
//         name: 'シン・ジャオ',
//         englishName: "Xin Zhao",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/xinzhao.jpg',
//     },
//     '6':
//     {
//         id: 'Urgot',
//         key: '6',
//         name: 'アーゴット',
//         englishName: "Urgot",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/urgot.jpg',
//     },
//     '7':
//     {
//         id: 'Leblanc',
//         key: '7',
//         name: 'ルブラン',
//         englishName: "LeBlanc",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/leblanc.jpg',
//     },
//     '8':
//     {
//         id: 'Vladimir',
//         key: '8',
//         name: 'ブラッドミア',
//         englishName: "Vladimir",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/vladimir.jpg',
//     },
//     '9':
//     {
//         id: 'Fiddlesticks',
//         key: '9',
//         name: 'フィドルスティックス',
//         englishName: "Fiddlesticks",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/fiddlesticks.jpg',
//     },
//     '10':
//     {
//         id: 'Kayle',
//         key: '10',
//         name: 'ケイル',
//         englishName: "Kayle",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kayle.jpg',
//     },
//     '11':
//     {
//         id: 'MasterYi',
//         key: '11',
//         name: 'マスター・イー',
//         englishName: "Master Yi",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/masteryi.jpg',
//     },
//     '12':
//     {
//         id: 'Alistar',
//         key: '12',
//         name: 'アリスター',
//         englishName: "Alistar",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/alistar.jpg',
//     },
//     '13':
//     {
//         id: 'Ryze',
//         key: '13',
//         name: 'ライズ',
//         englishName: "Ryze",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ryze.jpg',
//     },
//     '14':
//     {
//         id: 'Sion',
//         key: '14',
//         name: 'サイオン',
//         englishName: "Sion",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sion.jpg',
//     },
//     '15':
//     {
//         id: 'Sivir',
//         key: '15',
//         name: 'シヴィア',
//         englishName: "Sivir",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sivir.jpg',
//     },
//     '16':
//     {
//         id: 'Soraka',
//         key: '16',
//         name: 'ソラカ',
//         englishName: "Soraka",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/soraka.jpg',
//     },
//     '17':
//     {
//         id: 'Teemo',
//         key: '17',
//         name: 'ティーモ',
//         englishName: "Teemo",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/teemo.jpg',
//     },
//     '18':
//     {
//         id: 'Tristana',
//         key: '18',
//         name: 'トリスターナ',
//         englishName: "Tristana",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/tristana.jpg',
//     },
//     '19':
//     {
//         id: 'Warwick',
//         key: '19',
//         name: 'ワーウィック',
//         englishName: "Warwick",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/warwick.jpg',
//     },
//     '20':
//     {
//         id: 'Nunu&Willump',
//         key: '20',
//         name: 'ヌヌ＆ウィルンプ',
//         englishName: "Nunu & Willump",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nunu&wilump.jpg',
//     },
//     '21':
//     {
//         id: 'MissFortune',
//         key: '21',
//         name: 'ミス・フォーチュン',
//         englishName: "Miss Fortune",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/missfortune.jpg',
//     },
//     '22':
//     {
//         id: 'Ashe',
//         key: '22',
//         name: 'アッシュ',
//         englishName: "Ashe",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ashe.jpg',
//     },
//     '23':
//     {
//         id: 'Tryndamere',
//         key: '23',
//         name: 'トリンダメア',
//         englishName: "Tryndamere",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/tryndamere.jpg',
//     },
//     '24':
//     {
//         id: 'Jax',
//         key: '24',
//         name: 'ジャックス',
//         englishName: "Jax",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jax.jpg',
//     },
//     '25':
//     {
//         id: 'Morgana',
//         key: '25',
//         name: 'モルガナ',
//         englishName: "Morgana",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/morgana.jpg',
//     },
//     '26':
//     {
//         id: 'Zilean',
//         key: '26',
//         name: 'ジリアン',
//         englishName: "Zilean",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zilean.jpg',
//     },
//     '27':
//     {
//         id: 'Singed',
//         key: '27',
//         name: 'シンジド',
//         englishName: "Singed",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/singed.jpg',
//     },
//     '28':
//     {
//         id: 'Evelynn',
//         key: '28',
//         name: 'イブリン',
//         englishName: "Evelynn",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/evelynn.jpg',
//     },
//     '29':
//     {
//         id: 'Twitch',
//         key: '29',
//         name: 'トゥイッチ',
//         englishName: "Twitch",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/twitch.jpg',
//     },
//     '30':
//     {
//         id: 'Karthus',
//         key: '30',
//         name: 'カーサス',
//         englishName: "Karthus",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/karthus.jpg',
//     },
//     '31':
//     {
//         id: 'Chogath',
//         key: '31',
//         name: 'チョ＝ガス',
//         englishName: "Cho'Gath",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/chogath.jpg',
//     },
//     '32':
//     {
//         id: 'Amumu',
//         key: '32',
//         name: 'アムム',
//         englishName: "Amumu",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/amumu.jpg',
//     },
//     '33':
//     {
//         id: 'Rammus',
//         key: '33',
//         name: 'ラムス',
//         englishName: "Rammus",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rammus.jpg',
//     },
//     '34':
//     {
//         id: 'Anivia',
//         key: '34',
//         name: 'アニビア',
//         englishName: "Anivia",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/anivia.jpg',
//     },
//     '35':
//     {
//         id: 'Shaco',
//         key: '35',
//         name: 'シャコ',
//         englishName: "Shaco",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/shaco.jpg',
//     },
//     '36':
//     {
//         id: 'DrMundo',
//         key: '36',
//         name: 'ドクター・ムンド',
//         englishName: "Dr. Mundo",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/drmundo.jpg',
//     },
//     '37':
//     {
//         id: 'Sona',
//         key: '37',
//         name: 'ソナ',
//         englishName: "Sona",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sona.jpg',
//     },
//     '38':
//     {
//         id: 'Kassadin',
//         key: '38',
//         name: 'カサディン',
//         englishName: "Kassadin",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kassadin.jpg',
//     },
//     '39':
//     {
//         id: 'Irelia',
//         key: '39',
//         name: 'イレリア',
//         englishName: "Irelia",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/irelia.jpg',
//     },
//     '40':
//     {
//         id: 'Janna',
//         key: '40',
//         name: 'ジャンナ',
//         englishName: "Janna",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/janna.jpg',
//     },
//     '41':
//     {
//         id: 'Gangplank',
//         key: '41',
//         name: 'ガングプランク',
//         englishName: "Gangplank",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gangplank.jpg',
//     },
//     '42':
//     {
//         id: 'Corki',
//         key: '42',
//         name: 'コーキ',
//         englishName: "Corki",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/corki.jpg',
//     },
//     '43':
//     {
//         id: 'Karma',
//         key: '43',
//         name: 'カルマ',
//         englishName: "Karma",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/karma.jpg',
//     },
//     '44':
//     {
//         id: 'Taric',
//         key: '44',
//         name: 'タリック',
//         englishName: "Taric",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/taric.jpg',
//     },
//     '45':
//     {
//         id: 'Veigar',
//         key: '45',
//         name: 'ベイガー',
//         englishName: "Veigar",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/veigar.jpg',
//     },
//     '48':
//     {
//         id: 'Trundle',
//         key: '48',
//         name: 'トランドル',
//         englishName: "Trundle",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/trundle.jpg',
//     },
//     '50':
//     {
//         id: 'Swain',
//         key: '50',
//         name: 'スウェイン',
//         englishName: "Swain",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/swain.jpg',
//     },
//     '51':
//     {
//         id: 'Caitlyn',
//         key: '51',
//         name: 'ケイトリン',
//         englishName: "Caitlyn",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/caitlyn.jpg',
//     },
//     '53':
//     {
//         id: 'Blitzcrank',
//         key: '53',
//         name: 'ブリッツクランク',
//         englishName: "Blitzcrank",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/blitzcrank.jpg',
//     },
//     '54':
//     {
//         id: 'Malphite',
//         key: '54',
//         name: 'マルファイト',
//         englishName: "Malphite",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/malphite.jpg',
//     },
//     '55':
//     {
//         id: 'Katarina',
//         key: '55',
//         name: 'カタリナ',
//         englishName: "Katarina",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/katarina.jpg',
//     },
//     '56':
//     {
//         id: 'Nocturne',
//         key: '56',
//         name: 'ノクターン',
//         englishName: "Nocturne",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nocturne.jpg',
//     },
//     '57':
//     {
//         id: 'Maokai',
//         key: '57',
//         name: 'マオカイ',
//         englishName: "Maokai",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/maokai.jpg',
//     },
//     '58':
//     {
//         id: 'Renekton',
//         key: '58',
//         name: 'レネクトン',
//         englishName: "Renekton",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/renekton.jpg',
//     },
//     '59':
//     {
//         id: 'JarvanIV',
//         key: '59',
//         name: 'ジャーヴァンⅣ',
//         englishName: "Jarvan IV",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jarvaniv.jpg',
//     },
//     '60':
//     {
//         id: 'Elise',
//         key: '60',
//         name: 'エリス',
//         englishName: "Elise",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/elise.jpg',
//     },
//     '61':
//     {
//         id: 'Orianna',
//         key: '61',
//         name: 'オリアナ',
//         englishName: "Orianna",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/orianna.jpg',
//     },
//     '62':
//     {
//         id: 'MonkeyKing',
//         key: '62',
//         name: 'ウーコン',
//         englishName: "Wukong",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/wukong.jpg',
//     },
//     '63':
//     {
//         id: 'Brand',
//         key: '63',
//         name: 'ブランド',
//         englishName: "Brand",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/brand.jpg',
//     },
//     '64':
//     {
//         id: 'LeeSin',
//         key: '64',
//         name: 'リー・シン',
//         englishName: "Lee Sin",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/leesin.jpg',
//     },
//     '67':
//     {
//         id: 'Vayne',
//         key: '67',
//         name: 'ヴェイン',
//         englishName: "Vayne",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/vayne.jpg',
//     },
//     '68':
//     {
//         id: 'Rumble',
//         key: '68',
//         name: 'ランブル',
//         englishName: "Rumble",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rumble.jpg',
//     },
//     '69':
//     {
//         id: 'Cassiopeia',
//         key: '69',
//         name: 'カシオペア',
//         englishName: "Cassiopeia",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/cassiopeia.jpg',
//     },
//     '72':
//     {
//         id: 'Skarner',
//         key: '72',
//         name: 'スカーナー',
//         englishName: "Skarner",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/skarner.jpg',
//     },
//     '74':
//     {
//         id: 'Heimerdinger',
//         key: '74',
//         name: 'ハイマーディンガー',
//         englishName: "Heimerdinger",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/heimerdinger.jpg',
//     },
//     '75':
//     {
//         id: 'Nasus',
//         key: '75',
//         name: 'ナサス',
//         englishName: "Nasus",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nasus.jpg',
//     },
//     '76':
//     {
//         id: 'Nidalee',
//         key: '76',
//         name: 'ニダリー',
//         englishName: "Nidalee",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nidalee.jpg',
//     },
//     '77':
//     {
//         id: 'Udyr',
//         key: '77',
//         name: 'ウディア',
//         englishName: "Udyr",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/udyr.jpg',
//     },
//     '78':
//     {
//         id: 'Poppy',
//         key: '78',
//         name: 'ポッピー',
//         englishName: "Poppy",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/poppy.jpg',
//     },
//     '79':
//     {
//         id: 'Gragas',
//         key: '79',
//         name: 'グラガス',
//         englishName: "Gragas",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gragas.jpg',
//     },
//     '80':
//     {
//         id: 'Pantheon',
//         key: '80',
//         name: 'パンテオン',
//         englishName: "Pantheon",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/pantheon.jpg',
//     },
//     '81':
//     {
//         id: 'Ezreal',
//         key: '81',
//         name: 'エズリアル',
//         englishName: "Ezreal",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ezreal.jpg',
//     },
//     '82':
//     {
//         id: 'Mordekaiser',
//         key: '82',
//         name: 'モルデカイザー',
//         englishName: "Mordekaiser",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/mordekaiser.jpg',
//     },
//     '83':
//     {
//         id: 'Yorick',
//         key: '83',
//         name: 'ヨリック',
//         englishName: "Yorick",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yorick.jpg',
//     },
//     '84':
//     {
//         id: 'Akali',
//         key: '84',
//         name: 'アカリ',
//         englishName: "Akali",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/akali.jpg',
//     },
//     '85':
//     {
//         id: 'Kennen',
//         key: '85',
//         name: 'ケネン',
//         englishName: "Kennen",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kennen.jpg',
//     },
//     '86':
//     {
//         id: 'Garen',
//         key: '86',
//         name: 'ガレン',
//         englishName: "Garen",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/garen.jpg',
//     },
//     '89':
//     {
//         id: 'Leona',
//         key: '89',
//         name: 'レオナ',
//         englishName: "Leona",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/leona.jpg',
//     },
//     '90':
//     {
//         id: 'Malzahar',
//         key: '90',
//         name: 'マルザハール',
//         englishName: "Malzahar",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/malzahar.jpg',
//     },
//     '91':
//     {
//         id: 'Talon',
//         key: '91',
//         name: 'タロン',
//         englishName: "Talon",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/talon.jpg',
//     },
//     '92':
//     {
//         id: 'Riven',
//         key: '92',
//         name: 'リヴェン',
//         englishName: "Riven",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/riven.jpg',
//     },
//     '96':
//     {
//         id: 'KogMaw',
//         key: '96',
//         name: 'コグ＝マウ',
//         englishName: "Kog'Maw",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kogmaw.jpg',
//     },
//     '98':
//     {
//         id: 'Shen',
//         key: '98',
//         name: 'シェン',
//         englishName: "Shen",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/shen.jpg',
//     },
//     '99':
//     {
//         id: 'Lux',
//         key: '99',
//         name: 'ラックス',
//         englishName: "Lux",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lux.jpg',
//     },
//     '101':
//     {
//         id: 'Xerath',
//         key: '101',
//         name: 'ゼラス',
//         englishName: "Xerath",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/xerath.jpg',
//     },
//     '102':
//     {
//         id: 'Shyvana',
//         key: '102',
//         name: 'シヴァーナ',
//         englishName: "Shyvana",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/shyvana.jpg',
//     },
//     '103':
//     {
//         id: 'Ahri',
//         key: '103',
//         name: 'アーリ',
//         englishName: "Ahri",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ahri.jpg',
//     },
//     '104':
//     {
//         id: 'Graves',
//         key: '104',
//         name: 'グレイブス',
//         englishName: "Graves",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/graves.jpg',
//     },
//     '105':
//     {
//         id: 'Fizz',
//         key: '105',
//         name: 'フィズ',
//         englishName: "Fizz",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/fizz.jpg',
//     },
//     '106':
//     {
//         id: 'Volibear',
//         key: '106',
//         name: 'ボリベア',
//         englishName: "Volibear",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/volibear.jpg',
//     },
//     '107':
//     {
//         id: 'Rengar',
//         key: '107',
//         name: 'レンガー',
//         englishName: "Rengar",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rengar.jpg',
//     },
//     '110':
//     {
//         id: 'Varus',
//         key: '110',
//         name: 'ヴァルス',
//         englishName: "Varus",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/varus.jpg',
//     },
//     '111':
//     {
//         id: 'Nautilus',
//         key: '111',
//         name: 'ノーチラス',
//         englishName: "Nautilus",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nautilus.jpg',
//     },
//     '112':
//     {
//         id: 'Viktor',
//         key: '112',
//         name: 'ビクター',
//         englishName: "Viktor",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/viktor.jpg',
//         passiveInfoBoxAsset: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/in-game_tips/viktor-passive_infobox.png',
//     },
//     '113':
//     {
//         id: 'Sejuani',
//         key: '113',
//         name: 'セジュアニ',
//         englishName: "Sejuani",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sejuani.jpg',
//     },
//     '114':
//     {
//         id: 'Fiora',
//         key: '114',
//         name: 'フィオラ',
//         englishName: "Fiora",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/fiora.jpg',
//     },
//     '115':
//     {
//         id: 'Ziggs',
//         key: '115',
//         name: 'ジグス',
//         englishName: "Ziggs",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ziggs.jpg',
//     },
//     '117':
//     {
//         id: 'Lulu',
//         key: '117',
//         name: 'ルル',
//         englishName: "Lulu",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lulu.jpg',
//     },
//     '119':
//     {
//         id: 'Draven',
//         key: '119',
//         name: 'ドレイヴン',
//         englishName: "Draven",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/draven.jpg',
//     },
//     '120':
//     {
//         id: 'Hecarim',
//         key: '120',
//         name: 'ヘカリム',
//         englishName: "Hecarim",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/hecarim.jpg',
//     },
//     '121':
//     {
//         id: 'Khazix',
//         key: '121',
//         name: 'カ＝ジックス',
//         englishName: "Kha'Zix",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/khazix.jpg',
//     },
//     '122':
//     {
//         id: 'Darius',
//         key: '122',
//         name: 'ダリウス',
//         englishName: "Darius",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/darius.jpg',
//     },
//     '126':
//     {
//         id: 'Jayce',
//         key: '126',
//         name: 'ジェイス',
//         englishName: "Jayce",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jayce.jpg',
//     },
//     '127':
//     {
//         id: 'Lissandra',
//         key: '127',
//         name: 'リサンドラ',
//         englishName: "Lissandra",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lissandra.jpg',
//     },
//     '131':
//     {
//         id: 'Diana',
//         key: '131',
//         name: 'ダイアナ',
//         englishName: "Diana",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/diana.jpg',
//     },
//     '133':
//     {
//         id: 'Quinn',
//         key: '133',
//         name: 'クイン',
//         englishName: "Quinn",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/quinn.jpg',
//     },
//     '134':
//     {
//         id: 'Syndra',
//         key: '134',
//         name: 'シンドラ',
//         englishName: "Syndra",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/syndra.jpg',
//     },
//     '136':
//     {
//         id: 'AurelionSol',
//         key: '136',
//         name: 'オレリオン・ソル',
//         englishName: "Aurelion Sol",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/aurelionsol.jpg',
//     },
//     '141':
//     {
//         id: 'Kayn',
//         key: '141',
//         name: 'ケイン',
//         englishName: "Kayn",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kayn.jpg',
//         passiveInfoBoxAsset: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/in-game_tips/kayn-passive_infobox.png',
//     },
//     '142':
//     {
//         id: 'Zoe',
//         key: '142',
//         name: 'ゾーイ',
//         englishName: "Zoe",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zoe.jpg',
//     },
//     '143':
//     {
//         id: 'Zyra',
//         key: '143',
//         name: 'ザイラ',
//         englishName: "Zyra",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zyra.jpg',
//     },
//     '145':
//     {
//         id: 'Kaisa',
//         key: '145',
//         name: 'カイ=サ',
//         englishName: "Kai'Sa",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kaisa.jpg',
//         passiveInfoBoxAsset: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/in-game_tips/kaisa-passive_infobox.png',
//     },
//     '147':
//     {
//         id: 'Seraphine',
//         key: '147',
//         name: 'セラフィーン',
//         englishName: "Seraphine",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/seraphine.jpg',
//     },
//     '150':
//     {
//         id: 'Gnar',
//         key: '150',
//         name: 'ナー',
//         englishName: "Gnar",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gnar.jpg',
//     },
//     '154':
//     {
//         id: 'Zac',
//         key: '154',
//         name: 'ザック',
//         englishName: "Zac",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zac.jpg',
//     },
//     '157':
//     {
//         id: 'Yasuo',
//         key: '157',
//         name: 'ヤスオ',
//         englishName: "Yasuo",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yasuo.jpg',
//     },
//     '161':
//     {
//         id: 'Velkoz',
//         key: '161',
//         name: 'ヴェル＝コズ',
//         englishName: "Vel'Koz",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/velkoz.jpg',
//     },
//     '163':
//     {
//         id: 'Taliyah',
//         key: '163',
//         name: 'タリヤ',
//         englishName: "Taliyah",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/taliyah.jpg',
//     },
//     '164':
//     {
//         id: 'Camille',
//         key: '164',
//         name: 'カミール',
//         englishName: "Camille",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/camille.jpg',
//     },
//     '201':
//     {
//         id: 'Braum',
//         key: '201',
//         name: 'ブラウム',
//         englishName: "Braum",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/braum.jpg',
//     },
//     '202':
//     {
//         id: 'Jhin',
//         key: '202',
//         name: 'ジン',
//         englishName: "Jhin",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jhin.jpg',
//     },
//     '203':
//     {
//         id: 'Kindred',
//         key: '203',
//         name: 'キンドレッド',
//         englishName: "Kindred",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kindred.jpg',
//         passiveInfoBoxAsset: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/in-game_tips/kindred-passive_infobox.png',
//     },
//     '222':
//     {
//         id: 'Jinx',
//         key: '222',
//         name: 'ジンクス',
//         englishName: "Jinx",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jinx.jpg',
//     },
//     '223':
//     {
//         id: 'TahmKench',
//         key: '223',
//         name: 'タム・ケンチ',
//         englishName: "Tahm Kench",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/tahmkench.jpg',
//     },
//     '234':
//     {
//         id: 'Viego',
//         key: '234',
//         name: 'ヴィエゴ',
//         englishName: "Viego",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/viego.jpg',
//     },
//     '235':
//     {
//         id: 'Senna',
//         key: '235',
//         name: 'セナ',
//         englishName: "Senna",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/senna.jpg',
//         passiveInfoBoxAsset: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/game-overlay/infobox/in-game_tips/senna-passive_infobox.png',
//     },
//     '236':
//     {
//         id: 'Lucian',
//         key: '236',
//         name: 'ルシアン',
//         englishName: "Lucian",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lucian.jpg',
//     },
//     '238':
//     {
//         id: 'Zed',
//         key: '238',
//         name: 'ゼド',
//         englishName: "Zed",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zed.jpg',
//     },
//     '240':
//     {
//         id: 'Kled',
//         key: '240',
//         name: 'クレッド',
//         englishName: "Kled",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kled.jpg',
//     },
//     '245':
//     {
//         id: 'Ekko',
//         key: '245',
//         name: 'エコー',
//         englishName: "Ekko",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ekko.jpg',
//     },
//     '246':
//     {
//         id: 'Qiyana',
//         key: '246',
//         name: 'キヤナ',
//         englishName: "Qiyana",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/qiyana.jpg',
//     },
//     '254':
//     {
//         id: 'Vi',
//         key: '254',
//         name: 'ヴァイ',
//         englishName: "Vi",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/vi.jpg',
//     },
//     '266':
//     {
//         id: 'Aatrox',
//         key: '266',
//         name: 'エイトロックス',
//         englishName: "Aatrox",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/aatrox.jpg',
//     },
//     '267':
//     {
//         id: 'Nami',
//         key: '267',
//         name: 'ナミ',
//         englishName: "Nami",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nami.jpg',
//     },
//     '268':
//     {
//         id: 'Azir',
//         key: '268',
//         name: 'アジール',
//         englishName: "Azir",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/azir.jpg',
//     },
//     '350':
//     {
//         id: 'Yuumi',
//         key: '350',
//         name: 'ユーミ',
//         englishName: "Yuumi",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yuumi.jpg',
//     },
//     '360':
//     {
//         id: 'Samira',
//         key: '360',
//         name: 'サミーラ',
//         englishName: "Samira",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/samira.jpg',
//     },
//     '412':
//     {
//         id: 'Thresh',
//         key: '412',
//         name: 'スレッシュ',
//         englishName: "Thresh",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/thresh.jpg',
//     },
//     '420':
//     {
//         id: 'Illaoi',
//         key: '420',
//         name: 'イラオイ',
//         englishName: "Illaoi",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/illaoi.jpg',
//     },
//     '421':
//     {
//         id: 'RekSai',
//         key: '421',
//         name: 'レク＝サイ',
//         englishName: "Rek'Sai",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/reksai.jpg',
//     },
//     '427':
//     {
//         id: 'Ivern',
//         key: '427',
//         name: 'アイバーン',
//         englishName: "Ivern",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ivern.jpg',
//     },
//     '429':
//     {
//         id: 'Kalista',
//         key: '429',
//         name: 'カリスタ',
//         englishName: "Kalista",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kalista.jpg',
//     },
//     '432':
//     {
//         id: 'Bard',
//         key: '432',
//         name: 'バード',
//         englishName: "Bard",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/bard.jpg',
//     },
//     '497':
//     {
//         id: 'Rakan',
//         key: '497',
//         name: 'ラカン',
//         englishName: "Rakan",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rakan.jpg',
//     },
//     '498':
//     {
//         id: 'Xayah',
//         key: '498',
//         name: 'ザヤ',
//         englishName: "Xayah",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/xayah.jpg',
//     },
//     '516':
//     {
//         id: 'Ornn',
//         key: '516',
//         name: 'オーン',
//         englishName: "Ornn",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ornn.jpg',
//     },
//     '517':
//     {
//         id: 'Sylas',
//         key: '517',
//         name: 'サイラス',
//         englishName: "Sylas",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sylas.jpg',
//     },
//     '518':
//     {
//         id: 'Neeko',
//         key: '518',
//         name: 'ニーコ',
//         englishName: "Neeko",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/neeko.jpg',
//     },
//     '523':
//     {
//         id: 'Aphelios',
//         key: '523',
//         name: 'アフェリオス',
//         englishName: "Aphelios",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/aphelios.jpg',
//     },
//     '526':
//     {
//         id: 'Rell',
//         key: '526',
//         name: 'レル',
//         englishName: "Rell",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rell.jpg',
//     },
//     '555':
//     {
//         id: 'Pyke',
//         key: '555',
//         name: 'パイク',
//         englishName: "Pyke",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/pyke.jpg',
//     },
//     '777':
//     {
//         id: 'Yone',
//         key: '777',
//         name: 'ヨネ',
//         englishName: "Yone",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yone.jpg',
//     },
//     '875':
//     {
//         id: 'Sett',
//         key: '875',
//         name: 'セト',
//         englishName: "Sett",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sett.jpg',
//     },
//     '876':
//     {
//         id: 'Lillia',
//         key: '876',
//         name: 'リリア',
//         englishName: "Lillia",
//         imageLarge: 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lillia.jpg',
//     },
//     '887': {
//         id: "Gwen",
//         key: "887",
//         name: "グウェン",
//         englishName: "Gwen",
//         imageSquare: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Gwen.png",
//         imageLarge: "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gwen.jpg",
//     },
// }
const championsList = {
  1: {
    id: "Annie",
    key: "1",
    englishName: "Annie",
    name: "アニー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/annie.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Annie.png",
  },
  10: {
    id: "Kayle",
    key: "10",
    englishName: "Kayle",
    name: "ケイル",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kayle.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kayle.png",
  },
  101: {
    id: "Xerath",
    key: "101",
    englishName: "Xerath",
    name: "ゼラス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/xerath.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Xerath.png",
  },
  102: {
    id: "Shyvana",
    key: "102",
    englishName: "Shyvana",
    name: "シヴァーナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/shyvana.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Shyvana.png",
  },
  103: {
    id: "Ahri",
    key: "103",
    englishName: "Ahri",
    name: "アーリ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ahri.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ahri.png",
  },
  104: {
    id: "Graves",
    key: "104",
    englishName: "Graves",
    name: "グレイブス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/graves.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Graves.png",
  },
  105: {
    id: "Fizz",
    key: "105",
    englishName: "Fizz",
    name: "フィズ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/fizz.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Fizz.png",
  },
  106: {
    id: "Volibear",
    key: "106",
    englishName: "Volibear",
    name: "ボリベア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/volibear.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Volibear.png",
  },
  107: {
    id: "Rengar",
    key: "107",
    englishName: "Rengar",
    name: "レンガー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rengar.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Rengar.png",
  },
  11: {
    id: "MasterYi",
    key: "11",
    englishName: "Master Yi",
    name: "マスター・イー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/masteryi.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Master Yi.png",
  },
  110: {
    id: "Varus",
    key: "110",
    englishName: "Varus",
    name: "ヴァルス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/varus.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Varus.png",
  },
  111: {
    id: "Nautilus",
    key: "111",
    englishName: "Nautilus",
    name: "ノーチラス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nautilus.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Nautilus.png",
  },
  112: {
    id: "Viktor",
    key: "112",
    englishName: "Viktor",
    name: "ビクター",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/viktor.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Viktor.png",
  },
  113: {
    id: "Sejuani",
    key: "113",
    englishName: "Sejuani",
    name: "セジュアニ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sejuani.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Sejuani.png",
  },
  114: {
    id: "Fiora",
    key: "114",
    englishName: "Fiora",
    name: "フィオラ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/fiora.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Fiora.png",
  },
  115: {
    id: "Ziggs",
    key: "115",
    englishName: "Ziggs",
    name: "ジグス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ziggs.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ziggs.png",
  },
  117: {
    id: "Lulu",
    key: "117",
    englishName: "Lulu",
    name: "ルル",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lulu.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Lulu.png",
  },
  119: {
    id: "Draven",
    key: "119",
    englishName: "Draven",
    name: "ドレイヴン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/draven.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Draven.png",
  },
  12: {
    id: "Alistar",
    key: "12",
    englishName: "Alistar",
    name: "アリスター",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/alistar.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Alistar.png",
  },
  120: {
    id: "Hecarim",
    key: "120",
    englishName: "Hecarim",
    name: "ヘカリム",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/hecarim.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Hecarim.png",
  },
  121: {
    id: "Khazix",
    key: "121",
    englishName: "Kha'Zix",
    name: "カ＝ジックス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/khazix.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Khazix.png",
  },
  122: {
    id: "Darius",
    key: "122",
    englishName: "Darius",
    name: "ダリウス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/darius.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Darius.png",
  },
  126: {
    id: "Jayce",
    key: "126",
    englishName: "Jayce",
    name: "ジェイス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jayce.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Jayce.png",
  },
  127: {
    id: "Lissandra",
    key: "127",
    englishName: "Lissandra",
    name: "リサンドラ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lissandra.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Lissandra.png",
  },
  13: {
    id: "Ryze",
    key: "13",
    englishName: "Ryze",
    name: "ライズ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ryze.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ryze.png",
  },
  131: {
    id: "Diana",
    key: "131",
    englishName: "Diana",
    name: "ダイアナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/diana.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Diana.png",
  },
  133: {
    id: "Quinn",
    key: "133",
    englishName: "Quinn",
    name: "クイン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/quinn.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Quinn.png",
  },
  134: {
    id: "Syndra",
    key: "134",
    englishName: "Syndra",
    name: "シンドラ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/syndra.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Syndra.png",
  },
  136: {
    id: "AurelionSol",
    key: "136",
    englishName: "Aurelion Sol",
    name: "オレリオン・ソル",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/aurelionsol.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Aurelion Sol.png",
  },
  14: {
    id: "Sion",
    key: "14",
    englishName: "Sion",
    name: "サイオン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sion.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Sion.png",
  },
  141: {
    id: "Kayn",
    key: "141",
    englishName: "Kayn",
    name: "ケイン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kayn.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kayn.png",
  },
  142: {
    id: "Zoe",
    key: "142",
    englishName: "Zoe",
    name: "ゾーイ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zoe.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Zoe.png",
  },
  143: {
    id: "Zyra",
    key: "143",
    englishName: "Zyra",
    name: "ザイラ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zyra.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Zyra.png",
  },
  145: {
    id: "Kaisa",
    key: "145",
    englishName: "Kai'Sa",
    name: "カイ＝サ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kaisa.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kaisa.png",
  },
  147: {
    id: "Seraphine",
    key: "147",
    englishName: "Seraphine",
    name: "セラフィーン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/seraphine.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Seraphine.png",
  },
  15: {
    id: "Sivir",
    key: "15",
    englishName: "Sivir",
    name: "シヴィア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sivir.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Sivir.png",
  },
  150: {
    id: "Gnar",
    key: "150",
    englishName: "Gnar",
    name: "ナー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gnar.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Gnar.png",
  },
  154: {
    id: "Zac",
    key: "154",
    englishName: "Zac",
    name: "ザック",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zac.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Zac.png",
  },
  157: {
    id: "Yasuo",
    key: "157",
    englishName: "Yasuo",
    name: "ヤスオ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yasuo.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Yasuo.png",
  },
  16: {
    id: "Soraka",
    key: "16",
    englishName: "Soraka",
    name: "ソラカ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/soraka.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Soraka.png",
  },
  161: {
    id: "Velkoz",
    key: "161",
    englishName: "Vel'Koz",
    name: "ヴェル＝コズ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/velkoz.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Velkoz.png",
  },
  163: {
    id: "Taliyah",
    key: "163",
    englishName: "Taliyah",
    name: "タリヤ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/taliyah.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Taliyah.png",
  },
  164: {
    id: "Camille",
    key: "164",
    englishName: "Camille",
    name: "カミール",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/camille.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Camille.png",
  },
  166: {
    id: "Akshan",
    key: "166",
    englishName: "Akshan",
    name: "アクシャン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/akshan.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Akshan.png",
  },
  17: {
    id: "Teemo",
    key: "17",
    englishName: "Teemo",
    name: "ティーモ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/teemo.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Teemo.png",
  },
  18: {
    id: "Tristana",
    key: "18",
    englishName: "Tristana",
    name: "トリスターナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/tristana.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Tristana.png",
  },
  19: {
    id: "Warwick",
    key: "19",
    englishName: "Warwick",
    name: "ワーウィック",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/warwick.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Warwick.png",
  },
  2: {
    id: "Olaf",
    key: "2",
    englishName: "Olaf",
    name: "オラフ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/olaf.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Olaf.png",
  },
  20: {
    id: "Nunu",
    key: "20",
    englishName: "Nunu & Willump",
    name: "ヌヌ＆ウィルンプ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nunu.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Nunu & Willump.png",
  },
  200: {
    id: "Belveth",
    key: "200",
    englishName: "Bel'Veth",
    name: "ベル＝ヴェス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/belveth.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Belveth.png",
  },
  201: {
    id: "Braum",
    key: "201",
    englishName: "Braum",
    name: "ブラウム",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/braum.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Braum.png",
  },
  202: {
    id: "Jhin",
    key: "202",
    englishName: "Jhin",
    name: "ジン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jhin.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Jhin.png",
  },
  203: {
    id: "Kindred",
    key: "203",
    englishName: "Kindred",
    name: "キンドレッド",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kindred.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kindred.png",
  },
  21: {
    id: "MissFortune",
    key: "21",
    englishName: "Miss Fortune",
    name: "ミス・フォーチュン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/missfortune.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Miss Fortune.png",
  },
  22: {
    id: "Ashe",
    key: "22",
    englishName: "Ashe",
    name: "アッシュ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ashe.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ashe.png",
  },
  221: {
    id: "Zeri",
    key: "221",
    englishName: "Zeri",
    name: "ゼリ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zeri.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Zeri.png",
  },
  222: {
    id: "Jinx",
    key: "222",
    englishName: "Jinx",
    name: "ジンクス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jinx.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Jinx.png",
  },
  223: {
    id: "TahmKench",
    key: "223",
    englishName: "Tahm Kench",
    name: "タム・ケンチ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/tahmkench.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Tahm Kench.png",
  },
  23: {
    id: "Tryndamere",
    key: "23",
    englishName: "Tryndamere",
    name: "トリンダメア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/tryndamere.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Tryndamere.png",
  },
  233: {
    id: "Briar",
    key: "233",
    englishName: "Briar",
    name: "ブライアー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/briar.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Briar.png",
  },
  234: {
    id: "Viego",
    key: "234",
    englishName: "Viego",
    name: "ヴィエゴ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/viego.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Viego.png",
  },
  235: {
    id: "Senna",
    key: "235",
    englishName: "Senna",
    name: "セナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/senna.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Senna.png",
  },
  236: {
    id: "Lucian",
    key: "236",
    englishName: "Lucian",
    name: "ルシアン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lucian.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Lucian.png",
  },
  238: {
    id: "Zed",
    key: "238",
    englishName: "Zed",
    name: "ゼド",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zed.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Zed.png",
  },
  24: {
    id: "Jax",
    key: "24",
    englishName: "Jax",
    name: "ジャックス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jax.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Jax.png",
  },
  240: {
    id: "Kled",
    key: "240",
    englishName: "Kled",
    name: "クレッド",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kled.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kled.png",
  },
  245: {
    id: "Ekko",
    key: "245",
    englishName: "Ekko",
    name: "エコー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ekko.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ekko.png",
  },
  246: {
    id: "Qiyana",
    key: "246",
    englishName: "Qiyana",
    name: "キヤナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/qiyana.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Qiyana.png",
  },
  25: {
    id: "Morgana",
    key: "25",
    englishName: "Morgana",
    name: "モルガナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/morgana.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Morgana.png",
  },
  254: {
    id: "Vi",
    key: "254",
    englishName: "Vi",
    name: "ヴァイ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/vi.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Vi.png",
  },
  26: {
    id: "Zilean",
    key: "26",
    englishName: "Zilean",
    name: "ジリアン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/zilean.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Zilean.png",
  },
  266: {
    id: "Aatrox",
    key: "266",
    englishName: "Aatrox",
    name: "エイトロックス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/aatrox.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Aatrox.png",
  },
  267: {
    id: "Nami",
    key: "267",
    englishName: "Nami",
    name: "ナミ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nami.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Nami.png",
  },
  268: {
    id: "Azir",
    key: "268",
    englishName: "Azir",
    name: "アジール",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/azir.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Azir.png",
  },
  27: {
    id: "Singed",
    key: "27",
    englishName: "Singed",
    name: "シンジド",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/singed.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Singed.png",
  },
  28: {
    id: "Evelynn",
    key: "28",
    englishName: "Evelynn",
    name: "イブリン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/evelynn.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Evelynn.png",
  },
  29: {
    id: "Twitch",
    key: "29",
    englishName: "Twitch",
    name: "トゥイッチ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/twitch.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Twitch.png",
  },
  3: {
    id: "Galio",
    key: "3",
    englishName: "Galio",
    name: "ガリオ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/galio.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Galio.png",
  },
  30: {
    id: "Karthus",
    key: "30",
    englishName: "Karthus",
    name: "カーサス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/karthus.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Karthus.png",
  },
  31: {
    id: "Chogath",
    key: "31",
    englishName: "Cho'Gath",
    name: "チョ＝ガス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/chogath.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Chogath.png",
  },
  32: {
    id: "Amumu",
    key: "32",
    englishName: "Amumu",
    name: "アムム",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/amumu.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Amumu.png",
  },
  33: {
    id: "Rammus",
    key: "33",
    englishName: "Rammus",
    name: "ラムス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rammus.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Rammus.png",
  },
  34: {
    id: "Anivia",
    key: "34",
    englishName: "Anivia",
    name: "アニビア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/anivia.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Anivia.png",
  },
  35: {
    id: "Shaco",
    key: "35",
    englishName: "Shaco",
    name: "シャコ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/shaco.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Shaco.png",
  },
  350: {
    id: "Yuumi",
    key: "350",
    englishName: "Yuumi",
    name: "ユーミ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yuumi.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Yuumi.png",
  },
  36: {
    id: "DrMundo",
    key: "36",
    englishName: "Dr. Mundo",
    name: "ドクター・ムンド",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/drmundo.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Dr. Mundo.png",
  },
  360: {
    id: "Samira",
    key: "360",
    englishName: "Samira",
    name: "サミーラ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/samira.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Samira.png",
  },
  37: {
    id: "Sona",
    key: "37",
    englishName: "Sona",
    name: "ソナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sona.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Sona.png",
  },
  38: {
    id: "Kassadin",
    key: "38",
    englishName: "Kassadin",
    name: "カサディン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kassadin.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kassadin.png",
  },
  39: {
    id: "Irelia",
    key: "39",
    englishName: "Irelia",
    name: "イレリア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/irelia.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Irelia.png",
  },
  4: {
    id: "TwistedFate",
    key: "4",
    englishName: "Twisted Fate",
    name: "ツイステッド・フェイト",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/twistedfate.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Twisted Fate.png",
  },
  40: {
    id: "Janna",
    key: "40",
    englishName: "Janna",
    name: "ジャンナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/janna.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Janna.png",
  },
  41: {
    id: "Gangplank",
    key: "41",
    englishName: "Gangplank",
    name: "ガングプランク",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gangplank.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Gangplank.png",
  },
  412: {
    id: "Thresh",
    key: "412",
    englishName: "Thresh",
    name: "スレッシュ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/thresh.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Thresh.png",
  },
  42: {
    id: "Corki",
    key: "42",
    englishName: "Corki",
    name: "コーキ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/corki.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Corki.png",
  },
  420: {
    id: "Illaoi",
    key: "420",
    englishName: "Illaoi",
    name: "イラオイ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/illaoi.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Illaoi.png",
  },
  421: {
    id: "RekSai",
    key: "421",
    englishName: "Rek'Sai",
    name: "レク＝サイ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/reksai.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/RekSai.png",
  },
  427: {
    id: "Ivern",
    key: "427",
    englishName: "Ivern",
    name: "アイバーン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ivern.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ivern.png",
  },
  429: {
    id: "Kalista",
    key: "429",
    englishName: "Kalista",
    name: "カリスタ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kalista.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kalista.png",
  },
  43: {
    id: "Karma",
    key: "43",
    englishName: "Karma",
    name: "カルマ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/karma.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Karma.png",
  },
  432: {
    id: "Bard",
    key: "432",
    englishName: "Bard",
    name: "バード",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/bard.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Bard.png",
  },
  44: {
    id: "Taric",
    key: "44",
    englishName: "Taric",
    name: "タリック",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/taric.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Taric.png",
  },
  45: {
    id: "Veigar",
    key: "45",
    englishName: "Veigar",
    name: "ベイガー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/veigar.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Veigar.png",
  },
  48: {
    id: "Trundle",
    key: "48",
    englishName: "Trundle",
    name: "トランドル",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/trundle.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Trundle.png",
  },
  497: {
    id: "Rakan",
    key: "497",
    englishName: "Rakan",
    name: "ラカン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rakan.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Rakan.png",
  },
  498: {
    id: "Xayah",
    key: "498",
    englishName: "Xayah",
    name: "ザヤ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/xayah.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Xayah.png",
  },
  5: {
    id: "XinZhao",
    key: "5",
    englishName: "Xin Zhao",
    name: "シン・ジャオ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/xinzhao.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Xin Zhao.png",
  },
  50: {
    id: "Swain",
    key: "50",
    englishName: "Swain",
    name: "スウェイン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/swain.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Swain.png",
  },
  51: {
    id: "Caitlyn",
    key: "51",
    englishName: "Caitlyn",
    name: "ケイトリン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/caitlyn.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Caitlyn.png",
  },
  516: {
    id: "Ornn",
    key: "516",
    englishName: "Ornn",
    name: "オーン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ornn.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ornn.png",
  },
  517: {
    id: "Sylas",
    key: "517",
    englishName: "Sylas",
    name: "サイラス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sylas.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Sylas.png",
  },
  518: {
    id: "Neeko",
    key: "518",
    englishName: "Neeko",
    name: "ニーコ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/neeko.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Neeko.png",
  },
  523: {
    id: "Aphelios",
    key: "523",
    englishName: "Aphelios",
    name: "アフェリオス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/aphelios.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Aphelios.png",
  },
  526: {
    id: "Rell",
    key: "526",
    englishName: "Rell",
    name: "レル",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rell.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Rell.png",
  },
  53: {
    id: "Blitzcrank",
    key: "53",
    englishName: "Blitzcrank",
    name: "ブリッツクランク",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/blitzcrank.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Blitzcrank.png",
  },
  54: {
    id: "Malphite",
    key: "54",
    englishName: "Malphite",
    name: "マルファイト",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/malphite.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Malphite.png",
  },
  55: {
    id: "Katarina",
    key: "55",
    englishName: "Katarina",
    name: "カタリナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/katarina.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Katarina.png",
  },
  555: {
    id: "Pyke",
    key: "555",
    englishName: "Pyke",
    name: "パイク",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/pyke.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Pyke.png",
  },
  56: {
    id: "Nocturne",
    key: "56",
    englishName: "Nocturne",
    name: "ノクターン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nocturne.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Nocturne.png",
  },
  57: {
    id: "Maokai",
    key: "57",
    englishName: "Maokai",
    name: "マオカイ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/maokai.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Maokai.png",
  },
  58: {
    id: "Renekton",
    key: "58",
    englishName: "Renekton",
    name: "レネクトン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/renekton.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Renekton.png",
  },
  59: {
    id: "JarvanIV",
    key: "59",
    englishName: "Jarvan IV",
    name: "ジャーヴァンⅣ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/jarvaniv.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Jarvan IV.png",
  },
  6: {
    id: "Urgot",
    key: "6",
    englishName: "Urgot",
    name: "アーゴット",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/urgot.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Urgot.png",
  },
  60: {
    id: "Elise",
    key: "60",
    englishName: "Elise",
    name: "エリス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/elise.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Elise.png",
  },
  61: {
    id: "Orianna",
    key: "61",
    englishName: "Orianna",
    name: "オリアナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/orianna.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Orianna.png",
  },
  62: {
    id: "MonkeyKing",
    key: "62",
    englishName: "Wukong",
    name: "ウーコン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/monkeyking.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Wukong.png",
  },
  63: {
    id: "Brand",
    key: "63",
    englishName: "Brand",
    name: "ブランド",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/brand.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Brand.png",
  },
  64: {
    id: "LeeSin",
    key: "64",
    englishName: "Lee Sin",
    name: "リー・シン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/leesin.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Lee Sin.png",
  },
  67: {
    id: "Vayne",
    key: "67",
    englishName: "Vayne",
    name: "ヴェイン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/vayne.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Vayne.png",
  },
  68: {
    id: "Rumble",
    key: "68",
    englishName: "Rumble",
    name: "ランブル",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/rumble.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Rumble.png",
  },
  69: {
    id: "Cassiopeia",
    key: "69",
    englishName: "Cassiopeia",
    name: "カシオペア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/cassiopeia.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Cassiopeia.png",
  },
  7: {
    id: "Leblanc",
    key: "7",
    englishName: "LeBlanc",
    name: "ルブラン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/leblanc.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/LeBlanc.png",
  },
  711: {
    id: "Vex",
    key: "711",
    englishName: "Vex",
    name: "ヴェックス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/vex.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Vex.png",
  },
  72: {
    id: "Skarner",
    key: "72",
    englishName: "Skarner",
    name: "スカーナー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/skarner.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Skarner.png",
  },
  74: {
    id: "Heimerdinger",
    key: "74",
    englishName: "Heimerdinger",
    name: "ハイマーディンガー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/heimerdinger.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Heimerdinger.png",
  },
  75: {
    id: "Nasus",
    key: "75",
    englishName: "Nasus",
    name: "ナサス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nasus.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Nasus.png",
  },
  76: {
    id: "Nidalee",
    key: "76",
    englishName: "Nidalee",
    name: "ニダリー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nidalee.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Nidalee.png",
  },
  77: {
    id: "Udyr",
    key: "77",
    englishName: "Udyr",
    name: "ウディア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/udyr.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Udyr.png",
  },
  777: {
    id: "Yone",
    key: "777",
    englishName: "Yone",
    name: "ヨネ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yone.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Yone.png",
  },
  78: {
    id: "Poppy",
    key: "78",
    englishName: "Poppy",
    name: "ポッピー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/poppy.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Poppy.png",
  },
  79: {
    id: "Gragas",
    key: "79",
    englishName: "Gragas",
    name: "グラガス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gragas.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Gragas.png",
  },
  799: {
    id: "Ambessa",
    key: "799",
    englishName: "Ambessa",
    name: "アンベッサ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ambessa.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ambessa.png",
  },
  8: {
    id: "Vladimir",
    key: "8",
    englishName: "Vladimir",
    name: "ブラッドミア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/vladimir.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Vladimir.png",
  },
  80: {
    id: "Pantheon",
    key: "80",
    englishName: "Pantheon",
    name: "パンテオン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/pantheon.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Pantheon.png",
  },
  81: {
    id: "Ezreal",
    key: "81",
    englishName: "Ezreal",
    name: "エズリアル",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ezreal.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Ezreal.png",
  },
  82: {
    id: "Mordekaiser",
    key: "82",
    englishName: "Mordekaiser",
    name: "モルデカイザー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/mordekaiser.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Mordekaiser.png",
  },
  83: {
    id: "Yorick",
    key: "83",
    englishName: "Yorick",
    name: "ヨリック",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/yorick.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Yorick.png",
  },
  84: {
    id: "Akali",
    key: "84",
    englishName: "Akali",
    name: "アカリ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/akali.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Akali.png",
  },
  85: {
    id: "Kennen",
    key: "85",
    englishName: "Kennen",
    name: "ケネン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kennen.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Kennen.png",
  },
  86: {
    id: "Garen",
    key: "86",
    englishName: "Garen",
    name: "ガレン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/garen.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Garen.png",
  },
  875: {
    id: "Sett",
    key: "875",
    englishName: "Sett",
    name: "セト",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/sett.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Sett.png",
  },
  876: {
    id: "Lillia",
    key: "876",
    englishName: "Lillia",
    name: "リリア",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lillia.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Lillia.png",
  },
  887: {
    id: "Gwen",
    key: "887",
    englishName: "Gwen",
    name: "グウェン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/gwen.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Gwen.png",
  },
  888: {
    id: "Renata",
    key: "888",
    englishName: "Renata Glasc",
    name: "レナータ・グラスク",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/renata.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Renata Glasc.png",
  },
  89: {
    id: "Leona",
    key: "89",
    englishName: "Leona",
    name: "レオナ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/leona.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Leona.png",
  },
  893: {
    id: "Aurora",
    key: "893",
    englishName: "Aurora",
    name: "オーロラ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/aurora.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Aurora.png",
  },
  895: {
    id: "Nilah",
    key: "895",
    englishName: "Nilah",
    name: "ニーラ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/nilah.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Nilah.png",
  },
  897: {
    id: "KSante",
    key: "897",
    englishName: "K'Sante",
    name: "カ・サンテ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/ksante.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/KSante.png",
  },
  9: {
    id: "Fiddlesticks",
    key: "9",
    englishName: "Fiddlesticks",
    name: "フィドルスティックス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/fiddlesticks.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Fiddlesticks.png",
  },
  90: {
    id: "Malzahar",
    key: "90",
    englishName: "Malzahar",
    name: "マルザハール",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/malzahar.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Malzahar.png",
  },
  901: {
    id: "Smolder",
    key: "901",
    englishName: "Smolder",
    name: "スモルダー",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/smolder.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Smolder.png",
  },
  902: {
    id: "Milio",
    key: "902",
    englishName: "Milio",
    name: "ミリオ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/milio.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Milio.png",
  },
  91: {
    id: "Talon",
    key: "91",
    englishName: "Talon",
    name: "タロン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/talon.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Talon.png",
  },
  910: {
    id: "Hwei",
    key: "910",
    englishName: "Hwei",
    name: "フェイ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/hwei.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Hwei.png",
  },
  92: {
    id: "Riven",
    key: "92",
    englishName: "Riven",
    name: "リヴェン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/riven.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Riven.png",
  },
  950: {
    id: "Naafiri",
    key: "950",
    englishName: "Naafiri",
    name: "ナフィーリ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/naafiri.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Naafiri.png",
  },
  96: {
    id: "KogMaw",
    key: "96",
    englishName: "Kog'Maw",
    name: "コグ＝マウ",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/kogmaw.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/KogMaw.png",
  },
  98: {
    id: "Shen",
    key: "98",
    englishName: "Shen",
    name: "シェン",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/shen.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Shen.png",
  },
  99: {
    id: "Lux",
    key: "99",
    englishName: "Lux",
    name: "ラックス",
    imageLarge:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/centered/lux.jpg",
    imageSquare:
      "https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/Lux.png",
  },
};
export const getInfoboxAssetChampions = () => {
  return Object.values(championsList).filter(
    (champion) => champion.passiveInfoBoxAsset
  );
};

export default championsList;
