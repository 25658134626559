// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
// internal
// import { i18n } from "../../i18n";
import {
  StyleMainContainer,
  StyleResultGrid,
  StylePositionColumnContainer,
  StyleIsEntireGame,
  StyleSeparator,
  StyleTimeFrameContainer,
  StyleActionContainer,
} from "./styles";

import DataContainer from "../DataContainer";
import Button from "../../Button";
// import championsDict from "../../../services/championService";
import positions from "./../../../storybook-built/dist/Constants/lol_positions";

function DataDamageDealt(props) {
  const [currentGameState, setCurrentGameState] = useState();
  const [selectionState, setSelectionState] = useState({});
  const [dataResultState, setDataResultState] = useState({});
  const [isEntireGameState, setIsEntireGameState] = useState(false);

  useEffect(() => {
    const currentGame = get(
      props,
      `schedule.rounds.${props.schedule.round}.matches.${props.schedule.match}.games.${props.schedule.game}`,
      undefined
    );
    if (Object.values(currentGame.teams).length !== 2) return; // Handling TBD teams

    if (currentGame) {
      let team100 = {},
        team200 = {};
      let team100Name,
        team200Name = "";
      setDataResultState({});
      if (
        Object.values(currentGame.teams)[0].side === "100" &&
        Object.values(currentGame.teams)[1].side === "200"
      ) {
        team100Name = Object.keys(currentGame.teams)[0];
        team200Name = Object.keys(currentGame.teams)[1];
        for (let pos of positions) {
          team100[pos] = {
            ...Object.values(currentGame.teams)[0].roster[pos],
            pos,
          };
          team200[pos] = {
            ...Object.values(currentGame.teams)[1].roster[pos],
            pos,
          };
        }
      } else {
        team100Name = Object.keys(currentGame.teams)[1];
        team200Name = Object.keys(currentGame.teams)[0];
        for (let pos of positions) {
          team100[pos] = {
            ...Object.values(currentGame.teams)[1].roster[pos],
            pos,
          };
          team200[pos] = {
            ...Object.values(currentGame.teams)[0].roster[pos],
            pos,
          };
        }
      }
      // console.log("team100", team100, "team200", team200);
      setCurrentGameState({ team100Name, team100, team200Name, team200 });
    }
  }, [props.schedule]);

  useEffect(() => {
    if (Object.values(dataResultState).length) {
      window.MainData.damageDealtToChampionsData = {
        ...dataResultState,
        isEntireGame: isEntireGameState,
      };
    }
  }, [dataResultState]);

  const getPlayersDamage = async () => {
    let data;
    if (isEntireGameState) {
      data =
        window.MainData.dataApiListener.component.getPlayersDamageDealtToChampion();
    } else {
      const startTimeSec =
        Number(selectionState.startTimeSec) +
        Number(selectionState.startTimeMin) * 60;
      const endTimeSec =
        Number(selectionState.endTimeSec) +
        Number(selectionState.endTimeMin) * 60;
      data =
        window.MainData.dataApiListener.component.getPlayersDamageDealtToChampion(
          startTimeSec,
          endTimeSec
        );
    }
    // console.log("data", data);
    if (Object.values(data).length) {
      setDataResultState(data);
      return true;
    } else {
      return false;
    }
  };

  return (
    <DataContainer
      componentSlug="damageDealt"
      icon="fas fa-swords"
      style={{ overflow: "hidden" }}
    >
      <div
        className="data-panel__fieldset"
        style={{ paddingTop: "10px", paddingBottom: "0" }}
      >
        <StyleMainContainer>
          <StyleIsEntireGame>
            <div className={"pill"}>
              <input
                type={"checkbox"}
                checked={isEntireGameState}
                onChange={() => {
                  setIsEntireGameState((currentState) => !currentState);
                }}
              />
              <label className={`label`}>Entire Game</label>
            </div>
          </StyleIsEntireGame>
          <StyleTimeFrameContainer>
            <div
              className={`pill ${isEntireGameState ? "pill--disabled" : ""}`}
            >
              <span>Start Time</span>
              <input
                className={`input input--mini input--small-text`}
                type={"number"}
                value={selectionState.startTimeMin}
                min={0}
                onChange={(e) => {
                  e.persist();
                  e.target &&
                    setSelectionState((selectionState) => {
                      return {
                        ...selectionState,
                        startTimeMin: e.target.value,
                      };
                    });
                }}
              />
              <span>min</span>
              <input
                className={`input input--mini input--small-text`}
                type="number"
                value={selectionState.startTimeSec}
                min={0}
                max={59}
                onChange={(e) => {
                  e.persist();
                  e.target &&
                    setSelectionState((selectionState) => {
                      return {
                        ...selectionState,
                        startTimeSec: e.target.value,
                      };
                    });
                }}
              />
              <span>sec</span>
            </div>
            <div
              className={`pill ${isEntireGameState ? "pill--disabled" : ""}`}
            >
              <span>End Time</span>
              <input
                className={`input input--mini input--small-text`}
                type={"number"}
                value={selectionState.endTimeMin}
                min={0}
                onChange={(e) => {
                  e.persist();
                  e.target &&
                    setSelectionState((selectionState) => {
                      return { ...selectionState, endTimeMin: e.target.value };
                    });
                }}
              />
              <span>min</span>
              <input
                className={`input input--mini input--small-text`}
                type={"number"}
                value={selectionState.endTimeSec}
                min={0}
                max={59}
                onChange={(e) => {
                  e.persist();
                  e.target &&
                    setSelectionState((selectionState) => {
                      return { ...selectionState, endTimeSec: e.target.value };
                    });
                }}
              />
              <span>sec</span>
            </div>
          </StyleTimeFrameContainer>

          {(currentGameState && Object.values(dataResultState).length && (
            <StyleResultGrid>
              <div style={{ backgroundColor: "#0078ff" }}>
                <h3 style={{ backgroundColor: "#46adff" }}>
                  {currentGameState.team100Name}
                </h3>
              </div>
              <div />
              <div style={{ backgroundColor: "#dd3217" }}>
                <h3 style={{ backgroundColor: "#ff5745" }}>
                  {currentGameState.team200Name}
                </h3>
              </div>
              {positions.map((pos, index) => {
                return [
                  <p key={`damageTeam100${pos}`}>
                    {get(
                      dataResultState,
                      `${currentGameState.team100Name}.${pos}.damageDealtToChampions`,
                      0
                    )}
                  </p>,
                  <StylePositionColumnContainer key={`damageIcon${pos}`}>
                    <img
                      src={`https://cdn.dekki.com/assets/broadcast/games/league-of-legends/positions/black/${pos}.svg`}
                      alt={""}
                    />
                  </StylePositionColumnContainer>,
                  <p key={`damageTeam200${pos}`}>
                    {get(
                      dataResultState,
                      `${currentGameState.team200Name}.${pos}.damageDealtToChampions`,
                      0
                    )}
                  </p>,
                  index !== positions.length - 1 ? (
                    <StyleSeparator key={`separator-${pos}`} />
                  ) : null,
                ];
              })}
            </StyleResultGrid>
          )) || <p>{"no data"}</p>}
          <StyleActionContainer className={"pill"}>
            <Button
              disabled={
                !(
                  selectionState.endTimeMin !== undefined &&
                  Number.isInteger(Number(selectionState.endTimeMin)) &&
                  selectionState.endTimeSec !== undefined &&
                  Number.isInteger(Number(selectionState.endTimeSec)) &&
                  selectionState.startTimeMin !== undefined &&
                  Number.isInteger(Number(selectionState.startTimeMin)) &&
                  selectionState.startTimeSec !== undefined &&
                  Number.isInteger(Number(selectionState.startTimeSec))
                ) && !isEntireGameState
              }
              action={() => {
                getPlayersDamage();
              }}
              text="Load Data"
            />
          </StyleActionContainer>
        </StyleMainContainer>
      </div>
    </DataContainer>
  );
}

DataDamageDealt.propTypes = {
  schedule: PropTypes.object,
};

export default DataDamageDealt;
