// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// libraries
import { get, range, forEach, cloneDeep } from "lodash";
// internal
import { initialBanPick } from "../../../services/banPickLiveService";
import Button from "../../Button";
import { i18n } from "../../i18n";
import { loadImageUrlTeamLogo } from "../../../util/loadImageUrl";
import { subscribe } from "../../../services/api";
import {
  firebaseRealtimeDbSetValue,
  firebaseRealtimeGetValue,
} from "../../../util/firebase";
import {
  getPlayerStatsForPosition,
  getChampionsStats,
} from "../../../lib/allstats-sdk/api";
import DataMatchLineup from "./DataMatchLineup";
import DataMatchBans from "./DataMatchBans";
import DataContainer from "../DataContainer";
import {
  firebaseRealtimeDbUpdateStream,
  firebaseRealtimeDbUpdateAllStreams /* stopListening */,
} from "../../../util/firebase";
import { addAsset, removeChampionsAssets } from "../../../util/assetLoader";
import championData from "../../../services/championService";
import { lol_positions } from "../../../util/const";

export const playerPositions = ["top", "jungle", "mid", "adc", "support"];

// number of victory required per Match to be declared as winner
const winnerBestOf = {
  1: 1,
  3: 2,
  5: 3,
  7: 4,
  9: 5,
};
class DataMatch extends Component {
  state = {
    game: 0,
    match: 0,
    round: 0,
    winner: "",
    listeningGameLiveStats: false,
    invertBroadcastToolScreens: false,
    sideSwitched: false,
    switchedGames: [],
    callbackIds: {},
    isBanPickShowing: false,
    isBanPickV2Showing: false,
    champStatsStatus: {},
    // stopListeningFunc: () => { },
    // getApiContext: () => null,
    cleanUnlock: false,
    // match0~9 are for the switch sides, see UNSAFE_componentWillReceiveProps.
    match0: false,
    match1: false,
    match2: false,
    match3: false,
    match4: false,
    match5: false,
    match6: false,
    match7: false,
    match8: false,
    match9: false,
  };

  static propTypes = {
    schedule: PropTypes.any,
    updateSchedule: PropTypes.func,
    fetchGameId: PropTypes.func,
  };

  async componentDidMount() {
    this.fetchFireBase();
    this.buildMatchGames(this.props.schedule.round, this.props.schedule.match);
    this.updateFirebaseAllScreens(
      this.props.schedule.round,
      this.props.schedule.match,
      0
    );
    await removeChampionsAssets();
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const matchData = this.props.schedule.rounds[roundId].matches[matchId];
    if (!matchData.bestOf) matchData.bestOf = 3;
    this.runSubscription();
  }

  runSubscription = () => {
    subscribe("control-banPick", null, (data) => {
      if (data.target === "control-banPick") {
        this.setState({
          isBanPickShowing: data.status === "open",
          champStatsStatus: get(data, "data.championsStats", {}),
        });
      }
    });
    subscribe("control-banPickV2", null, (data) => {
      // champion stats
      if (data.target === "control-banPickV2") {
        this.setState({
          isBanPickV2Showing: data.status === "open",
          champStatsStatus: get(data, "data.championsStats", {}),
        });
      }
    });
    subscribe("banPick", null, (response) => {
      document.body.style.cursor = "wait";
      const newState = cloneDeep(this.state);
      // Check which ban and pick changed
      ["100", "200"].forEach((teamSide) => {
        // Picks
        lol_positions.forEach((pos) => {
          try {
            if (
              get(response, `picks[team${teamSide}][${pos}].championID`, "") !==
              newState[`current${teamSide}${pos}`]
            ) {
              // && get(response, `picks[team${teamSide}][${pos}].championID`, '') !== ''
              firebaseRealtimeDbSetValue(
                `${this.props.schedule.league}/${this.props.schedule.season}/${
                  this.props.schedule.split
                }/${
                  this.props.schedule.rounds[this.props.schedule.round].name
                }/${this.props.schedule.match}/${
                  this.props.schedule.game
                }/banPick/picks/team${teamSide}/${pos}/championID`,
                response.picks[`team${teamSide}`][pos].championID
              );
            }
          } catch (error) {
            console.log(error, "Failed Picks");
          }
        });
        // Bans
        [0, 1, 2, 3, 4].forEach((ban) => {
          try {
            if (
              get(response, `bans[team${teamSide}][${ban}]`, 0) !==
              newState[`current${teamSide}ban${ban}`]
            ) {
              // && get(response, `bans[team${teamSide}][${ban}]`, 0) !== 0
              firebaseRealtimeDbSetValue(
                `${this.props.schedule.league}/${this.props.schedule.season}/${
                  this.props.schedule.split
                }/${
                  this.props.schedule.rounds[this.props.schedule.round].name
                }/${this.props.schedule.match}/${
                  this.props.schedule.game
                }/banPick/bans/team${teamSide}/${ban}`,
                get(response, `bans[team${teamSide}][${ban}]`, 0)
              );
            }
          } catch (error) {
            console.log(error, "Failed Bans");
          }
        });
      });
      document.body.style.cursor = "default";
    });
    subscribe(
      `${this.props.schedule.league}/${this.props.schedule.season}/${this.props.schedule.split}/`,
      null,
      (res) => {
        if (res) {
          document.body.style.cursor = "wait";
          const response = get(
            res,
            `${this.props.schedule.rounds[this.props.schedule.round].name}[${
              this.props.schedule.match
            }][${this.props.schedule.game}].banPick`,
            initialBanPick
          );
          const roundId = this.props.schedule.round;
          const matchId = this.props.schedule.match;
          const teamTriCodes = {
            team100:
              this.props.schedule.rounds[roundId].matches[matchId][`team100`]
                .tricode,
            team200:
              this.props.schedule.rounds[roundId].matches[matchId][`team200`]
                .tricode,
          };
          const newState = cloneDeep(this.state);
          ["100", "200"].forEach((teamSide) => {
            // Picks
            lol_positions.forEach((pos) => {
              try {
                if (
                  get(response, `picks[team${teamSide}][${pos}].championID`) !==
                    newState[`current${teamSide}${pos}`] &&
                  get(response, `picks[team${teamSide}][${pos}].championID`) !==
                    undefined
                ) {
                  newState[`current${teamSide}${pos}`] =
                    response.picks[`team${teamSide}`][pos].championID;
                  this.setPick(
                    response.picks[`team${teamSide}`][pos].championID,
                    pos,
                    teamTriCodes[`team${this.teamSidesChanged(teamSide)}`]
                  );
                }
              } catch (error) {
                console.log(error, "Failed Picks");
              }
            });
            // Bans
            [0, 1, 2, 3, 4].forEach((ban) => {
              try {
                if (
                  get(response, `bans[team${teamSide}][${ban}]`) !==
                  newState[`current${teamSide}ban${ban}`]
                ) {
                  newState[`current${teamSide}ban${ban}`] = get(
                    response,
                    `bans[team${teamSide}][${ban}]`,
                    ""
                  );
                  this.setBan(
                    get(response, `bans[team${teamSide}][${ban}]`, ""),
                    ban,
                    teamTriCodes[`team${this.teamSidesChanged(teamSide)}`]
                  );
                }
              } catch (error) {
                console.log(error, "Failed Bans");
              }
            });
          });
          this.setState(newState);
          document.body.style.cursor = "default";
        }
      }
    );
  };

  resetFireBase() {
    if (this.state.sideSwitched) {
      const schedule = this.props.schedule;
      const roundId = this.props.schedule.round;
      const matchId = this.props.schedule.match;
      const gameId = this.state.game;
      let teamsNode =
        schedule.rounds[roundId].matches[matchId].games[gameId].teams;

      if (this.state.switchedGames.includes(gameId)) {
        var index = this.state.switchedGames.indexOf(gameId);
        this.state.switchedGames.splice(index, 1);
      }

      this.setState({ sideSwitched: false }, () => {
        firebaseRealtimeDbSetValue(
          `${this.props.schedule.league}/${this.props.schedule.season}/${
            this.props.schedule.split
          }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
            this.props.schedule.match
          }/${this.props.schedule.game}/switchedSides/`,
          false
        );
        Object.keys(teamsNode).map((team) => {
          const newSide = teamsNode[team].side === "100" ? "200" : "100";
          schedule.rounds[roundId].matches[matchId].games[gameId].teams[
            team
          ].side = newSide;
          window.MainData.schedule.rounds[roundId].matches[matchId].games[
            gameId
          ].teams[team].side = newSide;
          return null;
        });
      });
    }
    // firebaseRealtimeDbSetValue(`${this.props.schedule.league}/${this.props.schedule.season}/${this.props.schedule.split}/${this.props.schedule.rounds[this.props.schedule.round].name}/${this.props.schedule.match}/${this.props.schedule.game}/banPick/`, initialBanPick)
    firebaseRealtimeDbSetValue(`banPick/`, initialBanPick);
    firebaseRealtimeDbSetValue(
      `${this.props.schedule.league}/${this.props.schedule.season}/${
        this.props.schedule.split
      }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
        this.props.schedule.match
      }/${this.props.schedule.game}/invertBroadcastToolScreens/`,
      null
    );
    firebaseRealtimeDbSetValue(
      `${this.props.schedule.league}/${this.props.schedule.season}/${
        this.props.schedule.split
      }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
        this.props.schedule.match
      }/${this.props.schedule.game}/winner/`,
      null
    );
    this.setState({
      invertBroadcastToolScreens: false,
      sideSwitched: false,
    });
    // firebaseRealtimeDbSetValue('banPick/', initialBanPick)
    this.clearSelection();
  }
  // clearTopLevel() {
  //     firebaseRealtimeDbSetValue('banPick/', initialBanPick)
  //     this.clearSelection()
  // }
  fetchFireBase() {
    document.body.style.cursor = "wait";
    console.log("Fetching Firebase Champions");
    this.props.fetchGameId();
    firebaseRealtimeGetValue(
      `${this.props.schedule.league}/${this.props.schedule.season}/${
        this.props.schedule.split
      }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
        this.props.schedule.match
      }/${this.props.schedule.game}/`
    ).then((res) => {
      if (res) {
        const schedule = this.props.schedule;
        const roundId = this.props.schedule.round;
        const matchId = this.props.schedule.match;
        const gameId = this.state.game;

        const teamsNode =
          schedule.rounds[roundId].matches[matchId].games[gameId].teams;

        if (res.switchedSides) {
          const team100 = Object.keys(teamsNode).filter((team) => {
            if (teamsNode[team].side === "100") {
              return team;
            }
          });

          // console.log(team100[0]);
          // console.log(schedule.rounds[roundId].matches[matchId].team100.tricode)
          if (
            team100[0] ===
            schedule.rounds[roundId].matches[matchId].team100.tricode
          ) {
            Object.keys(teamsNode).map((team) => {
              const newSide = teamsNode[team].side === "100" ? "200" : "100";
              schedule.rounds[roundId].matches[matchId].games[gameId].teams[
                team
              ].side = newSide;
              window.MainData.schedule.rounds[roundId].matches[matchId].games[
                gameId
              ].teams[team].side = newSide;
            });
          }

          this.setState({ sideSwitched: true });
        } else if (!res.switchedSides) {
          const team100 = Object.keys(teamsNode).filter((team) => {
            if (teamsNode[team].side === "100") {
              return team;
            }
          });

          // console.log(team100[0]);
          // console.log(schedule.rounds[roundId].matches[matchId].team100.tricode)
          if (
            team100[0] !==
            schedule.rounds[roundId].matches[matchId].team100.tricode
          ) {
            Object.keys(teamsNode).map((team) => {
              const newSide = teamsNode[team].side === "100" ? "200" : "100";
              schedule.rounds[roundId].matches[matchId].games[gameId].teams[
                team
              ].side = newSide;
              window.MainData.schedule.rounds[roundId].matches[matchId].games[
                gameId
              ].teams[team].side = newSide;
            });
          }

          this.setState({ sideSwitched: false });
        }
        if (res.winner && res.winner !== this.state.winner) {
          this.setWinner(
            this.props.schedule.round,
            this.props.schedule.match,
            this.props.schedule.game,
            res.winner
          );
        }
        if (
          res.invertBroadcastToolScreens &&
          res.invertBroadcastToolScreens !==
            this.state.invertBroadcastToolScreens
        ) {
          this.invertBroadcastToolScreens();
        }
      }
    });
    firebaseRealtimeGetValue(
      `${this.props.schedule.league}/${this.props.schedule.season}/${
        this.props.schedule.split
      }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
        this.props.schedule.match
      }/${this.props.schedule.game}/banPick/`
    ).then(
      (res) => {
        if (!res) res = initialBanPick;
        try {
          if (!res.picks) res.picks = initialBanPick.picks;
          if (!res.picks.team100)
            res.picks.team100 = initialBanPick.picks.team100;
          if (!res.picks.team200)
            res.picks.team200 = initialBanPick.picks.team200;
          for (let index = 0; index < playerPositions.length; index++) {
            const pos = playerPositions[index];
            if (!res.picks.team100[pos]) {
              res.picks.team100[pos] = initialBanPick.picks.team100[pos];
            }
            if (!res.picks.team200[pos]) {
              res.picks.team200[pos] = initialBanPick.picks.team200[pos];
            }
          }
          if (!res.bans) res.bans = initialBanPick.bans;
          if (!res.bans.team100) res.bans.team100 = initialBanPick.bans.team100;
          if (!res.bans.team200) res.bans.team200 = initialBanPick.bans.team200;
          for (let i = 0; i < 5; i++) {
            if (!res.bans.team100[i]) res.bans.team100[i] = 0;
            if (!res.bans.team200[i]) res.bans.team200[i] = 0;
          }
          if (res) firebaseRealtimeDbSetValue("banPick/", res);

          const roundId = this.props.schedule.round;
          const matchId = this.props.schedule.match;
          const teamTriCodes = {
            team100:
              this.props.schedule.rounds[roundId].matches[matchId][`team100`]
                .tricode,
            team200:
              this.props.schedule.rounds[roundId].matches[matchId][`team200`]
                .tricode,
          };
          const newState = cloneDeep(this.state);
          ["100", "200"].forEach((teamSide) => {
            // Picks
            lol_positions.forEach((pos) => {
              try {
                if (
                  get(res, `picks[team${teamSide}][${pos}].championID`, "") !==
                  newState[`current${teamSide}${pos}`]
                ) {
                  newState[`current${teamSide}${pos}`] =
                    res.picks[`team${teamSide}`][pos].championID;
                  this.setPick(
                    res.picks[`team${teamSide}`][pos].championID,
                    pos,
                    teamTriCodes[`team${this.teamSidesChanged(teamSide)}`]
                  );
                }
              } catch (error) {
                console.log(error, "Failed Picks");
              }
            });
            // Bans
            [0, 1, 2, 3, 4].forEach((ban) => {
              try {
                if (
                  get(res, `bans[team${teamSide}][${ban}]`, "") !==
                  newState[`current${teamSide}ban${ban}`]
                ) {
                  newState[`current${teamSide}ban${ban}`] = get(
                    res,
                    `bans[team${teamSide}][${ban}]`,
                    ""
                  );
                  this.setBan(
                    get(res, `bans[team${teamSide}][${ban}]`, ""),
                    ban,
                    teamTriCodes[`team${this.teamSidesChanged(teamSide)}`]
                  );
                }
              } catch (error) {
                console.log(error, "Failed Bans");
              }
            });
          });
          this.setState(newState);

          document.body.style.cursor = "default";
        } catch (error) {
          console.log(error, "fetchFireBase Error.");
          document.body.style.cursor = "default";
        }
      },
      (err) => {
        console.log(err, "fetchFireBase err.");
        document.body.style.cursor = "default";
      }
    );
    document.body.style.cursor = "default";
  }

  teamSidesChanged(side) {
    // Deals with the change of sides
    if (!this.state.sideSwitched) {
      return side;
    } else {
      return side === "100" ? "200" : "100";
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.schedule.game !== this.state.game) {
      this.setState({
        game: nextProps.schedule.game,
      });
      this.fetchFireBase();
    }
    if (nextProps.schedule.match !== this.state.match) {
      // this is for when you change match, it fetches the data for the champions.
      this.setState({
        match: nextProps.schedule.match,
      });
      this.fetchFireBase();
    }
    if (nextProps.schedule.round !== this.state.round) {
      this.setState({
        round: nextProps.schedule.round,
      });
      this.fetchFireBase();
    }
    // if (this.state.sideSwitched !== this.state[`match${nextProps.schedule.match}`]) {
    //     // tells you if the side switch is set to true, even though it should be set to false.
    //     this.setState({ sideSwitched: !this.state.sideSwitched }, () => {
    //         window.MainData.schedule.isSideSwitched = this.state.sideSwitched;
    //     });
    // }
    const bestOf =
      nextProps.schedule.rounds[nextProps.schedule.round].matches[
        nextProps.schedule.match
      ].bestOf;
    this.buildMatchGames(nextProps.schedule.round, nextProps.schedule.match);
    if (nextProps.schedule.game !== this.state.game) {
      if (!bestOf || bestOf <= nextProps.schedule.game) {
        this.changeGame(0);
      } else {
        this.changeGame(nextProps.schedule.game);
      }
    } else {
      if (!bestOf || bestOf <= this.state.game) this.changeGame(0);
    }
    this.updateFirebaseAllScreens(
      nextProps.schedule.round,
      nextProps.schedule.match,
      this.state.game
    );
  }

  changeGame = async (gameid) => {
    const game = gameid;
    window.MainData.schedule.game = gameid;
    this.setState({ game }, this.props.updateSchedule);
    // if (this.state.sideSwitched) {
    // This if statement is because when you switch game within a match the switch side stays on
    // this.switchSides()
    // }
    const matchList = await firebaseRealtimeGetValue(
      `${this.props.schedule.league}/${this.props.schedule.season}/${
        this.props.schedule.split
      }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
        this.props.schedule.match
      }`
    );
    const newSwitchedGames = matchList?.reduce(function (list, match, i) {
      if (match.switchedSides) list.push(i);
      return list;
    }, []);
    this.setState({ switchedGames: newSwitchedGames });

    if (this.state.switchedGames?.includes(game)) {
      if (!this.state.sideSwitched) {
        this.switchSides();
      }
    } else {
      if (this.state.sideSwitched) {
        this.switchSides();
      }
    }

    await removeChampionsAssets();
    this.fetchFireBase();
  };

  invertBroadcastToolScreens = () => {
    try {
      firebaseRealtimeDbSetValue(
        `${this.props.schedule.league}/${this.props.schedule.season}/${
          this.props.schedule.split
        }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
          this.props.schedule.match
        }/${this.props.schedule.game}/invertBroadcastToolScreens`,
        this.state.invertBroadcastToolScreens
      );
    } catch (error) {
      console.log(error, "Switch sides not saved");
    }
    this.setState(
      {
        invertBroadcastToolScreens: !this.state.invertBroadcastToolScreens,
      },
      () => {
        window.MainData.invertBroadcastToolScreens =
          this.state.invertBroadcastToolScreens;
        if (this.state.game !== undefined && this.state.game !== null) {
          this.updateFirebaseAllScreens(
            this.props.schedule.round,
            this.props.schedule.match,
            this.state.game
          );
        }
      }
    );
    this.props.updateSchedule();
  };

  swapChamps = (positionA, positionB, team) => {
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const gameId = this.state.game;
    const champA =
      this.props.schedule.rounds[roundId].matches[matchId].games[gameId].teams[
        team
      ].roster[positionA].championId;
    const champB =
      this.props.schedule.rounds[roundId].matches[matchId].games[gameId].teams[
        team
      ].roster[positionB].championId;
    const teamSide =
      this.props.schedule.rounds[roundId].matches[matchId].games[gameId].teams[
        team
      ].side;
    firebaseRealtimeDbSetValue(
      `banPick/picks/team${teamSide}/${positionA}/pickMode`,
      1
    );
    firebaseRealtimeDbSetValue(
      `banPick/picks/team${teamSide}/${positionB}/pickMode`,
      1
    );
    console.log(champA, "swapping this champion");
    this.setPick(champB, positionA, team);
    this.setPick(champA, positionB, team);
    setTimeout(() => {
      firebaseRealtimeDbSetValue(`banPick/picks/team${teamSide}/${positionA}`, {
        pickMode: 2,
        championID: champB,
      });
      firebaseRealtimeDbSetValue(`banPick/picks/team${teamSide}/${positionB}`, {
        pickMode: 2,
        championID: champA,
      });
      // This update is so the swap champions are reflected in the item purchase history.
      this.props.updateSchedule();
    }, 2000);
  };

  setPlayer = (player, position, team) => {
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const gameId = this.state.game;

    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      gameId
    ].teams[team].roster[position].name = player;
    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      gameId
    ].teams[team].roster[position].playerId = player;
    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      gameId
    ].teams[team].roster[position].value = player;

    // this.props.updateSchedule();
    this.updateBroadcastTool(position, team);
  };

  setCoach = (coach, team) => {
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const gameId = this.state.game;

    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      gameId
    ].teams[team].roster.coach = coach;

    // this.props.updateSchedule();
    this.updateBroadcastTool("coach", team);
  };

  teamSwaps = async (team) => {
    const switchSides = await firebaseRealtimeGetValue(
      `${this.props.schedule.league}/${this.props.schedule.season}/${
        this.props.schedule.split
      }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
        this.props.schedule.match
      }/${this.props.schedule.game}/switchedSides`
    );
    if (switchSides) {
      if (team === "team100") {
        return "team200";
      } else if (team === "team200") {
        return "team100";
      }
    } else {
      return team;
    }
  };

  setChamp = async (championId, pos, team) => {
    if (typeof pos !== "string") {
      pos = pos.toString();
    }
    if (typeof championId !== "string") {
      championId = championId.toString();
    }
    if (typeof team !== "string") {
      team = team.toString();
    }
    const teamTriCodes = {
      team100:
        this.props.schedule.rounds[this.props.schedule.round].matches[
          this.props.schedule.match
        ][`team100`].tricode,
      team200:
        this.props.schedule.rounds[this.props.schedule.round].matches[
          this.props.schedule.match
        ][`team200`].tricode,
    };
    if (team.substring(0, 4) !== "team") {
      team = "team" + team;
    }
    var TeamTriCode = await this.teamSwaps(team);
    if (team === "team100" || team === "team200") {
      if (
        pos === "0" ||
        pos === "1" ||
        pos === "2" ||
        pos === "3" ||
        pos === "4"
      ) {
        firebaseRealtimeDbSetValue(`banPick/bans/${team}/${pos}`, championId);
        this.setBan(championId, pos, teamTriCodes[TeamTriCode]);
      } else if (
        pos === "top" ||
        pos === "mid" ||
        pos === "adc" ||
        pos === "support" ||
        pos === "jungle"
      ) {
        // const switchSides = await firebaseRealtimeGetValue(`${this.props.schedule.league}/${this.props.schedule.season}/${this.props.schedule.split}/${this.props.schedule.rounds[this.props.schedule.round].name}/${this.props.schedule.match}/${this.props.schedule.game}/switchedSides`)
        firebaseRealtimeDbSetValue(
          `banPick/picks/${team}/${pos}/championID`,
          championId
        );
        this.setPick(championId, pos, teamTriCodes[TeamTriCode]);
      } else {
        console.log("Junk pos in setChamp");
      }
    } else {
      console.log("Junk team in setChamp");
    }
  };

  setPick = async (champ, position, team) => {
    // console.log(team, 'setPick teamCheck')
    if (!team || champ === "0") return null;
    const schedule = this.props.schedule;
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const gameId = this.state.game;
    let positionNode =
      schedule.rounds[roundId].matches[matchId].games[gameId].teams[team]
        .roster[position];

    positionNode = { ...positionNode, championId: champ };
    schedule.rounds[roundId].matches[matchId].games[gameId].teams[team].roster[
      position
    ] = positionNode;
    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      gameId
    ].teams[team].roster[position] = positionNode;
    // this.props.updateSchedule();
    this.updateBroadcastTool(position, team);
    await addAsset(get(championData, `[${champ}].imageLarge`, ""));
  };

  updateBroadcastTool = (position, team) => {
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const gameId = this.state.game;
    const schedule = this.props.schedule;
    const playerData = get(
      window,
      `MainData.teams[${team}].roster[${window.MainData.schedule.rounds[roundId].matches[matchId].games[gameId].teams[team].roster[position].playerId}]`
    );
    const teamData = window.MainData.teams[team];
    const champion =
      championData[
        schedule.rounds[roundId].matches[matchId].games[gameId].teams[team]
          .roster[position].championId
      ];
    const league = window.MainData.schedule;
    try {
      const rightTeam = league.rounds[roundId].matches[matchId].team200;

      const order = ["top", "jungle", "mid", "adc", "support"];
      let rightSideDisplay = false;
      let screenNumber = order.indexOf(position) + 1;
      if (this.state.invertBroadcastToolScreens) {
        if (rightTeam.tricode === team) {
          // right/red
          screenNumber = 5 - order.indexOf(position);
        } else {
          rightSideDisplay = true;
          screenNumber = order.indexOf(position) + 6;
        }
      } else {
        if (rightTeam.tricode === team) {
          // right/red
          screenNumber = order.indexOf(position) + 6;
          rightSideDisplay = true;
        } else {
          screenNumber = 5 - order.indexOf(position);
        }
      }

      const updateData = {
        screenNumber,
        id: screenNumber,
        position: position,
      };
      if (playerData) {
        if (playerData.imageLarge) {
          updateData.playerImage = playerData.imageLarge;
        } else {
          updateData.playerImage = null;
        }
        updateData.playerId =
          window.MainData.schedule.rounds[roundId].matches[matchId].games[
            gameId
          ].teams[team].roster[position].playerId;
      }
      if (teamData) {
        if (teamData.images) {
          if (teamData.images.logoSplash) {
            updateData.teamImage = teamData.images.logoSplash;
          } else {
            if (
              rightSideDisplay &&
              teamData.images[`logoSplash${order.indexOf(position)}`]
            ) {
              updateData.teamImage =
                teamData.images[`logoSplash${order.indexOf(position)}`];
            } else if (
              !rightSideDisplay &&
              teamData.images[`logoSplash${4 - order.indexOf(position)}`]
            ) {
              updateData.teamImage =
                teamData.images[`logoSplash${4 - order.indexOf(position)}`];
            }
          }
        }

        if (teamData.name) updateData.team = teamData.name;
      }
      if (champion) {
        if (champion.imageLarge) updateData.championImage = champion.imageLarge;
        if (champion.id) updateData.championId = champion.id;
      }
      if (league && league.imageLarge)
        updateData.leagueImage = league.imageLarge;

      firebaseRealtimeDbUpdateStream(screenNumber, updateData);
    } catch (e) {
      console.error("error sending to broadcast tool", e); // eslint-disable-line no-console
    }
  };

  updateFirebaseAllScreens = async (roundId, matchId, gameId) => {
    const league = window.MainData.schedule;
    const leftTeam = league.rounds[roundId].matches[matchId].team100;
    const rightTeam = league.rounds[roundId].matches[matchId].team200;
    const teams = [];
    if (leftTeam && leftTeam.tricode) teams.push(leftTeam.tricode);
    if (rightTeam && rightTeam.tricode) teams.push(rightTeam.tricode);
    const schedule = this.props.schedule;
    const screensToUpdate = [];
    const order = ["top", "jungle", "mid", "adc", "support"];
    teams.forEach((team) => {
      if (
        window.MainData.teams[team] &&
        window.MainData.schedule.rounds[roundId].matches[matchId].games[gameId]
      ) {
        order.forEach((position) => {
          const playerData =
            window.MainData.teams[team].roster[
              window.MainData.schedule.rounds[roundId].matches[matchId].games[
                gameId
              ].teams[team].roster[position].playerId
            ];
          const teamData = window.MainData.teams[team];
          const champion =
            championData[
              schedule.rounds[roundId].matches[matchId].games[gameId].teams[
                team
              ].roster[position].championId
            ];

          let rightSideDisplay = false;
          try {
            let screenNumber = order.indexOf(position) + 1;
            if (this.state.invertBroadcastToolScreens) {
              if (rightTeam.tricode === team) {
                // right/red
                screenNumber = 5 - order.indexOf(position);
              } else {
                rightSideDisplay = true;
                screenNumber = order.indexOf(position) + 6;
              }
            } else {
              if (rightTeam.tricode === team) {
                // right/red
                rightSideDisplay = true;
                screenNumber = order.indexOf(position) + 6;
              } else {
                screenNumber = 5 - order.indexOf(position);
              }
            }
            const updateData = {
              screenNumber,
              id: screenNumber,
              position: position,
            };
            if (playerData) {
              if (playerData.imageLarge) {
                updateData.playerImage = playerData.imageLarge;
              }
              updateData.playerId =
                window.MainData.schedule.rounds[roundId].matches[matchId].games[
                  gameId
                ].teams[team].roster[position].playerId;
            }
            if (teamData) {
              if (teamData.images) {
                if (teamData.images.logoSplash) {
                  updateData.teamImage = teamData.images.logoSplash;
                } else {
                  if (
                    rightSideDisplay &&
                    teamData.images[`logoSplash${order.indexOf(position)}`]
                  ) {
                    updateData.teamImage =
                      teamData.images[`logoSplash${order.indexOf(position)}`];
                  } else if (
                    !rightSideDisplay &&
                    teamData.images[`logoSplash${4 - order.indexOf(position)}`]
                  ) {
                    updateData.teamImage =
                      teamData.images[
                        `logoSplash${4 - order.indexOf(position)}`
                      ];
                  }
                }
              }
              if (teamData.color) {
                updateData.teamColor = teamData.color;
              }

              if (teamData.name) updateData.team = teamData.name;
            }
            if (champion) {
              if (champion.imageLarge)
                updateData.championImage = champion.imageLarge;
              if (champion.id) updateData.championId = champion.id;
            }
            if (league && league.imageLarge)
              updateData.leagueImage = league.imageLarge;
            screensToUpdate.push(updateData);
          } catch (e) {
            console.error("error sending to broadcast tool", e); // eslint-disable-line no-console
          }
        });
      }
    });

    if (screensToUpdate.length > 0) {
      await firebaseRealtimeDbUpdateAllStreams(screensToUpdate);
    }
  };

  setBan = (champ, ban, team) => {
    const schedule = this.props.schedule;
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const gameId = this.state.game;
    let bansNode =
      schedule.rounds[roundId].matches[matchId].games[gameId].teams[team].bans;
    bansNode = { ...bansNode, [ban]: champ };
    schedule.rounds[roundId].matches[matchId].games[gameId].teams[team].bans =
      bansNode;
    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      gameId
    ].teams[team].bans = bansNode;
    // this.props.updateSchedule();
  };

  setWinner = async (roundId, matchId, gameId, winner) => {
    if (winner === this.state.winner) {
      winner = "";
    }
    this.setState({ winner: winner });
    const schedule = this.props.schedule;
    schedule.rounds[roundId].matches[matchId].games[gameId].winner = winner;
    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      gameId
    ].winner = winner;
    // this.props.updateSchedule();
    await this.checkScore(roundId, matchId);
    // if (winner !== undefined)
    try {
      firebaseRealtimeDbSetValue(
        `${this.props.schedule.league}/${this.props.schedule.season}/${
          this.props.schedule.split
        }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
          this.props.schedule.match
        }/${this.props.schedule.game}/winner`,
        winner
      );
    } catch (error) {
      console.log(error, "Switch sides not saved");
    }
  };

  showChampionStat = async (team, pos, championId, show) => {
    if (!show) {
      await firebaseRealtimeDbSetValue(
        `control-banPick/data/championsStats/${team}/${pos}/show`,
        show
      );
      return;
    }
    if (this.state.isBanPickShowing) {
      const championName = get(championData, `[${championId}].id`, "");
      const responseChampionStats = await getPlayerStatsForPosition({
        league: get(window, "MainData.schedule.league", "ljl"),
        season: get(window, "MainData.schedule.season", "2021"),
        split: get(window, "MainData.schedule.split", "spring"),
        champion: championName,
      });
      const responseTeamsStats = await getChampionsStats({
        league: get(window, "MainData.schedule.league", "ljl"),
        season: get(window, "MainData.schedule.season", "2021"),
        split: get(window, "MainData.schedule.split", "spring"),
      });
      if (responseChampionStats.error || responseTeamsStats.error) {
        console.log("Error ferching data");
        return;
      } else {
        const winCount = get(responseChampionStats, "stats.winCount", 0);
        const gameCount = get(responseChampionStats, "stats.gameCount", 0);
        if (gameCount === 0) {
          await firebaseRealtimeDbSetValue(
            `control-banPick/data/championsStats/${team}/${pos}/`,
            { show: show, stats: { isFirstPick: true } }
          );
        } else {
          const winRate = Math.round((winCount / gameCount) * 100);
          const banCount = get(
            responseTeamsStats,
            `ALLTEAMS.championInfo[${championName}].bannedCount`,
            0
          );
          const pickCount = get(
            responseTeamsStats,
            `ALLTEAMS.championInfo[${championName}].pickedCount`,
            0
          );
          const presence = get(
            responseTeamsStats,
            `ALLTEAMS.championInfo[${championName}].presence`,
            0
          );
          const totalGames = Math.round(
            ((banCount + pickCount) * 100) / presence
          );
          const pickRate = Math.round((pickCount / totalGames) * 100);
          const banRate = Math.round((banCount / totalGames) * 100);
          const champStats = {
            icon: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${championName}.png`,
            pick: pickRate,
            ban: banRate,
            win: winRate,
            isFirstPick: false,
          };
          await firebaseRealtimeDbSetValue(
            `control-banPick/data/championsStats/${team}/${pos}/`,
            { show: show, stats: { ...champStats } }
          );
        }
      }
    } else {
      console.log("BanPick Screen Closed");
    }
  };

  showChampionStatV2 = async (team, pos, championId, show) => {
    if (!show) {
      await firebaseRealtimeDbSetValue(
        `control-banPickV2/data/championsStats/${team}/${pos}/show`,
        show
      );
      return;
    }
    if (this.state.isBanPickV2Showing) {
      const championName = get(championData, `[${championId}].id`, "");
      const responseChampionStats = await getPlayerStatsForPosition({
        league: get(window, "MainData.schedule.league", "ljl"),
        season: get(window, "MainData.schedule.season", "2021"),
        split: get(window, "MainData.schedule.split", "spring"),
        champion: championName,
      });
      const responseTeamsStats = await getChampionsStats({
        league: get(window, "MainData.schedule.league", "ljl"),
        season: get(window, "MainData.schedule.season", "2021"),
        split: get(window, "MainData.schedule.split", "spring"),
      });
      if (responseChampionStats.error || responseTeamsStats.error) {
        console.log("Error fetching data");
        return;
      } else {
        const winCount = get(responseChampionStats, "stats.winCount", 0);
        const gameCount = get(responseChampionStats, "stats.gameCount", 0);
        if (gameCount === 0) {
          const champStats = {
            icon: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${championName}.png`,
            isFirstPick: true,
          };
          await firebaseRealtimeDbSetValue(
            `control-banPickV2/data/championsStats/${team}/${pos}/`,
            { show: show, stats: champStats }
          );
        } else {
          const winRate = Math.round((winCount / gameCount) * 100);
          const banCount = get(
            responseTeamsStats,
            `ALLTEAMS.championInfo[${championName}].bannedCount`,
            0
          );
          const pickCount = get(
            responseTeamsStats,
            `ALLTEAMS.championInfo[${championName}].pickedCount`,
            0
          );
          const presence = get(
            responseTeamsStats,
            `ALLTEAMS.championInfo[${championName}].presence`,
            0
          );
          const totalGames = Math.round(
            ((banCount + pickCount) * 100) / presence
          );
          const pickRate = Math.round((pickCount / totalGames) * 100);
          const banRate = Math.round((banCount / totalGames) * 100);
          const champStats = {
            icon: `https://cdn.dekki.com/assets/broadcast/games/league-of-legends/champions/square/${championName}.png`,
            pick: pickRate,
            ban: banRate,
            win: winRate,
            isFirstPick: false,
          };
          await firebaseRealtimeDbSetValue(
            `control-banPickV2/data/championsStats/${team}/${pos}/`,
            { show: show, stats: { ...champStats } }
          );
        }
      }
    } else {
      console.log("BanPickV2 Screen Closed");
    }
  };

  buildMatchGames = (round, match) => {
    const matchData = this.props.schedule.rounds[round].matches[match];
    const gamesData = matchData.games || [];
    const bestOf = matchData.bestOf;
    const matchGamesArray = range(0, bestOf);
    if (!matchData.games) matchData.games = [];
    matchGamesArray.map((game) => {
      if (
        matchData.team100 &&
        matchData.team100.tricode &&
        matchData.team200 &&
        matchData.team200.tricode
      ) {
        gamesData[game] =
          matchData.games[game] ||
          this.buildMatchGame(
            matchData.team100.tricode,
            matchData.team200.tricode
          );
      } else {
        gamesData[game] =
          matchData.games[game] ||
          this.buildMatchGameManyTeams(matchData.teams);
      }
      return true;
    });
    window.MainData.schedule.rounds[round].matches[match].games = gamesData;
  };

  // setStateSwitchPerMatch = (e) => {
  //     this.setState({ [`match${e.id}`]: e.value });
  // }

  switchSides = () => {
    const schedule = this.props.schedule;
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const gameId = this.state.game;
    let teamsNode =
      schedule.rounds[roundId].matches[matchId].games[gameId].teams;
    // this.setStateSwitchPerMatch({ id: matchId, value: !this.state.sideSwitched })
    if (!this.state.switchedGames.includes(gameId)) {
      this.state.switchedGames.push(gameId);
    } else {
      var index = this.state.switchedGames.indexOf(gameId);
      this.state.switchedGames.splice(index, 1);
    }

    this.setState({ sideSwitched: !this.state.sideSwitched }, () => {
      window.MainData.schedule.isSideSwitched = this.state.sideSwitched;
      try {
        firebaseRealtimeDbSetValue(
          `${this.props.schedule.league}/${this.props.schedule.season}/${
            this.props.schedule.split
          }/${this.props.schedule.rounds[this.props.schedule.round].name}/${
            this.props.schedule.match
          }/${this.props.schedule.game}/switchedSides`,
          this.state.sideSwitched
        );
      } catch (error) {
        console.log(error, "Switch sides not saved");
      }
      Object.keys(teamsNode).map((team) => {
        const newSide = teamsNode[team].side === "100" ? "200" : "100";
        schedule.rounds[roundId].matches[matchId].games[gameId].teams[
          team
        ].side = newSide;
        window.MainData.schedule.rounds[roundId].matches[matchId].games[
          gameId
        ].teams[team].side = newSide;
        // this.props.updateSchedule();
        return null;
      });
    });
    this.props.updateSchedule();
  };

  buildMatchGame = (team100, team200) => {
    const game = {
      gameId: undefined,
      gameNumber: undefined,
      platformId: "ESPORTSTMNT06",
      teams: {
        [team100]: this.buildMatchGameTeam(team100, "100"),
        [team200]: this.buildMatchGameTeam(team200, "200"),
      },
      winner: undefined,
    };
    return game;
  };

  buildMatchGameManyTeams = (teams) => {
    const game = {
      gameId: undefined,
      gameNumber: undefined,
      platformId: "ESPORTSTMNT06",
      teams: teams.reduce((acc, team) => {
        acc[team.tricode] = team;
        return acc;
      }, {}),
      winner: undefined,
    };
    return game;
  };

  buildMatchGameTeam = (teamTricode, side) => {
    const teamPlayers =
      window.MainData.teams[teamTricode] &&
      Object.keys(window.MainData.teams[teamTricode].roster).length
        ? window.MainData.teams[teamTricode].roster
        : {};
    const p = Object.keys(teamPlayers).map((pl) => ({
      name: pl,
      position: teamPlayers[pl].position,
    }));
    const roster = {
      top: { championId: undefined },
      jungle: { championId: undefined },
      mid: { championId: undefined },
      adc: { championId: undefined },
      support: { championId: undefined },
    };
    p.map((player) => {
      if (!roster[player.position] || !roster[player.position].name) {
        const obj = {
          playerId: player.name,
          name: player.name,
          championId: undefined,
          value: player.name,
        };
        roster[player.position] = obj;
      }
      return undefined;
    });
    return {
      side: side,
      bans: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
      },
      roster: roster,
    };
  };

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getRandomChamp = () => {
    const champArray = Object.keys(championData);
    return champArray[Math.floor(Math.random() * champArray.length)];
  };

  checkScore = (roundId, matchId) => {
    const schedule = this.props.schedule;
    const team100 = schedule.rounds[roundId].matches[matchId].team100;
    const team200 = schedule.rounds[roundId].matches[matchId].team200;
    if (team100 && team200) {
      const team100Score = schedule.rounds[roundId].matches[
        matchId
      ].games.reduce(
        (acc, v) => (v.winner === team100.tricode ? acc + 1 : acc),
        0
      );
      const team200Score = schedule.rounds[roundId].matches[
        matchId
      ].games.reduce(
        (acc, v) => (v.winner === team200.tricode ? acc + 1 : acc),
        0
      );
      window.MainData.schedule.rounds[roundId].matches[matchId].team100.score =
        team100Score || 0;
      window.MainData.schedule.rounds[roundId].matches[matchId].team200.score =
        team200Score || 0;
      if (
        winnerBestOf[schedule.rounds[roundId].matches[matchId].bestOf] ===
        team100Score
      ) {
        window.MainData.schedule.rounds[roundId].matches[matchId].winner =
          team100.tricode;
      } else if (
        winnerBestOf[schedule.rounds[roundId].matches[matchId].bestOf] ===
        team200Score
      ) {
        window.MainData.schedule.rounds[roundId].matches[matchId].winner =
          team200.tricode;
      }
      // this.props.updateSchedule();
    }
  };

  randomSelection = () => {
    // const roundId = this.props.schedule.round;
    // const matchId = this.props.schedule.match;
    // const matchData = this.props.schedule.rounds[roundId].matches[matchId];
    // Array.from(Array(5).keys()).forEach((time, index) => {
    // this.setPick(this.getRandomChamp(), 'top', matchData.team100.tricode);
    // this.setPick(this.getRandomChamp(), 'mid', matchData.team100.tricode);
    // this.setPick(this.getRandomChamp(), 'adc', matchData.team100.tricode);
    // this.setPick(this.getRandomChamp(), 'support', matchData.team100.tricode);
    // this.setPick(this.getRandomChamp(), 'jungle', matchData.team100.tricode);

    // this.setPick(this.getRandomChamp(), 'top', matchData.team200.tricode);
    // this.setPick(this.getRandomChamp(), 'mid', matchData.team200.tricode);
    // this.setPick(this.getRandomChamp(), 'adc', matchData.team200.tricode);
    // this.setPick(this.getRandomChamp(), 'support', matchData.team200.tricode);
    // this.setPick(this.getRandomChamp(), 'jungle', matchData.team200.tricode);

    // const newIndex = index;
    // this.setBan(this.getRandomChamp(), newIndex, matchData.team100.tricode);
    // this.setBan(this.getRandomChamp(), newIndex, matchData.team200.tricode);
    // });

    ["100", "200"].forEach((side) => {
      // Picks
      lol_positions.forEach((pos) => {
        try {
          firebaseRealtimeDbSetValue(
            `banPick/picks/team${side}/${pos}/championID`,
            this.getRandomChamp()
          );
        } catch (error) {
          console.log(error, "Failed Random Picks");
        }
      });
      // Bans
      [0, 1, 2, 3, 4].forEach((ban) => {
        try {
          firebaseRealtimeDbSetValue(
            `banPick/bans/team${side}/${ban}`,
            this.getRandomChamp()
          );
        } catch (error) {
          console.log(error, "Failed Random Bans");
        }
      });
    });
  };

  clearSelection = async () => {
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const matchData = this.props.schedule.rounds[roundId].matches[matchId];
    this.setWinner(roundId, matchId, this.state.game, undefined);
    Array.from(Array(5).keys()).forEach((time, index) => {
      this.setPick("", "top", matchData.team100.tricode);
      this.setPick("", "mid", matchData.team100.tricode);
      this.setPick("", "adc", matchData.team100.tricode);
      this.setPick("", "support", matchData.team100.tricode);
      this.setPick("", "jungle", matchData.team100.tricode);

      this.setPick("", "top", matchData.team200.tricode);
      this.setPick("", "mid", matchData.team200.tricode);
      this.setPick("", "adc", matchData.team200.tricode);
      this.setPick("", "support", matchData.team200.tricode);
      this.setPick("", "jungle", matchData.team200.tricode);

      const newIndex = index;
      this.setBan(0, newIndex, matchData.team100.tricode);
      this.setBan(0, newIndex, matchData.team200.tricode);
    });
    await removeChampionsAssets();
  };

  handleChangeGameTime = (e) => {
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    window.MainData.schedule.rounds[roundId].matches[matchId].games[
      this.state.game || 0
    ] = {
      ...window.MainData.schedule.rounds[roundId].matches[matchId].games[
        this.state.game
      ],
      date: { time: (e.target && e.target.value) || "" },
    };
    // this.props.updateSchedule();
  };

  stopListeningGameLiveStats = () => {
    this.state.callbackIds &&
      Object.keys(this.state.callbackIds).forEach((key) => {
        window.MainData.dataApiListener.component.unregisterCallback(
          key,
          this.state.callbackIds[key]
        );
      });
    this.setState({ listeningGameLiveStats: false });
  };

  listenGameLiveStats = () => {
    this.setState({ listeningGameLiveStats: true }, () => {
      const roundId = this.props.schedule.round;
      const matchId = this.props.schedule.match;
      const gameId = this.state.game;
      const matchData = this.props.schedule.rounds[roundId].matches[matchId];
      const gameData = matchData.games[gameId];
      const processTeamPicks = (liveStats, teamSide) => {
        forEach(liveStats, (player) => {
          if (!player.championName && player.championID === 0) return;
          const index = player.arrayIndex;
          let teamTricode, playerName, side;
          const mainDataTeams = gameData.teams;
          forEach(mainDataTeams, (value, tricode) => {
            if (value.side === teamSide) {
              teamTricode = tricode;
              side = value.side;
            }
          });
          playerName = player.summonerName;
          if (playerName.startsWith(`${teamTricode} `)) {
            const pattern = `^${teamTricode} `;
            playerName = playerName.replace(new RegExp(pattern), "");
          }
          let championId = player.championID;
          if (championId !== 0)
            this.setChamp(championId.toString(), playerPositions[index], side);
          // this.setPick(championId.toString(), playerPositions[index], teamTricode);
          if (teamSide) {
            if (player.championName) {
              const championInfo = Object.values(championData).find(
                (championInfo) => championInfo.id === player.championName
              );
              championId = championInfo ? championInfo.key : null;
            }
            if (
              Object.keys(
                (window.MainData.teams[teamTricode] &&
                  window.MainData.teams[teamTricode].roster) ||
                  {}
              ).includes(playerName)
            ) {
              this.setPlayer(playerName, playerPositions[index], teamTricode);
            }
          }
        });
      };

      const callbackIds = {};
      const process = (event) => {
        if (event.teamOne) {
          processTeamPicks(event.teamOne, "100");
        }
        if (event.teamTwo) {
          processTeamPicks(event.teamTwo, "200");
        }
        if (event.bannedChampions) {
          const bannedChampions = event.bannedChampions;
          let team100BanCounter = 0;
          let team200BanCounter = 0;
          // const tricode1 = get(matchData, 'team100.tricode', '');
          // const tricode2 = get(matchData, 'team200.tricode', '');
          // const switched = get(matchData, `games[${this.state.game}].teams[${tricode1}].side`) === '200';
          bannedChampions.forEach((ban) => {
            if (ban.teamID === 100) {
              this.setChamp(
                ban.championID.toString(),
                team100BanCounter,
                "100"
              );
              // this.setBan(ban.championID.toString(), team100BanCounter, switched ? tricode2 : tricode1);
              ++team100BanCounter;
            }
            if (ban.teamID === 200) {
              this.setChamp(
                ban.championID.toString(),
                team200BanCounter,
                "200"
              );
              // this.setBan(ban.championID.toString(), team200BanCounter, switched ? tricode1 : tricode2);
              ++team200BanCounter;
            }
          });
        }
      };
      callbackIds["champ_select"] =
        window.MainData.dataApiListener.component.registerCallback(
          "champ_select",
          process
        );
      callbackIds["game_info"] =
        window.MainData.dataApiListener.component.registerCallback(
          "game_info",
          process
        );
      this.setState({
        callbackIds,
      });
    });
  };

  handleSkipMatch = () => {
    const match =
      window.MainData.schedule.rounds[this.props.schedule.round].matches[
        this.props.schedule.match
      ];
    match.skip = !match.skip;
    this.props.updateSchedule();
  };

  render() {
    const roundId = this.props.schedule.round;
    const matchId = this.props.schedule.match;
    const matchData = this.props.schedule.rounds[roundId].matches[matchId];
    if (!matchData.bestOf) matchData.bestOf = 3;
    const team100tricode = matchData
      ? matchData.team100 && matchData.team100.tricode
      : "";
    const team200tricode = matchData
      ? matchData.team200 && matchData.team200.tricode
      : "";
    let flipped;
    if (team100tricode && team200tricode) {
      flipped =
        matchData && matchData.games && matchData.games[this.state.game]
          ? matchData.games[this.state.game].teams[team100tricode].side ===
            "100"
            ? false
            : true
          : false;
    } else {
      flipped = false;
    }

    const team100Score =
      this.props.schedule.rounds[roundId].matches[matchId].team100 &&
      this.props.schedule.rounds[roundId].matches[matchId].team100.score;
    const team200Score =
      this.props.schedule.rounds[roundId].matches[matchId].team200 &&
      this.props.schedule.rounds[roundId].matches[matchId].team200.score;
    let extraHeader = "";
    if (team100tricode) {
      extraHeader = `Match ${
        window.MainData.schedule.match + 1
      } : ${team100tricode} (${team100Score || 0}) vs ${team200tricode} (${
        team200Score || 0
      }) - Game ${window.MainData.schedule.game + 1}`;
    } else {
      extraHeader = `${
        window.MainData.schedule.rounds[window.MainData.schedule.round].matches[
          window.MainData.schedule.match
        ].name
      }`;
    }
    const gamesNumberArray = range(0, matchData.bestOf);

    return (
      <DataContainer
        componentSlug="match"
        extra={extraHeader}
        icon="fas fa-trophy"
      >
        <div className="data-panel__fieldset">
          <label className="data-panel__fieldset__label">
            {i18n().general.match}
          </label>
          <div className="data-panel__fieldset__body">
            <Style2Rows>
              {team100tricode && team200tricode && (
                <div className="data-panel--match__logos">
                  <strong>{matchData.team100.tricode}</strong>
                  <img
                    className="data-panel--match__logos__image"
                    src={loadImageUrlTeamLogo(
                      matchData.team100,
                      this.props.schedule.league,
                      this.props.schedule.season,
                      this.props.schedule.split
                    )}
                    alt={team100tricode}
                  />
                  <div className="pill">
                    <span>
                      <strong>{team100Score || 0}</strong>
                    </span>
                    <span>vs</span>
                    <span>
                      <strong>{team200Score || 0}</strong>
                    </span>
                  </div>
                  <img
                    className="data-panel--match__logos__image"
                    src={loadImageUrlTeamLogo(
                      matchData.team200,
                      this.props.schedule.league,
                      this.props.schedule.season,
                      this.props.schedule.split
                    )}
                    alt={team200tricode}
                  />
                  <strong>{matchData.team200.tricode}</strong>
                  {(matchData.skip && (
                    <StyleSkip>{"Match Skipped"}</StyleSkip>
                  )) ||
                    null}
                </div>
              )}
              <Button
                text={matchData.skip ? "Unskip" : "Skip"}
                action={() => {
                  this.handleSkipMatch();
                }}
                style={{
                  backgroundColor: matchData.skip ? "#dd3217" : "#076FC1",
                  width: "75px",
                  margin: "auto",
                  textAlign: "center",
                }}
              />
            </Style2Rows>

            {!team100tricode && extraHeader}
          </div>
        </div>
        <div className="data-panel__fieldset">
          <label className="data-panel__fieldset__label">
            {i18n().general.gameList}
          </label>
          <div className="data-panel__fieldset__body">
            <div className="pill">
              {gamesNumberArray.map((game, i) => {
                const winner =
                  matchData && matchData.games && matchData.games[game]
                    ? matchData.games[game].winner
                    : undefined;
                return (
                  <Button
                    className={
                      game === this.state.game ? "button--highlighted" : ""
                    }
                    text={`${i18n().general.game} ${game + 1}: ${
                      winner ? winner : "・・・"
                    }`}
                    key={i}
                    action={() => this.changeGame(game)}
                    disabled={game === this.state.game ? true : false}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* I am convinced this timer is not attached to anything. */}
        {(matchData.games && matchData.games.length > 1 && (
          <div
            className={`data-panel__fieldset ${
              matchData.skip ? "disabled" : ""
            }`}
          >
            <label className="data-panel__fieldset__label">
              {i18n().general.startTime}
            </label>
            <div className="data-panel__fieldset__body">
              <div className="pill">
                <input
                  key={`${roundId}-${matchId}-${this.state.game}`}
                  className="input input--wide input--small-text"
                  style={{ textAlign: "center" }}
                  type="time"
                  value={get(
                    matchData,
                    `games[${this.state.game}].date.time`,
                    "--:--"
                  )}
                  onChange={this.handleChangeGameTime}
                />
              </div>
            </div>
          </div>
        )) ||
          null}
        {team100tricode && (
          <div
            className={`data-panel__fieldset ${
              matchData.skip ? "disabled" : ""
            }`}
          >
            <label className="data-panel__fieldset__label">
              {i18n().general.game}
            </label>
            <div className="data-panel__fieldset__body">
              <div className="pill">
                <span>{`${i18n().general.game} ${this.state.game + 1} ${
                  i18n().general.result
                }`}</span>
                <Button
                  className={
                    matchData &&
                    matchData.games &&
                    matchData.games[this.state.game]
                      ? matchData.games[this.state.game].winner ===
                        team100tricode
                        ? "button--highlighted"
                        : ""
                      : ""
                  }
                  icon="fas fa-trophy"
                  action={() =>
                    this.setWinner(
                      roundId,
                      matchId,
                      this.state.game,
                      team100tricode
                    )
                  }
                  text={team100tricode}
                />
                <Button
                  className={
                    matchData &&
                    matchData.games &&
                    matchData.games[this.state.game]
                      ? matchData.games[this.state.game].winner ===
                        team200tricode
                        ? "button--highlighted"
                        : ""
                      : ""
                  }
                  icon="fas fa-trophy"
                  action={() =>
                    this.setWinner(
                      roundId,
                      matchId,
                      this.state.game,
                      team200tricode
                    )
                  }
                  text={team200tricode}
                />
                {/* <Button action={() => {
                                    this.clearTopLevel();
                                    this.setWinner(roundId, matchId, this.state.game, undefined);
                                }} text={i18n().general.clear}
                                /> */}
                <Button
                  className={``}
                  action={() => this.resetFireBase()}
                  text={i18n().general.clear}
                />
              </div>
              <div className="pill">
                <Button
                  className={
                    this.state.sideSwitched ? "button--highlighted" : ""
                  }
                  action={this.switchSides}
                  text={i18n().general.switchSides}
                  icon="fal fa-people-arrows"
                />
                <Button
                  className={
                    this.state.invertBroadcastToolScreens
                      ? "button--highlighted"
                      : ""
                  }
                  action={this.invertBroadcastToolScreens}
                  text={i18n().general.switchScreens}
                  icon="fas fa-sync"
                />
              </div>
              <div className="pill">
                {this.state.listeningGameLiveStats ? (
                  <Button
                    style={{ backgroundColor: "green" }}
                    className={`button interactive `}
                    action={() => this.stopListeningGameLiveStats()}
                    text="STOP"
                  />
                ) : (
                  <Button
                    className={`button interactive `}
                    action={() => this.listenGameLiveStats()}
                    text="LISTEN"
                  />
                )}
              </div>
              <div className="pill">
                {!this.state.cleanUnlock && (
                  <Button
                    className={`button interactive `}
                    action={() =>
                      this.setState({
                        cleanUnlock: !this.state.cleanUnlock,
                      })
                    }
                    text={i18n().controller.buttons.unlock}
                  />
                )}
                {this.state.cleanUnlock && (
                  <Button
                    className={`button interactive `}
                    action={() =>
                      this.setState({
                        cleanUnlock: !this.state.cleanUnlock,
                      })
                    }
                    text={i18n().controller.buttons.lock}
                  />
                )}

                {this.state.cleanUnlock && (
                  <Button
                    style={{ backgroundColor: "green" }}
                    className={`button interactive `}
                    action={() => this.fetchFireBase()}
                    text={i18n().controller.buttons.fetch}
                  />
                )}
                {this.state.cleanUnlock && (
                  <Button
                    action={() => this.randomSelection()}
                    text={"Randomize"}
                  />
                )}
                {/* {this.state.cleanUnlock && <Button style={{ backgroundColor: 'green' }} className={`button interactive `} action={() => this.resetFireBase()} text={i18n().controller.buttons.reset} />} */}
              </div>
            </div>
          </div>
        )}
        <div
          className={`data-panel__fieldset ${matchData.skip ? "disabled" : ""}`}
        >
          {team100tricode &&
            matchData &&
            matchData.games &&
            matchData.games[this.state.game] && (
              <div className="game-lineup-container game-sides-container">
                {
                  <DataMatchLineup
                    team={
                      matchData.games[this.state.game].teams[
                        flipped ? team200tricode : team100tricode
                      ]
                    }
                    side="100"
                    teamTricode={flipped ? team200tricode : team100tricode}
                    onPlayerSet={this.setPlayer}
                    onChampSet={this.setChamp}
                    onSetCoach={this.setCoach}
                    onShowChampStats={(pos, champId, show, v1) => {
                      if (v1) {
                        this.showChampionStat("team100", pos, champId, show);
                      } else {
                        this.showChampionStatV2("team100", pos, champId, show);
                      }
                    }}
                    champStatsStatus={get(
                      this,
                      "state.champStatsStatus.team100",
                      {}
                    )}
                    swapChamps={this.swapChamps}
                    screens={
                      (this.state.invertBroadcastToolScreens && !flipped) ||
                      (!this.state.invertBroadcastToolScreens && flipped)
                        ? [6, 7, 8, 9, 10]
                        : [5, 4, 3, 2, 1]
                    }
                  />
                }
                {
                  <DataMatchLineup
                    team={
                      matchData.games[this.state.game].teams[
                        flipped ? team100tricode : team200tricode
                      ]
                    }
                    side="200"
                    teamTricode={flipped ? team100tricode : team200tricode}
                    onPlayerSet={this.setPlayer}
                    onChampSet={this.setChamp}
                    onSetCoach={this.setCoach}
                    onShowChampStats={(pos, champId, show, v1) => {
                      if (v1) {
                        this.showChampionStat("team200", pos, champId, show);
                      } else {
                        this.showChampionStatV2("team200", pos, champId, show);
                      }
                    }}
                    champStatsStatus={get(
                      this,
                      "state.champStatsStatus.team200",
                      {}
                    )}
                    swapChamps={this.swapChamps}
                    screens={
                      (this.state.invertBroadcastToolScreens && !flipped) ||
                      (!this.state.invertBroadcastToolScreens && flipped)
                        ? [5, 4, 3, 2, 1]
                        : [6, 7, 8, 9, 10]
                    }
                  />
                }
              </div>
            )}
        </div>
        <div
          className={`data-panel__fieldset ${matchData.skip ? "disabled" : ""}`}
        >
          {team100tricode &&
            matchData &&
            matchData.games &&
            matchData.games[this.state.game] && (
              <div className="game-bans-container game-sides-container">
                {
                  <DataMatchBans
                    team={
                      matchData.games[this.state.game].teams[
                        flipped ? team200tricode : team100tricode
                      ]
                    }
                    side="100"
                    teamTricode={flipped ? team200tricode : team100tricode}
                    onBanSet={this.setChamp}
                  />
                }
                {
                  <DataMatchBans
                    team={
                      matchData.games[this.state.game].teams[
                        flipped ? team100tricode : team200tricode
                      ]
                    }
                    side="200"
                    teamTricode={flipped ? team100tricode : team200tricode}
                    onBanSet={this.setChamp}
                  />
                }
              </div>
            )}
        </div>
      </DataContainer>
    );
  }
}

const Style2Rows = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

const StyleSkip = styled.div`
  display: inline-block;
  width: 150px;
  margin: 0 20px;
  padding: 0 5px;
  text-align: center;
  border-radius: 10px;
  background-color: #cccccc;
`;

export default DataMatch;
