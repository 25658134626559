"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleYTicksLabelContainer = exports.StyleWinnerLabel = exports.StyleTeamSeparator = exports.StyleTeamLogoContainer = exports.StyleTeamLogo = exports.StyleRightAsset = exports.StyleMain = exports.StyleInfoBoxSubtitle = exports.StyleGraphContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleInfoBoxSubtitle = _styledComponents.default.span.withConfig({
  displayName: "styles__StyleInfoBoxSubtitle",
  componentId: "sc-1bnlvk2-0"
})(["font-size:25px;font-weight:500;"]);

exports.StyleInfoBoxSubtitle = StyleInfoBoxSubtitle;

var StyleMain = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMain",
  componentId: "sc-1bnlvk2-1"
})(["display:grid;margin-left:10px;grid-template-columns:10% 63% 27%;transform:translateX(10px);"]);

exports.StyleMain = StyleMain;

var StyleTeamLogo = _styledComponents.default.img.withConfig({
  displayName: "styles__StyleTeamLogo",
  componentId: "sc-1bnlvk2-2"
})(["height:80px;width:80px;"]);

exports.StyleTeamLogo = StyleTeamLogo;

var StyleTeamSeparator = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamSeparator",
  componentId: "sc-1bnlvk2-3"
})(["background-color:#efefef;margin:5px 0;"]);

exports.StyleTeamSeparator = StyleTeamSeparator;

var StyleTeamLogoContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamLogoContainer",
  componentId: "sc-1bnlvk2-4"
})(["display:grid;grid-template-rows:1fr 11px 1fr;margin:auto 10px;padding-right:10px;padding-bottom:15px;align-content:center;padding-top:13px;img{margin:auto;}&& img:first-of-type{margin-bottom:5px;}"]);

exports.StyleTeamLogoContainer = StyleTeamLogoContainer;

var StyleGraphContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleGraphContainer",
  componentId: "sc-1bnlvk2-5"
})(["position:relative;width:100%;height:100%;canvas{margin-top:5px;}"]);

exports.StyleGraphContainer = StyleGraphContainer;

var StyleYTicksLabelContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleYTicksLabelContainer",
  componentId: "sc-1bnlvk2-6"
})(["position:absolute;top:3px;left:-20px;bottom:0px;margin:3px 0;width:40px;font-size:14px;display:grid;line-height:1;font-family:\"futura-pt\";font-weight:600;margin-right:5px;text-align:center;span{color:#0078ff;}span:nth-child(6){color:white;}span:nth-child(n + 7){color:#dd3217;}", ";"], function (props) {
  return props.StyleYTicksLabelContainer ? props.StyleYTicksLabelContainer : "";
});

exports.StyleYTicksLabelContainer = StyleYTicksLabelContainer;

var StyleRightAsset = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleRightAsset",
  componentId: "sc-1bnlvk2-7"
})(["height:210px;position:relative;transition:transform 1000ms linear;img{right:-50px;overflow:visible;position:absolute;bottom:-75px;width:410px;max-width:unset;transform:", ";}"], function (props) {
  return props.show ? "translateY(0)" : "translateY(35px)";
});

exports.StyleRightAsset = StyleRightAsset;

var StyleWinnerLabel = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleWinnerLabel",
  componentId: "sc-1bnlvk2-8"
})(["background-color:", ";height:60px;position:absolute;bottom:25px;right:8px;width:95%;div{width:100px;height:100%;padding:8px 0 8px 10px;text-align:left;font-family:futura-pt,\"sans-serif\";}span{width:100%;font-size:40px;line-height:30px;font-weight:700;text-align:left;display:inline;}span:first-of-type{display:block;font-size:14px;line-height:12px;font-weight:400;margin-bottom:4px;}span:last-of-type{font-size:30px;}", ";"], function (props) {
  return props.teamWinnerColor;
}, function (props) {
  return props.StyleWinnerLabel ? props.StyleWinnerLabel : "";
});

exports.StyleWinnerLabel = StyleWinnerLabel;