"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyleTeamsContainer = exports.StylePositionLabel = exports.StylePlayersContainer = exports.StylePlayer = exports.StyleMythicItemIcon = exports.StyleInfoBoxBody = exports.StyleChampionIcon = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyleInfoBoxBody = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleInfoBoxBody",
  componentId: "sc-q03yjp-0"
})(["color:white;align-items:center;height:210px;position:absolute;width:1340px;overflow:visible;top:70px;bottom:0;"]);

exports.StyleInfoBoxBody = StyleInfoBoxBody;

var StyleTeamsContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleTeamsContainer",
  componentId: "sc-q03yjp-1"
})(["display:grid;grid-template-columns:repeat(2,1fr);height:100%;"]);

exports.StyleTeamsContainer = StyleTeamsContainer;

var StylePlayersContainer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayersContainer",
  componentId: "sc-q03yjp-2"
})(["display:grid;grid-template-columns:repeat(5,1fr);width:100%;height:85%;margin:auto 0;justify-content:center;padding:0 20px;&:first-child{border-right:1px #ffffff solid;}"]);

exports.StylePlayersContainer = StylePlayersContainer;

var StylePlayer = _styledComponents.default.div.withConfig({
  displayName: "styles__StylePlayer",
  componentId: "sc-q03yjp-3"
})(["display:flex;flex-direction:column;text-align:center;justify-content:center;"]);

exports.StylePlayer = StylePlayer;

var StylePositionLabel = _styledComponents.default.span.withConfig({
  displayName: "styles__StylePositionLabel",
  componentId: "sc-q03yjp-4"
})(["color:", ";text-transform:uppercase;font-size:18px;"], function (props) {
  return props.side === "team100" ? "#0078ff" : "#dd3217";
});

exports.StylePositionLabel = StylePositionLabel;

var StyleChampionIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleChampionIcon",
  componentId: "sc-q03yjp-5"
})(["margin-top:5px;min-height:45px;img{width:40px;}"]);

exports.StyleChampionIcon = StyleChampionIcon;

var StyleMythicItemIcon = _styledComponents.default.div.withConfig({
  displayName: "styles__StyleMythicItemIcon",
  componentId: "sc-q03yjp-6"
})(["margin-top:15px;img{width:70px;}"]);

exports.StyleMythicItemIcon = StyleMythicItemIcon;