// REACT
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ThemeContext } from "../../View/themes";

// const ScoreContainerAsset = 'https://cdn.dekki.com/assets/broadcast/games/league-of-legends/view/win-loss_bg.png';

const ScoreContainer = styled.div`
  background-image: ${(props) => `url(${props.background})`};
  width: 150px;
  height: 60px;
  background-position-x: 0;
  transform: ${(props) =>
    props.flip ? "scaleX(-1) translateX(-4px)" : "translateX(56px)"};
  z-index: 5;
  border-color: ${(props) => props.mainTeamColor};
  ${(props) => props.css}
`;

const StyleTeamRecordContainer = styled.div`
  // Double = 145, 33. Single = 155, 44
  transform: ${(props) =>
    props.flip ? "translate(44px,-16px)" : "translate(155px,-16px)"};
  color: ${(props) => (props.side === "left" ? "#0078ff" : "#dd3217")};
  /* color: ${(props) => props.color}; */
  ${(props) => props.cssTeamReccord}
`;

const StyleTeamRecordContainerBig = styled.div`
  // Double = 145, 33. Single = 155, 44
  transform: ${(props) =>
    props.flip ? "translate(33px,-16px)" : "translate(145px,-16px)"};
  color: ${(props) => (props.side === "left" ? "#0078ff" : "#dd3217")};
  /* color: ${(props) => props.color}; */
  ${(props) => props.cssTeamReccord}
`;

const StyleTeamReccord = styled.div`
  font-size: 13px;
  transform: translate(-8px, -2px);
`;

const StyleContainer = styled.div`
  font-weight: 600;
  height: 41px;
  left: ${(props) => (props.side === "left" ? "318px" : "initial")};
  right: ${(props) => (props.side === "left" ? "initial" : "368px")};
  position: absolute;
  top: 0;
  width: 148px;
  font-size: 50px;
  color: white;
  ${(props) => props.cssTeamInfoContainer || ""}
`;

// const TeamLogo = styled.img`
//     position: absolute;
//     top: 2px;
//     ${props => props.side === 'left' ? 'left: 60px;' : 'left: 111px;'};
//     ${props => props.cssTeamLogo}
// `;

const StyleTeamName = styled.div`
  font-size: 35px;
  top: 1px;
  color: white;
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  width: 100px;
  z-index: 6;
  left: ${(props) => (props.side === "left" ? "initial" : "18px")};
  right: ${(props) => (props.side === "left" ? "-43px" : "initial")};
  color: ${(props) => (props.side === "left" ? "#0078ff" : "#dd3217")};
  ${(props) => props.css}
`;

const StyleLightContainer = styled.div`
  width: 65px;
  display: flex;
  margin-left: -13px;
  ${(props) => props.cssTeamInfo}
`;

const StyleLightContainerBo3 = styled.div`
  width: 65px;
  display: flex;
  margin-left: -7px;
  ${(props) => props.cssTeamInfo}
`;

const StyleLight = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(props) => (props.win ? props.color : "#767676")};
  margin-right: 6px;
`;

const StyleTeamScore = styled.div`
  position: absolute;
  font-size: 35px;
  top: 1px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  width: 57px;
  z-index: 2;
  left: ${(props) => (props.side === "right" ? "initial" : "0px")};
  right: ${(props) => (props.side === "right" ? "0px" : "initial")};
  background-image: ${(props) => `url(${props.background})`};
  background-position: ${(props) => props.side};
  /* padding-${(props) => props.side}: 3px; */
`;

const TeamInfo = (props) => {
  const context = useContext(ThemeContext);
  const theme = context.screens.gameScreen.teamInfo || {};
  return (
    <StyleContainer
      side={props.side}
      cssTeamInfoContainer={theme.cssTeamInfoContainer}
      flip={props.side === "right"}
    >
      <StyleTeamName side={props.side} css={theme.cssTeamName || ""}>
        {props.team}
      </StyleTeamName>
      <ScoreContainer
        background={props.scoreAsset}
        flip={props.side === "right"}
        mainTeamColor={props.mainTeamColor}
        css={theme.cssScoreContainer || ""}
      />
      {/* {theme.showTeamLogo &&
                <TeamLogo src={props.teamLogo} side={props.side} cssTeamLogo={theme.cssTeamLogo}/>
            } */}
      {props.showScore &&
        props.standings &&
        props.standings.win < 10 &&
        props.standings.loss < 10 && (
          <StyleTeamRecordContainer
            flip={props.side === "right"}
            side={props.side}
            cssTeamReccord={theme.cssTeamReccord}
            color={props.mainTeamColor}
          >
            {theme.showGamesWinLights && props.bestOf === 5 ? (
              <StyleLightContainer side={props.side}>
                {[...Array(3)].map((item, index) => {
                  return (
                    <StyleLight
                      key={index}
                      color={props.side === "left" ? "#0078ff" : "#dd3217"}
                      win={
                        props.side === "left"
                          ? props.score + index + 1 > 3
                          : props.score > index
                      }
                    />
                  );
                })}
              </StyleLightContainer>
            ) : theme.showGamesWinLights && props.bestOf === 3 ? (
              <StyleLightContainerBo3 side={props.side}>
                {[...Array(2)].map((item, index) => {
                  return (
                    <StyleLight
                      key={index}
                      color={props.side === "left" ? "#0078ff" : "#dd3217"}
                      win={
                        props.side === "left"
                          ? props.score + index + 1 > 2
                          : props.score > index
                      }
                    />
                  );
                })}
              </StyleLightContainerBo3>
            ) : (
              <StyleTeamReccord>
                {`${props.standings.win} - ${props.standings.loss}`}
              </StyleTeamReccord>
            )}
          </StyleTeamRecordContainer>
        )}
      {props.showScore &&
        props.standings &&
        (props.standings.win > 9 || props.standings.loss > 9) && (
          <StyleTeamRecordContainerBig
            flip={props.side === "right"}
            side={props.side}
            cssTeamReccord={theme.cssTeamReccord}
            color={props.mainTeamColor}
          >
            {theme.showGamesWinLights && props.bestOf === 5 ? (
              <StyleLightContainer side={props.side}>
                {[...Array(3)].map((item, index) => {
                  return (
                    <StyleLight
                      key={index}
                      color={props.side === "left" ? "#0078ff" : "#dd3217"}
                      win={
                        props.side === "left"
                          ? props.score + index + 1 > 3
                          : props.score > index
                      }
                    />
                  );
                })}
              </StyleLightContainer>
            ) : (
              <StyleTeamReccord>
                {`${props.standings.win} - ${props.standings.loss}`}
              </StyleTeamReccord>
            )}
          </StyleTeamRecordContainerBig>
        )}
      {props.score !== undefined &&
        props.score !== null &&
        !theme.showGamesWinLights &&
        !theme.hideScore && (
          <StyleTeamScore background={props.scoreAsset} side={props.side}>
            {props.score}
          </StyleTeamScore>
        )}
    </StyleContainer>
  );
};

TeamInfo.propTypes = {
  side: PropTypes.string,
  team: PropTypes.string,
  // teamLogo: PropTypes.string,
  standings: PropTypes.object,
  scoreAsset: PropTypes.string,
  showScore: PropTypes.bool.isRequired,
  score: PropTypes.object,
  mainTeamColor: PropTypes.string,
  bestOf: PropTypes.number,
};

export default TeamInfo;
