// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";
//ME
import PickerBase from "../../Picker/PickerBase";
import TournamentRealmPickerService from "../../../services/realmPickerService";

class TournamentRealmPicker extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    default: PropTypes.string,
  };

  render() {
    return (
      <PickerBase
        className="tournamentRealm"
        onChange={this.props.onChange}
        service={TournamentRealmPickerService}
        ljlMainDataID="tournamentRealm"
        i18nTag="gameRealm"
        defaultValue={this.props.default || "LOLTMNT01"}
      />
    );
  }
}

export default TournamentRealmPicker;
