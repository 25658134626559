"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _chart = _interopRequireDefault(require("chart.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

var helpers = _chart.default.helpers;
var pluginDefaults = {
  yValue: 0,
  twoToneLine: {
    stroke: 0.01,
    color: "rgba(0, 0, 0, 1.000)",
    type: "solid",
    xPos: "top",
    yPos: "left"
  },
  belowThresholdBackgroundColor: ["rgba(221,50,23 0.3)" // red
  ],
  belowThresholdBorderColor: ["#dd3217" // red
  ]
};

function addTwoToneLine(ctx, scale, constraints, options) {
  var yPos = scale.getPixelForValue(options.yValue);
  var twoToneLine = options.twoToneLine;
  var dashLength = 8;
  var padding = 0;
  var lineStartPos = constraints.start + padding;
  var lineStopPos = constraints.stop + padding;
  drawLineInContext(ctx, yPos + twoToneLine.stroke / 4, lineStartPos, lineStopPos, twoToneLine.stroke, twoToneLine.color, dashLength);
}

function drawLineInContext(ctx, y, x0, x1, lineWidth, color) {
  ctx.beginPath();
  ctx.moveTo(x0, y);
  ctx.lineTo(x1, y);
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
}

function addTwoToneBody(chartInstance, node, options, defaultBackgroundColors, defaultBorderColors) {
  for (var i = 0; i < chartInstance.chart.config.data.datasets.length; i += 1) {
    // if (typeof defaultBackgroundColors[i] === 'string') {
    //   const fill = calculateGradientFill(
    //     node.getContext("2d"),
    //     chartInstance.scales['y-axis-0'],
    //     chartInstance.chart.height,
    //     defaultBackgroundColors[i],
    //     options.belowThresholdBackgroundColor[i],
    //     options.yValue
    //   );
    //   chartInstance.chart.config.data.datasets[i].backgroundColor = fill;
    // }
    if (typeof defaultBorderColors[i] === "string") {
      var fill = calculateGradientFill(node.getContext("2d"), chartInstance.scales["y-axis-0"], chartInstance.chart.height, defaultBorderColors[i], options.belowThresholdBorderColor[i], options.yValue);
      chartInstance.chart.config.data.datasets[i].borderColor = fill;
    }
  }
}

function pluginOptionsHaveBeenSet(options) {
  return _typeof(options.belowThresholdBackgroundColor) === "object" && options.belowThresholdBackgroundColor.length > 0 && typeof options.yValue === "number";
}

function calculateGradientFill(ctx, scale, height, baseColor, gradientColor, value) {
  var yPos = scale.getPixelForValue(value);
  var grd = ctx.createLinearGradient(0, height, 0, 0);
  var gradientStop = 1 - yPos / height;

  try {
    grd.addColorStop(0, gradientColor);
    grd.addColorStop(gradientStop, gradientColor);
    grd.addColorStop(gradientStop, baseColor);
    grd.addColorStop(1.0, baseColor);
    return grd;
  } catch (e) {
    return baseColor;
  }
}

var TwoTonePlugin = _chart.default.PluginBase.extend({
  id: "TwoTone",
  baseBackgroundColors: [],
  baseBorderColors: [],
  blockingPromise: null,
  loaded: false,
  isSupportedByPlugin: function isSupportedByPlugin(type) {
    return type === "line" || type === "derivedLine";
  },
  beforeInit: function beforeInit(chartInstance) {
    if (!this.isSupportedByPlugin(chartInstance.config.type)) return; // Capture the defaults so we can reapply on resize

    for (var i = 0; i < chartInstance.chart.config.data.datasets.length; i += 1) {
      this.baseBackgroundColors[i] = chartInstance.chart.config.data.datasets[i].backgroundColor;
      this.baseBorderColors[i] = chartInstance.chart.config.data.datasets[i].borderColor;
    }
  },
  afterScaleUpdate: function afterScaleUpdate(chartInstance) {
    if (!this.isSupportedByPlugin(chartInstance.config.type)) return;
    var node = chartInstance.chart.ctx.canvas;
    var options = helpers.configMerge(pluginDefaults, chartInstance.options.twoTone);

    if (pluginOptionsHaveBeenSet(options)) {
      addTwoToneBody(chartInstance, node, options, this.baseBackgroundColors, this.baseBorderColors);
    }
  },
  afterDraw: function afterDraw(chartInstance) {
    if (!this.isSupportedByPlugin(chartInstance.config.type)) return;
    var node = chartInstance.chart.ctx.canvas;
    var options = helpers.configMerge(pluginDefaults, chartInstance.options.twoTone);

    if (typeof options.yValue === "number") {
      addTwoToneLine(node.getContext("2d"), chartInstance.scales["y-axis-0"], {
        top: chartInstance.chartArea.top,
        bottom: chartInstance.boxes[2].bottom,
        start: chartInstance.chartArea.left,
        stop: chartInstance.chartArea.right
      }, options);
    }
  }
});

var _default = TwoTonePlugin;
exports.default = _default;