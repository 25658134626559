// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";
// import styled from 'styled-components';
// ME
import CreateGoldChart from "../../../GameBreakdown/GoldDiffChart";
import { StyleInfoBoxBody, StyleInfoBoxTitle } from "../styles";
import { get } from "lodash";

class InfoBoxGoldDifference extends Component {
  static propTypes = {
    // theme: PropTypes.object,
    data: PropTypes.object,
    team100Tricode: PropTypes.string,
    team200Tricode: PropTypes.string,
  };

  state = {
    // dataSet: [],
    chart: null,
  };

  getOptions(dataSet) {
    const options = {
      animation: {
        duration: 800,
      },
      twoTone: {
        yValue: 0,
        twoToneLine: {
          stroke: 0.2,
          colour: "rgba(255, 255, 255, 0.3)",
        },
        belowThresholdBackgroundColor: ["rgba(221,65,66, 0.3)"],
        belowThresholdBorderColor: ["#dd3217"],
      },
      trigger: {
        blockingPromise: Promise.resolve(),
      },
      elements: {
        line: {
          tension: 0.1, // disable bezier curves,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              color: "rgba(255, 255, 255, 0.1)",
            },
            ticks: {
              fontSize: 13,
              fontColor: "#FFFFFF",
              autoSkip: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: "rgba(255, 255, 255, 0.3)",
              zeroLineColor: "#FFFFFF",
            },
            ticks: {
              maxTicksLimit: 2,
              fontSize: 15,
              fontColor: "#FFFFFF",
              // suggestedMin: -1000,
              // suggestedMax: 1000,
              min: Math.min.apply(this, dataSet),
              max: Math.max.apply(this, dataSet),
              padding: 2,
              callback: function (value, index, values) {
                let tickLabel = undefined;
                if (value === 0) return "0";
                if (index === values.length - 1) {
                  tickLabel = `${Math.min.apply(this, dataSet)}   `;
                } else if (index === 0) {
                  tickLabel = `${Math.max.apply(this, dataSet)}   `;
                }
                if (tickLabel.startsWith("-")) {
                  tickLabel = tickLabel.slice(1, tickLabel.length);
                }
                return tickLabel;
              },
            },
          },
        ],
      },
      legend: { display: false },
    };
    // console.log("options", options)
    return options;
  }

  createChart() {
    // console.log("creating chart", this.props.data);
    if (
      this.props.data &&
      this.props.data[this.props.team100Tricode] &&
      this.props.data[this.props.team100Tricode]
    ) {
      const labels =
        Object.keys(
          (this.props.data[this.props.team100Tricode] &&
            this.props.data[this.props.team100Tricode].gold) ||
            {}
        ) || [];
      const dataSet = labels.map(
        (label) =>
          this.props.data[this.props.team100Tricode].gold[label] -
          this.props.data[this.props.team200Tricode].gold[label]
      );
      const targetElement = document.getElementById("chart");
      const newChart = CreateGoldChart(
        targetElement,
        dataSet,
        this.createLabels(),
        null,
        null,
        this.getOptions(dataSet),
        null
      );
      this.setState({ chart: newChart });
    } else {
      console.log("Invalid data to build gold graph => ", this.props.data);
    }
  }

  createLabels() {
    let result = [];
    let rawLabels =
      Object.keys(
        this.props.data[this.props.team100Tricode] &&
          this.props.data[this.props.team100Tricode].gold
      ) ||
      {} ||
      [];
    let interval = Math.min(rawLabels) || 20;
    let numberOfLabelFor5min = 300 / interval;
    let temp = new Array(numberOfLabelFor5min).fill("");
    for (let i = 0; i < rawLabels.length / numberOfLabelFor5min; i++) {
      temp[numberOfLabelFor5min - 1] = `${(i + 1) * 5}`;
      result = result.concat(temp);
    }
    console.log("Label Created", result);
    return result;
  }

  updateChart() {
    const labels =
      Object.keys(
        this.props.data[this.props.team100Tricode] &&
          this.props.data[this.props.team200Tricode].gold
      ) ||
      {} ||
      [];
    const dataSet = labels.map(
      (label) =>
        this.props.data[this.props.team100Tricode].gold[label] -
        this.props.data[this.props.team200Tricode].gold[label]
    );
    // eslint-disable-next-line
    this.state.chart.data.labels = this.createLabels();
    // eslint-disable-next-line
    this.state.chart.data.datasets[0].data = dataSet;
    console.log("updating chart, datasets", dataSet);
    // eslint-disable-next-line
    this.state.chart.options = this.getOptions(dataSet);
    this.state.chart.update();
  }

  componentDidMount() {
    console.log("GoldDifference", this.props);
    const goldChart = this.createChart();
    // eslint-disable-next-line
    this.state.chart = goldChart;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.data &&
      Object.values(this.props.data[this.props.team100Tricode].gold || {})
        .length <
        Object.values(nextProps.data[this.props.team100Tricode].gold || {})
          .length
    ) {
      if (this.state.chart) {
        this.updateChart();
      }
    }
  }

  render() {
    // console.log('GoldDifference datasetLenght => ', Object.values(this.props.data[this.props.team100Tricode].gold).length);
    console.log("re render");

    return (
      <div>
        <StyleInfoBoxTitle
          StyleInfoBoxTitle={get(this.props, "theme.StyleInfoBoxTitle", "")}
        >
          GOLD DIFFERENCE
        </StyleInfoBoxTitle>

        <StyleInfoBoxBody>
          <canvas id="chart" height="200" width="1300"></canvas>
        </StyleInfoBoxBody>
      </div>
    );
  }
}

export default InfoBoxGoldDifference;
