// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";
//3rd PARTY
import moment from "moment";
import preval from "preval.macro";
import styled from "styled-components";
// import { get } from 'lodash';
import { /* themes, */ getTheme } from "../View/themes";

// internal
import DataKeyPlayers from "./DataKeyPlayers";
import DataLeaderboard from "./DataLeaderboard";
import DataMatch from "./DataMatch";
import DataSchedule from "./DataSchedule";
import DataSettings from "./DataSettings";
import DataStandings from "./DataStandings";
import DataTelops from "./DataTelops";
import DataTelopsV2 from "./DataTelopsV2";
import DataTimer from "./DataTimer";
import DataTwitter from "./DataTwitter";
import DataGameBreakdown from "./DataGameBreakdown";
import DataBackground from "./DataBackground";
import DataWaitingMessage from "./DataWaitingMessage";
import DataGameScreenKeyPlayers from "./DataGameScreenKeyPlayers";
import DataGameScreenKeyTeams from "./DataGameScreenKeyTeams";
import DataResultsList from "./DataResultsList";
import DataApiListener from "./DataApiListener";
import DataJungleRoute from "./DataJungleRoute";
import DataDamageDealt from "./DataDamageDealt";
import DataTurretGold from "./DataTurretGoldPlate";
import DataWhoWillWinAI from "./DataWhoWillWinAI";
import DataCastersPredictions from "./DataCastersPredictions";
import DataItemsPurchaseHistory from "./DataItemsPurchaseHistory";
import DataMythicItemInfobox from "./DataMythicItemInfobox";
import DataCustomInGameSide from "./DataCustomInGameSide";
import DataInGameTipInfobox from "./DataInGameTipInfobox";
import DataChampionPool from "./DataChampionPool";
import DataDraftOrder from "./DataDraftOrder";
import { firebaseRealtimeGetValue } from "../../util/firebase";

// Notes:
// This folder 'data' contains half of the components you see on the controller. All the parts on the right hand side to be exact. So it handles the data that is fed into the view components.
// 'DataMatch' is the most used component here, so I'll use that as an example. In there you can see everything inside the 'match' drop down on the controller (right side). Such as champions picked, players playing and team captains name.

const buildTimestamp = preval`module.exports = new Date().getTime();`;

class Data extends Component {
  static propTypes = {
    openSplitMenu: PropTypes.func,
    onUpdateSchedule: PropTypes.func,
    secondKeys: PropTypes.object,
    cgpc: PropTypes.string,
  };

  state = {
    schedule: window.MainData.schedule,
    teams: window.MainData.teams,
    leaderboard: window.MainData.leaderboard,
    gameId: "",
    platform: "LOLTMNT01",
  };

  componentDidMount() {}

  updateDataNode = (dataroot, data) => {
    const currentNode = window.MainData[dataroot];
    window.MainData[dataroot] = Object.assign(currentNode, data);
  };

  createDataNode = (dataroot, defaultData) => {
    if (!window.MainData[dataroot]) {
      window.MainData[dataroot] = defaultData;
    } else {
      console.error(`Data node already exists.`); // eslint-disable-line no-console
    }
  };

  setDataNode = (dataroot, defaultData) => {
    if (!window.MainData[dataroot]) {
      window.MainData[dataroot] = defaultData;
    }
    this.updateDataNode(dataroot, defaultData);
  };

  objectDiff(obj1, obj2) {
    var diff = function (obj1, obj2) {
      // Make sure an object to compare is provided
      if (!obj2 || Object.prototype.toString.call(obj2) !== "[object Object]") {
        return obj1;
      }

      //
      // Variables
      //

      var diffs = {};
      var key;

      //
      // Methods
      //

      /**
       * Check if two arrays are equal
       * @param  {Array}   arr1 The first array
       * @param  {Array}   arr2 The second array
       * @return {Boolean}      If true, both arrays are equal
       */
      var arraysMatch = function (arr1, arr2) {
        // Check if the arrays are the same length
        if (arr1.length !== arr2.length) return false;

        // Check if all items exist and are in the same order
        for (var i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) return false;
        }

        // Otherwise, return true
        return true;
      };

      /**
       * Compare two items and push non-matches to object
       * @param  {*}      item1 The first item
       * @param  {*}      item2 The second item
       * @param  {String} key   The key in our object
       */
      var compare = function (item1, item2, key) {
        // Get the object type
        var type1 = Object.prototype.toString.call(item1);
        var type2 = Object.prototype.toString.call(item2);

        // If type2 is undefined it has been removed
        if (type2 === "[object Undefined]") {
          diffs[key] = null;
          return;
        }

        // If items are different types
        if (type1 !== type2) {
          diffs[key] = item2;
          return;
        }

        // If an object, compare recursively
        if (type1 === "[object Object]") {
          var objDiff = diff(item1, item2);
          if (Object.keys(objDiff).length > 0) {
            diffs[key] = objDiff;
          }
          return;
        }

        // If an array, compare
        if (type1 === "[object Array]") {
          if (!arraysMatch(item1, item2)) {
            diffs[key] = item2;
          }
          return;
        }

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (type1 === "[object Function]") {
          if (item1.toString() !== item2.toString()) {
            diffs[key] = item2;
          }
        } else {
          if (item1 !== item2) {
            diffs[key] = item2;
          }
        }
      };

      //
      // Compare our objects
      //

      // Loop through the first object
      for (key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, key)) {
          compare(obj1[key], obj2[key], key);
        }
      }

      // Loop through the second object and find missing items
      for (key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          if (!obj1[key] && obj1[key] !== obj2[key]) {
            diffs[key] = obj2[key];
          }
        }
      }

      // Return the object of differences
      return diffs;
    };
    return diff(obj1, obj2);
  }

  updateSchedule = () => {
    const schedule = window.MainData.schedule;
    if (
      Object.keys(this.objectDiff(schedule.rounds, this.state.schedule.rounds))
        .length > 0
    ) {
      this.setState({ schedule });
      this.props.onUpdateSchedule(schedule);
    }
  };

  distributeGameId = (gameId) => {
    if (gameId) this.setState({ gameId: gameId });
  };
  distributePlatform = (platform) => {
    if (platform) this.setState({ platform: platform });
  };
  fetchGameId = () => {
    firebaseRealtimeGetValue(
      `${this.state.schedule.league}/${this.state.schedule.season}/${this.state.schedule.split}/`
    ).then((value) => {
      if (value) {
        try {
          const roundId =
            this.state.schedule.rounds[this.state.schedule.round].name;
          const matchId = this.state.schedule.match;
          const gameId = this.state.schedule.game;
          try {
            const GameID = value[roundId][matchId][gameId].GameID || undefined;
            if (value && GameID) this.setState({ gameId: GameID });
          } catch (gameError) {
            this.setState({ gameId: "000000" });
            console.log("GAMEID fetch failed.");
          }
          try {
            const platformID =
              value[roundId][matchId][gameId].Realm || undefined;
            if (value && platformID) this.setState({ platform: platformID });
          } catch (platformError) {
            this.setState({ platform: "LOLTMNT01" });
            console.log("platform fetch failed.");
          }
        } catch (err) {
          this.setState({ gameId: "000000" });
          this.setState({ platform: "LOLTMNT01" });
          console.log("fetchGameId Error.", err);
        }
      }
    });
  };

  render() {
    const theme = getTheme(window.MainData.theme);
    return (
      <div className="data-container">
        {window.MainData.components.data.DataTimer && (
          <DataTimer
            cgpc={this.props.cgpc}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.data.DataStandings && (
          <DataStandings
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.data.DataLeaderboard && (
          <DataLeaderboard
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.data.DataTelops && (
          <DataTelops
            schedule={this.state.schedule}
            teams={this.state.teams}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.data.DataTelopsV2 && (
          <DataTelopsV2
            schedule={this.state.schedule}
            teams={this.state.teams}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.view.CastersPredictions && (
          <DataCastersPredictions schedule={{ ...this.state.schedule }} />
        )}
        {window.MainData.components.data.DataSchedule && (
          <DataSchedule
            updateSchedule={this.updateSchedule}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
            openSplitMenu={this.props.openSplitMenu}
          />
        )}
        {window.MainData.components.data.DataMatch && (
          <DataMatch
            updateSchedule={this.updateSchedule}
            schedule={this.state.schedule}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
            fetchGameId={this.fetchGameId}
          />
        )}
        {window.MainData.components.data.DataKeyPlayers && (
          <DataKeyPlayers
            schedule={this.state.schedule}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {
          <DataApiListener
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
            gameId={this.state.gameId}
            platform={this.state.platform}
            distributeGameId={this.distributeGameId}
            distributePlatform={this.distributePlatform}
          />
        }
        {window.MainData.components.data.DataGameBreakdown && (
          <DataGameBreakdown
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
            gameId={this.state.gameId}
            platform={this.state.platform}
            distributeGameId={this.distributeGameId}
          />
        )}
        {window.MainData.components.data.DataTwitter && (
          <DataTwitter
            schedule={this.state.schedule}
            teams={this.state.teams}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.data.DataResultsList && (
          <DataResultsList
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.gameScreen.whoWillWinAI && (
          <DataWhoWillWinAI
            schedule={{ ...this.state.schedule }}
            teams={this.state.teams}
          />
        )}
        {window.MainData.components.gameScreen.jungleRoute && (
          <DataJungleRoute
            schedule={{ ...this.state.schedule }}
            teams={this.state.teams}
          />
        )}
        {window.MainData.components.gameScreen.teamStats && (
          <DataGameScreenKeyPlayers
            schedule={this.state.schedule}
            teams={this.state.teams}
            leaderboard={this.state.leaderboard}
          />
        )}
        {window.MainData.components.gameScreen.playerStats && (
          <DataGameScreenKeyTeams
            schedule={this.state.schedule}
            teams={this.state.teams}
            leaderboard={this.state.leaderboard}
          />
        )}
        {window.MainData.components.gameScreen.turretPlate && (
          <DataTurretGold
            schedule={{ ...this.state.schedule }}
            teams={this.state.teams}
          />
        )}
        {window.MainData.components.gameScreen.damageDealt && (
          <DataDamageDealt
            schedule={{ ...this.state.schedule }}
            teams={this.state.teams}
          />
        )}
        {window.MainData.components.gameScreen.mythicItemInfobox && (
          <DataMythicItemInfobox />
        )}
        {window.MainData.components.gameScreen.inGameTipInfobox && (
          <DataCustomInGameSide schedule={{ ...this.state.schedule }} />
        )}
        {window.MainData.components.gameScreen.inGameTipInfobox && (
          <DataInGameTipInfobox />
        )}
        {window.MainData.components.gameScreen.itemsPurchaseHistory && (
          <DataItemsPurchaseHistory
            schedule={{ ...this.state.schedule }}
            teams={this.state.teams}
          />
        )}
        {window.MainData.components.data.DataBackground && (
          <DataBackground
            secondKeys={this.props.secondKeys}
            theme={theme || {}}
          />
        )}
        {window.MainData.components.data.DataWaitingMessage && (
          <DataWaitingMessage setDataNode={this.setDataNode} />
        )}
        {window.MainData.components.data.DataSettings && (
          <DataSettings
            cgpc={this.props.cgpc}
            secondKeys={this.props.secondKeys}
            updateDataNode={this.updateDataNode}
            createDataNode={this.createDataNode}
          />
        )}
        {window.MainData.components.view.DraftOrder && (
          <DataDraftOrder schedule={{ ...this.state.schedule }} />
        )}
        {window.MainData.components.view.ChampionPool && <DataChampionPool />}
        {/* {window.MainData.components.data.DataSettings && <DataSettings updateDataNode={this.updateDataNode} createDataNode={this.createDataNode} />} */}
        <StyleBuildDate>{`Build date: ${moment(buildTimestamp).format(
          "MMMM Do YYYY, h:mm:ss a"
        )}`}</StyleBuildDate>
      </div>
    );
  }
}

const StyleBuildDate = styled.div`
  color: black;
  font-style: italic;
  font-size: 12px;
  padding: 3px;
  text-align: right;
`;

export default Data;
