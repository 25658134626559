// REACT
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
// internal
// import { i18n } from "../../i18n";
import {
  StyleMainContainer,
  StyleTeamGrid,
  StyleInputGold,
  StyleActionContainer,
} from "./styles";
import DataContainer from "../DataContainer";
import Button from "../../Button";

function DataTurretGoldPlate(props) {
  const [currentGameState, setCurrentGameState] = useState();
  const [leftGoldEarnedState, setLeftGoldEarnedState] = useState(0);
  const [rightGoldEarnedState, setRightGoldEarnedState] = useState(0);

  useEffect(() => {
    const currentGame = get(
      props,
      `schedule.rounds.${props.schedule.round}.matches.${props.schedule.match}.games.${props.schedule.game}`,
      undefined
    );
    if (Object.values(currentGame.teams).length !== 2) return; // Handling TBD teams

    if (currentGame) {
      let team100 = {},
        team200 = {};
      let team100Name,
        team200Name = "";
      if (
        Object.values(currentGame.teams)[0].side === "100" &&
        Object.values(currentGame.teams)[1].side === "200"
      ) {
        team100Name = Object.keys(currentGame.teams)[0];
        team200Name = Object.keys(currentGame.teams)[1];
      } else {
        team100Name = Object.keys(currentGame.teams)[1];
        team200Name = Object.keys(currentGame.teams)[0];
      }
      // console.log("team100", team100, "team200", team200);
      setCurrentGameState({ team100Name, team100, team200Name, team200 });
    }
  }, [props.schedule]);

  const handleGoldChange = (side, goldAmount) => {
    if (side === "left") {
      setLeftGoldEarnedState(goldAmount);
    } else if (side === "right") {
      setRightGoldEarnedState(goldAmount);
    }
  };

  const saveChange = () => {
    if (leftGoldEarnedState === 0 && rightGoldEarnedState === 0) {
      window.MainData.turretPlateGold = undefined;
    } else {
      const gold = {};
      gold.team100 = leftGoldEarnedState;
      gold.team200 = rightGoldEarnedState;
      window.MainData.turretPlateGold = gold;
    }
  };

  return (
    <DataContainer
      componentSlug="turretsPlate"
      icon="fas fa-monument"
      style={{ overflow: "hidden" }}
    >
      <div
        className="data-panel__fieldset"
        style={{ paddingTop: "10px", paddingBottom: "0" }}
      >
        <StyleMainContainer>
          {(currentGameState && (
            <StyleTeamGrid>
              <div style={{ backgroundColor: "#0078ff" }}>
                <h3 style={{ backgroundColor: "#46adff" }}>
                  {currentGameState.team100Name}
                </h3>
                <StyleInputGold
                  type={"number"}
                  onChange={(e) => {
                    e.persist();
                    handleGoldChange("left", Number(e.target.value));
                  }}
                  value={leftGoldEarnedState}
                />
              </div>
              <div style={{ backgroundColor: "#dd3217" }}>
                <h3 style={{ backgroundColor: "#ff5745" }}>
                  {currentGameState.team200Name}
                </h3>
                <StyleInputGold
                  type={"number"}
                  onChange={(e) => {
                    e.persist();
                    handleGoldChange("right", Number(e.target.value));
                  }}
                  value={rightGoldEarnedState}
                />
              </div>
            </StyleTeamGrid>
          )) || <p>{"No game"}</p>}
          <StyleActionContainer className={"pill"}>
            <Button
              action={() => {
                saveChange();
              }}
              text="Save Data"
            />
          </StyleActionContainer>
        </StyleMainContainer>
      </div>
    </DataContainer>
  );
}

DataTurretGoldPlate.propTypes = {
  schedule: PropTypes.object,
};

export default DataTurretGoldPlate;
