// REACT
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { get } from "lodash";
// ME
import { ThemeContext } from "../../../View/themes";
import positions from "./../../../../storybook-built/dist/Constants/lol_positions";
import PlayerBarItem from "./PlayerBarItem";
import { StyleInfoBoxBody, StyleInfoBoxTitle } from "../styles";

class InfoBoxGoldPerPlayer extends Component {
  static propTypes = {
    // theme: PropTypes.object,
    data: PropTypes.object,
    team100Tricode: PropTypes.string,
    team200Tricode: PropTypes.string,
    team100Roster: PropTypes.object,
    team200Roster: PropTypes.object,
  };

  componentDidMount() {
    console.log("GoldperPlayer", this.props);
  }

  getMaxValue() {
    let max = 0;
    if (
      this.props.data &&
      this.props.data[this.props.team100Tricode] &&
      this.props.data[this.props.team200Tricode]
    ) {
      positions.forEach((pos) => {
        let team100GoldTotal = get(
          this.props,
          `data[${this.props.team100Tricode}][${pos}].currentTotalGold`,
          0
        );
        if (team100GoldTotal > max) max = team100GoldTotal;
        let team200GoldTotal = get(
          this.props,
          `data[${this.props.team200Tricode}][${pos}].currentTotalGold`,
          0
        );
        if (team200GoldTotal > max) max = team200GoldTotal;
      });
    }
    return max;
  }

  render() {
    const theme = this.context || {};
    const maxValue = this.getMaxValue() || 10000;
    console.log("GoldperPlayer", this.props);

    return (
      <div>
        <StyleInfoBoxTitle
          StyleInfoBoxTitle={get(this.props, "theme.StyleInfoBoxTitle", "")}
        >
          GOLD PER PLAYER
        </StyleInfoBoxTitle>
        <StyleInfoBoxBody>
          <StyleGoldTeam
            className="game-breakdown__content__graphs__item__damage-cont__team game-breakdown__content__graphs__item__damage-cont__team--blue"
            css={
              (theme &&
                theme.screens.gameBreakdown.graph.damageCountBackground) ||
              ""
            }
          >
            {positions.map((pos) => {
              return (
                <PlayerBarItem
                  theme={get(this.props, "theme.bars", "")}
                  key={`${pos}-teamLeft`}
                  color={"#0078ff"}
                  isInfoBox
                  side={"left"}
                  champ={this.props.team100Roster[pos].championId}
                  instantVal={get(
                    this.props,
                    `data[${this.props.team100Tricode}][${pos}].currentGold`,
                    0
                  )}
                  maxVal={maxValue}
                  score={get(
                    this.props,
                    `data[${this.props.team100Tricode}][${pos}].currentTotalGold`,
                    0
                  )}
                  scoreExtraWide={true}
                />
              );
            })}
          </StyleGoldTeam>
          <StyleGoldTeam
            className="game-breakdown__content__graphs__item__damage-cont__team game-breakdown__content__graphs__item__damage-cont__team--red"
            css={
              (theme &&
                theme.screens.gameBreakdown.graph.damageCountBackground) ||
              ""
            }
          >
            {positions.map((pos) => {
              return (
                <PlayerBarItem
                  theme={get(this.props, "theme.bars", "")}
                  key={`${pos}-teamRight`}
                  color={"#dd3217"}
                  isInfoBox
                  side={"right"}
                  champ={this.props.team200Roster[pos].championId}
                  instantVal={get(
                    this.props,
                    `data[${this.props.team200Tricode}][${pos}].currentGold`,
                    0
                  )}
                  maxVal={maxValue}
                  score={get(
                    this.props,
                    `data[${this.props.team200Tricode}][${pos}].currentTotalGold`,
                    0
                  )}
                  scoreExtraWide={true}
                />
              );
            })}
          </StyleGoldTeam>
        </StyleInfoBoxBody>
      </div>
    );
  }
}

InfoBoxGoldPerPlayer.contextType = ThemeContext;

const StyleGoldTeam = styled.div`
  padding: 6px;
  display: grid;
  grid-row-gap: 6px;
  height: 100%;
`;

export default InfoBoxGoldPerPlayer;
